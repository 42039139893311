@keyframes dotFlashing {
    0% {
        color: #000;
    }

    50%,
    100% {
        color: #ebe6ff;
    }
}
.FlanProdDet .u-contain.similar-items {
    max-width: none;
    padding: 20px 0 0;

    .innerMoreDelivery #MoreFromLinks {
        justify-content: center;
    }
}

.FlanProdDet {
    #BodyWrap .ContentWrapper {
        max-width: 100%;
        padding: 0;
        overflow: hidden;
    }

    .u-contain {
        position: relative;
        margin-left: auto;
        margin-right: auto;
        box-sizing: border-box;
        max-width: var(--max-container-width);
        display: grid;
        grid-template-columns: 60% 40%;
        padding: 0 30px;

        @media (min-width: 768px) {
            padding: 0 20px;
        }

        @media (min-width: 1022px) {
            padding: 0 32px;
        }
    }

    #contentWrapper {
        max-width: 1420px;
        margin: 0 auto;
    }

    .productDescription .infoPage .infoTabPage span,
    .productDescription .infoPage .infoTabPage span a,
    .productDescription .infoaccordion .panel-collapse span,
    .productDescription .infoaccordion .panel-collapse span a {
        font-size: 1.1em;
        line-height: 18px;
    }

    .productDescription .infoPage .infoTabPage span a,
    .productDescription .infoaccordion .panel-collapse span a {
        font-size: 1em;
    }

    .infoTabPage h2,
    #divColour .s-productextras-column-1,
    #divSize .s-productextras-column-1,
    .qtyBorder .s-productextras-column-1,
    #divColour .s-productextras-column-2-3 {
        display: block;
    }

    #divColour {
        width: 100%;
        flex-direction: column;

        span#colourName {
            font-style: normal;
            font-size: 16px;
        }

        .s-productextras-column-1,
        .s-productextras-column-2-3 {
            display: inline-block;
            float: none;
            padding: 0;
        }

        .s-productextras-column-1 {
            &::before,
            &::after {
                content: none;
            }
        }
    }

    .swapSize .s-productextras-column-2 ul li {
        float: none;
        display: inline-block;
    }

    .infoRow .innerInfoRow {
        position: relative;
        padding: 35px 0 10px;
        border: none;
    }

    .free-samples-promo-wrapper,
    .gwpPromotionContainer .gwpPromotion {
        border-top: 3px solid var(--flan-neon);
    }

    .swapColour .s-productextras-column-2-3 {
        padding: 6px 0 5px 10px;
    }

    .addToBasketContainer.SizeRequiredButton {
        a#aAddToBag {
            background-color: #979797;
            border-color: #979797;
            color: var(--flan-white);

            &:hover,
            &:active,
            &:focus {
                background-color: #979797;
                border-color: #979797;
                color: var(--flan-white);
            }
        }
        &.sizeSelected a#aAddToBag {
            color: var(--flan-white);
            border-color: var(--flan-black);
            background-color: var(--flan-black);

            &:hover,
            &:active,
            &:focus {
                color: var(--flan-black);
                border-color: var(--flan-black);
                background-color: var(--flan-white);
            }
        }
    }

    .infoRow .help-info h3 {
        margin: 10px 0 5px;
    }

    .infoRow .help-info a {
        color: #000;
    }

    .pd-accordion .acc-content,
    .pd-accordion .acc-content p,
    .pd-accordion .acc-content p a,
    .pd-accordion .acc-content ul li {
        color: #000;
    }

    .colourImages {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        margin: 0;

        &::before {
            content: none;
        }

        li {
            margin: 0;
            width: 60px;
            height: 60px;

            img {
                max-width: 100%;
                aspect-ratio: 1 / 1;
            }

            &.greyOut span {
                background-image: none;
                opacity: 0.7;
                background-color: #ebebeb;
            }

            &#cvlimore a {
                padding: 12px 0;
                font-weight: var(--fw-semibold);
                font-size: 13px;
            }
        }
    }

    .infoRow .help-info li a,
    .helpContainer .pd-accordion .acc-content ul li a,
    #MoreFromLinks li.MoreFromLinksRow a {
        text-decoration: underline;
    }

    .infoRow .help-info li a:hover,
    .helpContainer .pd-accordion .acc-content ul li a:hover,
    #MoreFromLinks li.MoreFromLinksRow a:hover,
    .infoRow > div p a:hover,
    #productVariantAndPrice .s-productextras-column-3 a:hover .SizeGuideText,
    .productDescription .infoPage .infoTabPage span a:hover {
        text-shadow: 0 0 1px #000;
    }

    #divSize {
        width: auto;

        .s-productextras-column-1 .BuySizeText.sizeerror {
            position: relative;
            background-color: #fff;
            z-index: 1;
        }

        .s-productextras-column-1 .BuySizeText.sizeerror::after {
            background-image: none;
        }
    }

    .helpContainer .pd-accordion .acc-content ul li + li {
        margin-top: 10px;
    }

    #MoreFromLinks li.MoreFromLinksRow {
        padding: 0;
        font-size: 16px;
    }

    #MoreFromLinks li.MoreFromLinksRow a {
        font-size: 16px;
    }

    #MoreFromLinks li.MoreFromLinksRow a:last-child {
        font-weight: var(--fw-normal);
    }

    #MoreFromLinks li.MoreFromLinksRow a::before {
        display: none;
    }

    .PSPlacementHorizontal span.PSProdBrand,
    .PSPlacementHorizontal span.PSProdTitle,
    .RecentlyViewedListOnProductsPage span.AltStratBrand,
    .RecentlyViewedListOnProductsPage span.AltStratDescription,
    .PSPlacementHorizontal .PSProdPrice,
    .RecentlyViewedListOnProductsPage .AltStratRefSell {
        text-align: left;
    }

    .SelectSizePopover {
        display: none !important;
    }

    #hotspotModal .swiper-pagination {
        height: auto;
    }

    #hotspotModal .swiper-button-next::before,
    #hotspotModal .swiper-button-prev::before {
        display: none;
    }

    #productDetails {
        z-index: 1;
        background-color: #fff;
        box-sizing: border-box;
        float: none;
        width: auto;
        padding-left: 40px;
        padding-right: 0;
    }

    #piThumbList li {
        padding: 0;
        width: auto;
        min-height: 44px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    #piThumbList li a {
        padding: 8px;
    }

    #piThumbList li .imgdot {
        display: block !important;
        height: 4px;
        width: 24px;
        border: none;
        opacity: 0.3;
    }

    #piThumbList li .imgdot.piActiveDot {
        opacity: 1;
    }

    .u-contain.how-to-use::before,
    .u-contain.how-to-use::after,
    .u-contain.shop-the-look::before,
    .u-contain.shop-the-look::after {
        display: table;
        content: "";
    }

    .u-contain.how-to-use::after,
    .u-contain.shop-the-look::after {
        clear: both;
    }

    .product-usage-attributes {
        padding: 0 0 40px;
        width: 100%;
    }

    .product-usage-attributes .beautyTabWrap {
        max-width: 100%;
    }

    .similar-items .innerMoreDelivery {
        margin: 0 auto;
        position: relative;
        padding-bottom: 40px;
    }

    #MoreFromLinks {
        border: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: #000;
    }

    #MoreFromLinks .MoreFromLinksHeader,
    #MoreFromLinks li.MoreFromLinksRow,
    #MoreFromLinks li.MoreFromLinksRow a {
        font-weight: var(--fw-semibold);
        color: #fff;
    }

    #MoreFromLinks .MoreFromLinksHeader {
        font-size: 20px;
        line-height: 20px;
        padding: 0;
        margin-bottom: 0;
        white-space: nowrap;
    }

    #MoreFromLinks li.MoreFromLinksRow::before {
        display: none;
    }

    #productDetails .originalprice,
    #productDetails #lblSellingPrice,
    .originalprice .originalTicket {
        display: block;
    }

    #productDetails .originalprice {
        display: inline-block;
    }

    .originalprice .originalTicket {
        font-size: 16px;
        line-height: 16px;
    }

    #productDetails .originalprice,
    #productDetails #lblSellingPrice {
        font-size: 20px !important;
        line-height: 22px !important;
    }

    #productDetails .productDetailsInner {
        padding: 0;
        border: 0;
    }

    .product-detail__expandable-toggle {
        font-size: 16px;
    }

    .product-detail__expandable-lead,
    .product-detail__expandable-content,
    .product-detail__expandable-content p,
    .displayAttributesActive {
        font-size: 16px;
        line-height: 24px;
    }

    .product-detail__expandable-lead,
    .product-detail__expandable-content.is_expanded {
        padding-bottom: 12px;
    }

    .product-detail__delivery-estimate {
        position: relative;
        line-height: 24px;
        font-size: 16px;
    }

    .product-detail__delivery-estimate::before {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 4px;
        vertical-align: text-bottom;
        background-image: url("/Images/Marketing/icons/icon-clock-black.svg");
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 16px auto;
        content: "";
    }

    .product-detail__name {
        margin-top: 0;
        margin-bottom: 0;
        padding-bottom: 0;
        text-transform: uppercase;
        line-height: 1.6em;
    }

    .product-detail__price {
        display: flex;
        flex: 0 0 100%;
        gap: 8px;
        align-items: baseline;
        order: 3;
        font-size: 20px;
        line-height: 22px;
        padding-top: 20px;
        font-weight: var(--fw-semibold);
        text-transform: uppercase;
    }

    .ProdDetails .member-price + .product-detail__price {
        padding-bottom: 28px;
    }

    .frasers-plus-breakdown {
        order: 3;
    }

    .product-detail__title {
        display: flex;
        margin-bottom: 20px;

        #lblProductName {
            display: block;
            font-size: 20px;
            font-weight: var(--fw-normal);
            letter-spacing: -0.32px;
        }
    }

    .brandLink,
    &:not(.brand-chanel) .product-detail__name.has-logo #lblProductName {
        font-size: 30px;
        font-weight: var(--fw-semibold);
        letter-spacing: -0.32px;
    }

    .brand-chanel .product-detail__name .brandLink {
        text-transform: uppercase;
    }

    .product-detail__title .brandLink:hover,
    .product-detail__title .brandLink:focus {
        text-decoration: underline;
    }

    .product-detail__brand-logo {
        margin-bottom: 10px;
    }

    .product-detail__select-contain::before {
        position: absolute;
        top: 50%;
        right: 24px;
        display: block;
        width: 20px;
        height: 10px;
        background-image: url("/images/marketing/icons/icon-arrow-down-black.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50% 50%;
        transform: translate(0, -3px);
        content: "";
        transition: 0.25s transform ease-in-out;
        pointer-events: none;
    }

    #productDetails .BasketWishContainer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0;
        bottom: 0;
        background-color: #fff;
        z-index: 2;
        float: none;
        min-height: auto;
        margin: 20px 0;

        .addToBasketContainer {
            width: auto;
            flex: 1 1 auto;
            min-height: 0;
            float: none;
        }

        #aAddToWishList {
            background-color: transparent;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 calc(3 * var(--padding-unit));
        }
    }

    .WishListContain a,
    a#aAddToBag,
    a#addHotspotToBag {
        font-weight: var(--fw-medium);
    }

    .addToBasketContainer a#aAddToBag,
    &.ProdDetails.printess-button-cta {
        letter-spacing: 0.1em;
    }

    .BasketWishContainer .WishListContain {
        padding-top: 0;
        padding-bottom: 0;
        width: 44px;
        float: none;
        background: none;
    }

    .WishListContain a {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 50px;
    }

    .WishListContain .global-icon svg {
        width: 30px;
        height: 100%;
    }

    .WishListContain .pdpWishListLabel {
        height: 100%;
    }

    .WishListContain a:hover,
    .WishListContain a:focus,
    .WishListContain.sAddToWishListWrapper a:hover {
        background-position: -209px -237px;
        background-size: 500px 499px;
    }

    .WishListContain .wishListSVG svg {
        width: 30px;
        height: 100%;
    }

    .WishListContain .wishListSVG:hover svg,
    .WishListContain .wishListSVG:focus svg,
    .WishListContain .wishListSVG.addedWishList svg {
        fill: var(--flan-neon);
    }

    .WishListContain.sAddToWishListWrapper a.pdpWishListLabel {
        display: block;
        vertical-align: middle;
    }

    .WishListContain a,
    .WishListContain.sAddToWishListWrapper a:hover {
        background-color: transparent !important;
    }

    .WishListContain {
        width: auto !important;
        padding: 0 !important;
        float: none !important;
        background-color: transparent !important;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;
    }

    @media (min-width: 1022px) {
        .FlanProdDet .WishListContain.sAddToWishListWrapper {
            display: block;
            float: none;
        }
    }

    #productDetails select {
        margin: 0;
        border-bottom: 0;
        height: 100%;
        text-transform: capitalize;
        letter-spacing: normal;
    }

    #productDetails select option {
        font-size: 16px;
    }

    #divColour,
    .swapSize,
    .s-productextras-column-1,
    .u-form__row {
        float: none;
    }

    #productVariantAndPrice,
    #divColour {
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: flex-start;
        align-items: baseline;
        gap: 8px;
    }

    #productVariantAndPrice {
        position: relative;
        margin-bottom: 20px;

        .swapSize {
            width: 100%;
        }
    }

    #productDetails .TrueFitWrapper iframe {
        margin: 0 0 10px;
    }

    .brand-detail__size-guide {
        position: absolute;
        top: 0;
        right: 0;
    }

    .brand-detail__size-guide .sizeslink {
        color: #000;
        display: block;
        line-height: 16px;
        padding: 0 0 0 62px;
        position: relative;
        text-decoration: underline;

        @media (min-width: 768px) and (max-width: 1020px) {
            padding: 0;

            &::before {
                display: none;
            }
        }
    }

    .brand-detail__size-guide .sizeslink:hover,
    .brand-detail__size-guide .sizeslink:focus {
        text-decoration: none;
    }

    .brand-detail__size-guide .sizeslink::before {
        display: block;
        content: "";
        position: absolute;
        left: 10px;
        top: 50%;
        width: 40px;
        height: 16px;
        transform: translate(0, -50%);
        background-image: url("/Images/Marketing/icons/icon-ruler-black.svg");
        background-repeat: no-repeat;
        background-position: 0 50%;
        background-size: 40px auto;
    }

    .brand-detail__size-guide .sizeslink span {
        font-size: 16px;
        float: none;
        line-height: 16px;
        display: block;
        padding-right: 0;

        @media (min-width: 768px) and (max-width: 1020px) {
            font-size: 14px;
        }
    }

    .shadeSwitch .brand-detail__size-guide .sizeslink {
        display: none;
    }

    #productDetails .discount-percentage-off {
        padding: 0 0 0 5px;
        font-size: 16px;
        line-height: 22px;
    }

    .frasers-plus-enabled {
        .frasers-plus-outer-container {
            padding: 5px 0 20px;
        }
    }

    .discount-percentage-off {
        display: inline-block;
        vertical-align: top;
        padding: 0 0 5px;
        font-size: 1.1em;
        line-height: 1.1em;
        text-transform: uppercase;
    }

    .product-reviews {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    div.bv_modal_component_container[data-bv-modal] {
        z-index: 6000 !important;
    }

    .stock-level-container {
        flex-wrap: wrap;
        gap: 4px;
        margin-top: 20px;

        .stock-level-message-divider,
        h4,
        p {
            height: 15px;
        }
    }

    .productHasRef {
        color: var(--flan-red);
    }

    .sizeButtons {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding-top: 10px;
        gap: 8px;

        &::before {
            content: none;
        }

        li {
            margin: 0;
            min-width: 0;
            flex-basis: calc(25% - 6px);

            @media (min-width: 768px) {
                flex-basis: calc(33% - 7px);
            }

            @media (min-width: 1022px) {
                flex-basis: calc(25% - 6px);
            }

            @media (min-width: 1440px) {
                flex-basis: calc(20% - 7px);
            }

            &.greyOut a {
                background-color: #ebebeb;
                opacity: 0.7;
            }
        }
    }

    .sticky-atb-wrapper {
        display: none;

        &.stickyCTA-active {
            top: 0;
        }

        .sticky-atb--buysection {
            #aAddToBag {
                height: 35px;
                line-height: 33px;
                padding: 0 20px;
                font-size: 16px;
            }

            .addToBasketContainer {
                width: 100%;
                min-height: 35px;
            }

            .addToBasketContainer.SizeRequiredButton a#aAddToBag {
                &:hover {
                    background-color: var(--flan-black) !important;
                    color: var(--flan-white) !important;
                }
            }

            .NonBuyableOverlay {
                width: 100%;
                height: 35px;
                top: unset;
                bottom: unset;

                .NonBuyableOverlayMessage {
                    letter-spacing: 0.1em;
                    font-size: 16px;
                    font-weight: var(--fw-semibold);
                }
            }
        }

        .sticky-atb .sticky-atb-img {
            width: 50px;

            img {
                height: 50px;
                width: 50px;
            }
        }

        .sticky-atb .sticky-atb-product--info .sticky-atb--title span,
        .sticky-atb .pdpPrice span {
            font-size: 16px;
        }

        .addToBag {
            border-radius: 0;
        }

        .productHasRef {
            color: var(--flan-red);
        }
    }

    .gwpPromotionContainer {
        margin-top: 0;
    }
}

@media (min-width: 600px) and (max-width: 1021px) {
    .FlanProdDet {
        .productSampleGwpContainer {
            display: flex;
        }

        .productSampleGwpContainer .gwpPromotionContainer,
        .productSampleGwpContainer .free-samples-promo-wrapper {
            flex-grow: 1;
            min-width: 50%;
        }
    }
}

@media (max-width: 767px) {
    .FlanProdDet {
        .u-contain {
            padding: 0;
        }

        .title h1 .brandTitle a {
            font-size: 1.6em;
        }

        .title h1 .prodTitle {
            font-size: 1.4em;
        }

        #MoreFromLinks {
            padding: 20px 24px;
        }

        #productVariantAndPrice {
            flex-direction: column;
        }

        #productDetails {
            .u-form__select-contain select {
                text-align-last: left;
            }
        }

        .product-detail__title {
            flex-direction: column;
        }

        .product-detail__name {
            margin-right: 16px;
        }

        .product-detail__brand-logo {
            width: 100px;
            margin-bottom: 8px;
        }

        .u-form__select-contain {
            display: block;
            max-width: 100%;
        }

        .brand-detail__size-guide .sizeslink {
            margin: 0;
        }

        .product-detail__select-contain {
            margin-top: 12px;
        }

        .pdp-image-carousel-view {
            #productDetails {
                width: 100%;
                transform: translateY(30px);
                padding: 20px;
                margin: 0;
            }
        }

        .pdp-image-grid-view {
            #productDetails {
                width: 100%;
                padding: 16px 20px;
                margin: 0;
            }

            .u-contain {
                grid-template-columns: 100%;
            }
        }

        .sticky-atb-wrapper {
            display: block;

            .sticky-atb--buysection {
                #aAddToBag {
                    padding: 0 15px;
                    height: 40px;
                    line-height: 36px;
                    font-size: 12px;
                    letter-spacing: 0.1em;
                }

                .NonBuyableOverlay {
                    width: 100%;
                    height: 40px;

                    .NonBuyableOverlayMessage {
                        font-size: 12px;
                    }
                }
            }

            .sticky-atb .pdpPrice span,
            .originalprice .originalTicket {
                font-size: 14px;
            }

            .sticky-atb .sticky-atb-product--info .sticky-atb--title span {
                font-size: 14px;
            }
        }

        .menu-search-shown {
            .sticky-atb-wrapper {
                &.stickyCTA-active {
                    top: 130px;
                }
            }
        }

        .frasers-plus-breakdown {
            padding: 0 15px 28px 0;
        }
    }
}

@media (min-width: 768px) {
    .FlanProdDet {
        &:not(.brand-chanel) .product-detail__name.has-logo {
            display: flex;
            flex: 1;
        }

        .product-detail__title,
        &:not(.brand-chanel) .product-detail__name.has-logo {
            justify-content: space-between;
            flex-wrap: wrap;
        }

        .product-detail__name span {
            flex: 0 0 65.21739%;
            order: 1;
        }

        .product-detail__name.no-logo {
            flex: 1 1 auto;
        }

        &:not(.brand-chanel) .product-detail__name.has-logo .brandLink {
            order: 2;
            flex: 0 0 30.47438%;
            text-align: right;
        }

        .product-detail__brand-logo {
            max-width: 100px;
        }

        #productVariantAndPrice {
            flex-direction: row;
        }

        .store-outer .storesDetColWrapper {
            display: flex;
            flex-wrap: wrap;
        }

        .store-outer .StoreDetailsWrapper {
            display: flex;
            flex-wrap: wrap;
        }
    }
}

@media (max-width: 480px) {
    .sticky-atb .sticky-atb-product--info {
        width: calc(100% - 252px);
    }
}

@media (min-width: 768px) and (max-width: 1021px) {
    .FlanProdDet {
        #MoreFromLinks {
            padding: 24px 48px;
        }

        #productVariantAndPrice {
            max-width: 454px;
        }

        #productDetails .BasketWishContainer {
            max-width: 500px;
        }
    }
}

@media (max-width: 1021px) {
    .FlanProdDet {
        #productDetails {
            padding-left: 20px;

            .productDetailsInner > div {
                padding-left: 15px;
                padding-right: 15px;
            }

            .productVariantContainer .descGwpContainer {
                padding: 0;
                display: flex;
                flex-direction: column;
            }

            .productDetailsInner > .title {
                padding-right: 5px;
            }

            .productDetailsInner > .price-container {
                padding-left: 5px;
            }

            .productDescription .pd-accordion .acc-title h3 {
                padding: 10px 0;
            }
        }

        #divSize {
            width: 100%;
        }

        .productDescription {
            margin-bottom: 20px;
            padding-left: 15px;
            padding-right: 15px;
            order: 2;
        }

        .productSampleGwpContainer {
            order: 1;
        }

        #productVariantAndPrice .s-productextras-column-3 {
            text-align: right;
            margin: 0;
            width: auto;
        }

        .mainImageButtonPrev,
        .mainImageButtonNext {
            display: none;
        }

        .headerProductCode,
        .pd-accordion {
            text-align: left;
            padding: 0;
            border: none;
        }

        .delRetHelpGroup {
            background-color: #ebebeb;
            padding: 0 15px 10px;
        }

        .mobileInfoRow .pd-accordion .acc-title h3 {
            padding: 20px 0 15px;
        }

        .pd-accordion .acc-title h3 span.title-text::before,
        .pd-accordion .acc-title h3::after {
            content: none;
        }

        .pd-accordion .acc-content {
            display: block;
            padding: 0;
        }

        .helpContainer .pd-accordion .acc-content ul li a {
            padding: 10px 0;
        }

        .pd-accordion .acc-content,
        .pd-accordion .acc-content p,
        .pd-accordion .acc-content p a,
        .pd-accordion .acc-content ul li {
            letter-spacing: normal;
        }

        #MoreFromLinks .MoreFromLinksHeader {
            text-align: center;
            padding-bottom: 15px;
            white-space: normal;
        }

        #MoreFromLinks {
            flex-direction: column;
        }

        #MoreFromLinks ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
        }

        #MoreFromLinks li.MoreFromLinksRow {
            padding-top: 4px;
            padding-bottom: 4px;
        }

        #MoreFromLinks li.MoreFromLinksRow:not(:last-of-type) {
            margin-right: 8px;
            margin-left: 8px;
        }

        .product-usage-attributes {
            padding: 0 0 20px;
        }
    }

    .matchMyMakeupContainer #findShadeMMM {
        max-width: fit-content;
    }
}

@media (min-width: 1022px) {
    .FlanProdDet {
        .productDescription .sdtabBody {
            min-height: 120px;
            padding-bottom: 15px;
        }

        #MoreFromLinks .MoreFromLinksHeader {
            margin-right: 20px;
        }

        #MoreFromLinks {
            padding: 24px 48px;
        }

        #MoreFromLinks li.MoreFromLinksRow:not(:last-of-type) {
            margin-right: 30px;
        }

        #productVariantAndPrice {
            justify-content: space-between;
        }

        .matchMyMakeupContainer #findShadeMMM {
            max-width: 100%;
        }

        .pdp-image-carousel-view {
            #productDetails {
                flex: 0 0 50%;
                z-index: 1;
            }
        }
    }
}

@media (min-width: 1440px) {
    .FlanProdDet {
        .productSampleGwpContainer {
            display: flex;
        }

        .productSampleGwpContainer .gwpPromotionContainer,
        .productSampleGwpContainer .free-samples-promo-wrapper {
            flex-grow: 1;
            min-width: 50%;
        }
    }
}
