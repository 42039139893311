#divBagItems.elevated-sliding-basket {
    overflow: hidden;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    border: none;

    &.open {
        overflow: hidden !important;
    }

    .innerdivBagItems {
        max-height: calc(95vh - 85px);
        display: flex;
        flex-direction: column;
    }

    #divBagItemsChild {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
    }

    .bagHeader {
        flex: 0 0 auto;
    }

    #divulBagParent {
        flex: 1 1 auto;
    }

    #divBagItemsChild .bagHeader p {
        display: flex;
        text-transform: uppercase;
        font-size: var(--subtitle-font-size);
        font-weight: var(--fw-bold) !important;
        border-bottom: 1px solid #ddd !important;
        padding: 30px 24px !important;
        margin: 0;
        background-color: var(--flan-white);
        line-height: unset;

        .global-icon {
            margin: 0 10px;
        }

        #clsBasketMob::before {
            content: none;
        }

        #clsBasketMob {
            width: 42px;
            right: 10px;
            height: 42px;
            line-height: unset;
            top: 50%;
            translate: 0 -50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        #clsBasketMob .global-icon svg {
            width: 18px;
            height: 100%;
        }

        .basketTotalItemsHeader {
            text-transform: uppercase;
        }
    }

    .innerdivBagItems #divulBagParent {
        overflow-y: auto !important;
        width: 100%;
        max-height: 50vh;
    }

    #divButtons {
        background-color: var(--background-color-secondary);
        padding: 10px 25px 25px;
        flex: 0 0 auto;
    }

    #divButtons .SubBask {
        display: flex;
        padding: 0;

        span {
            text-transform: uppercase;
        }
    }

    #divButtons .SubBask > div {
        width: 50%;
        flex: 1 1 auto;
    }

    #divButtons .chkouterror {
        margin: 6px 0 16px;
        background-color: hsl(3 79% 95% / 1);
        padding: 8px 16px;
        text-align: center;
        font-weight: var(--fw-semibold);
    }

    #divBagTotalDiscount {
        font-size: var(--body-font-size);
        color: var(--flan-red);
        text-align: left;
    }

    #lblBagSubTotal {
        font-size: var(--body-font-size);
        font-weight: var(--fw-normal);
    }

    #spanBagSubTotalValue {
        font-size: var(--body-font-size);
    }

    .summaryWrapCTA {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        #aViewBag,
        #aCheckout {
            font-size: var(--body-font-size);
            width: 100%;
            padding: 0;
            font-weight: var(--fw-medium);
            text-transform: capitalize;

            span {
                display: block;
                padding: 15px;
                text-decoration: none !important;
            }
        }
    }

    .summaryWrapCTA #aViewBag {
        margin-bottom: 16px !important;
        border: 1px solid var(--flan-black) !important;

        &:hover {
            background: transparent !important;
        }
    }

    #aCheckout {
        border: 1px solid var(--flan-black) !important;
    }

    .chkoutopt {
        width: 100%;

        #aCheckout {
            margin: 0;

            &:hover {
                background: var(--flan-black) !important;
                color: var(--flan-neon) !important;
            }
        }
    }

    .tick-outer {
        fill: var(--flan-neon);
    }

    .tick-inner {
        fill: var(--flan-neon);
        stroke: var(--flan-black);
    }
}

.BasketCardsIcons {
    width: 55%;
}

.BasketNew .PromoCodeInput input {
    border: 1px solid var(--flan-black);
    margin-bottom: 10px;
}

.Basket .elevated-cart {
    .elevated-cart-is-empty .cart-page__empty > * {
        color: var(--flan-dark-grey);
    }

    .elevated-cart-is-empty .cart-page__primary-button {
        border: 1px solid var(--flan-black) !important;

        &:hover {
            transition: all 400ms linear 0s;
        }
    }

    .elevated-cart-is-empty .global-icon {
        padding: 15px;
        margin-bottom: 24px;
    }

    .elevated-cart-is-empty .global-icon svg path {
        stroke: var(--flan-dark-grey) !important;
        stroke-width: 2;
    }

    .basket-heading {
        text-transform: uppercase;
    }

    .basket-header .global-icon.global-icon-tick {
        .tick-outer {
            fill: var(--flan-neon);
        }

        .tick-inner {
            fill: var(--flan-neon);
            stroke: var(--flan-black);
        }
    }

    .cart-page__empty .global-icon svg {
        width: 25px;
    }

    .cart-page__primary-button {
        border: 1px solid var(--flan-black);
    }
}

.Basket .back-to-top {
    display: none !important;
}

.basket-urgency-message {
    margin: 15px 24px;
    padding: 0;
    background: none;

    @media (min-width: 1025px) {
        margin: 30px 24px 15px;
    }
}

#divBagItems .basket-urgency-message {
    padding: 10px 24px;
    background-color: var(--flan-neon);

    .global-icon svg {
        width: 22px;
        height: 22px;
    }

    &-header,
    &-text {
        text-transform: none;
    }

    &-header {
        font-weight: var(--fw-semibold) !important;
    }

    &-text {
        font-weight: var(--fw-normal) !important;
    }
}

.basket-summary-continue-button-container .ContinueOn,
.Basket .elevated-cart .PromoCodeBut {
    letter-spacing: 0.1em;
    text-transform: capitalize;
}
