.accordionMenuContentWrap {
    height: 100%;
    position: static;
    padding-bottom: 0;
}

ul#homeMenu {
    display: none;
}

.am-menu .am-level a.MobMenChevron::after,
.mp-menu .mp-level a.MobMenChevron::after,
.am-menu .am-level .glyphicon-chevron-right::before {
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background-size: 600px 600px;
    height: 25px;
    width: 15px;
    background-position: -335px -303px;
}

.am-menu .am-level a.MobMenChevron.rotate-90::after,
.am-menu .am-level .glyphicon-chevron-right.rotate-90 {
    transform: none;
}

.am-menu .am-level a.MobMenChevron.rotate-90::after,
.mp-menu .mp-level a.MobMenChevron.rotate-90::after,
.am-menu .am-level .glyphicon-chevron-right.rotate-90::before {
    background-position: -281px -187px;
}

.showAccordianMenu .ContentWrapper,
.showAccordianMenu .FooterWrap {
    display: block !important;
    visibility: visible !important;
}

/* No results when regex searching the FGP solution for "SubMenuPaneWrap" */
.SubMenuPaneWrap {
    position: absolute;
    top: 72px;
    width: 100%;
}

/* No results when regex searching the FGP solution for "SubMenuPane" */
.SubMenuPane {
    min-height: 37px !important;
    padding: 0 !important;
    width: 100%;
    max-width: 1210px;
    margin: 0 auto;
    position: relative;
}

.SubMenuGroup {
    margin: 0 !important;
    padding: 0.727em 0.018em;

    li {
        font-size: 1em !important;
        padding: 0 12px !important;
    }

    a {
        letter-spacing: 2px;
    }
}

nav#topMenu ul li.root {
    &.twoPromo .SubMenuWrapper > ul > li {
        &.Center {
            width: 60%;
        }

        &.Right {
            width: 40%;
        }
    }

    &.noPromo .SubMenuWrapper > ul > li {
        &.Center {
            width: 100%;
            padding-right: 0;
        }

        &.Right {
            display: none !important;
        }
    }

    .SubMenuWrapper > ul {
        &::before,
        &::after {
            content: "";
            display: table;
        }

        &::after {
            clear: both;
        }
    }

    div.SubMenuWrapper > ul {
        max-width: 1600px;
        margin: 0 auto;
        padding: 40px 0 0;
    }

    div.SubMenuWrapper {
        top: auto;
        width: 100%;
        font-size: 1em;
        padding: 0;
        position: fixed;
        max-height: calc(100% - 130px);
        overflow-y: auto !important;
    }

    &.sdHover div.SubMenuWrapper {
        visibility: visible;
        opacity: 1;
    }

    > ul {
        border: none;
        box-shadow: none;
        top: 72px;
    }

    > a {
        position: relative;
        letter-spacing: 1px;
        line-height: 50px;
        width: auto;
        height: auto;
        padding: 0 10px;
        font-size: 1.2em;
        transition: none;
    }

    &.sdHover > a {
        background-color: var(--flan-neon);
        color: var(--flan-black);
    }

    &.MenuGroupOutlet > a {
        color: var(--flan-neon);
    }

    &.MenuGroupOutlet.sdHover > a {
        color: var(--flan-black);
    }
}

.groupquestions li {
    padding-left: 0;
    background: none !important;
}

.relatedquestions h4 {
    border-bottom: 1px dotted #ccc;
}

.csHomeTable {
    border: 0;
    min-height: 278px;

    li.header a {
        font-weight: var(--fw-semibold);
    }
}

#topMenuWrapper {
    position: static;
    left: auto;
    top: auto;
    width: auto;
    height: 100%;
    z-index: 5200 !important;
}

#topMenu {
    width: 100%;
    position: static;
    margin: 0;
    max-width: 100%;
    padding: 0;
    height: 100%;
    text-align: center;
}

nav#topMenu {
    li.root {
        position: static;
    }

    ul li.root div.SubMenuWrapper .Center > ul {
        display: flex;
    }

    > ul li.columnGroup {
        width: 20%;
        flex-grow: 1;
    }

    .twoCol ul li.columnGroup {
        width: 50%;
    }

    .threeCol ul li.columnGroup {
        width: 33.333333%;
    }

    .fourCol ul li.columnGroup {
        width: 25%;
    }

    .sixCol ul li.columnGroup {
        width: 16.666667%;
    }

    ul li.root .SubMenuWrapper {
        ul li.ActivewearDropdown > ul {
            z-index: 5200;
        }

        ul li.level1 {
            &.sdHover > ul {
                opacity: 1;
                visibility: visible;
            }

            &.allChildrenHiddenDesktop > ul .columnGroup ul,
            &.allChildrenHiddenDesktop:hover > ul .columnGroup ul {
                min-width: 0;
            }

            > ul li.columnGroup ul {
                border: none;
            }

            &.sdHover::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 1px;
                background: #fff;
                bottom: -1px;
                z-index: 1;
            }

            &.sdHover > a {
                font-weight: var(--fw-semibold);
                border-color: var(--flan-neon);
                border-bottom-color: #fff;
            }
        }

        ul li.level2 {
            display: block;
        }

        .sdmColGap > a {
            margin-top: 15px;
        }

        > ul > li {
            &.Center {
                width: 75%;
                float: left;
                padding-right: 30px;
            }

            &.Right {
                width: 25%;
            }
        }
    }

    li.root .SubMenuWrapper {
        li.Bottom {
            width: 100%;
            text-align: center;
            border-top: 1px solid #e0e0e0;
            margin-top: 30px;
        }

        > ul li.Bottom a {
            padding: 20px;
            font-weight: var(--fw-semibold);
            text-transform: uppercase;
            color: var(--flan-black);
        }
    }
}

.ui-autocomplete.ui-front.ui-menu {
    z-index: 7001 !important;
    padding: 10px 5px;
    box-shadow: none;

    > li {
        padding: 0;
        box-shadow: none;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .header {
        border-bottom: none;
        margin: 0;
        padding: 5px 10px;
        flex-grow: 1;
    }

    .autocomplete-split-enabled:not(.ui-menu-item):not(:first-child)::before {
        content: "";
        display: list-item;
        margin: 5px 0;
        background-color: #ececec;
        height: 1px;
        min-height: 1px;
        width: calc(100% - 20px);
        text-align: center;
        justify-content: center;
        transform: translateX(10px);
    }
}

.ui-menu .ui-menu-item a {
    padding: 5px 10px;
    color: var(--flan-black);
    font-size: 1.2em;
}

.ui-autocomplete li.autocomplete-split-enabled > .ui-corner-all {
    padding: 5px 10px !important;
    flex-grow: 1;
}

.menu-trigger {
    background-image: none;
    background-color: transparent;
    height: 55px;
    width: 55px;
    position: absolute;

    &:hover {
        background-color: transparent;
    }

    .global-icon-menu {
        svg {
            width: 30px;
            height: 30px;
        }
    }

    .global-icon {
        display: inline;
        pointer-events: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .global-icon-cross {
        display: none;

        svg {
            width: 21px;
            height: 21px;
        }
    }
}

.PullMenuActive {
    .global-icon-cross {
        display: inline;
    }

    .global-icon-menu {
        display: none;
    }
}
