.modal,
#hotspotModal.modal,
#GwpPromotionDetailModal.modal {
    .modal-header {
        font-weight: var(--fw-medium);
        font-size: var(--body-font-size);
        padding: 15px 40px 15px 15px;
        position: relative;
    }

    .modal-header .text-center {
        text-align: left;
        line-height: normal;
        display: block;
    }

    .modal-header .text-center .header-text {
        font-size: var(--subtitle-font-size);
        font-weight: var(--fw-medium);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        height: 100%;
        line-height: normal;
        display: flex;
        align-items: center;
    }

    .modal-header .close {
        background: var(--spritesheet) no-repeat center center !important;
        width: 40px;
        height: 40px;
        background-size: 390px 259px !important;
        background-position: -28px -119px !important;
        border: 0;
        top: 50%;
        translate: 0 -50%;
    }

    .modal-content {
        border: none;
    }
}
