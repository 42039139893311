.FooterWrap {
    z-index: 1 !important;
    border-top: 0;
    width: 100%;
    margin: 20px auto 0;
    padding: 0;

    .container-fluid {
        padding: 0;
    }

    .maxFooter {
        margin: 0 auto;
        padding: 0 2%;

        .footerQuickLinks {
            margin: 35px auto 0;
            max-width: 1600px;

            span {
                padding: 0;
            }
        }
    }

    .fullFooter {
        padding: 10px 2%;

        .footerSocialContainer {
            max-width: 1600px;
            margin: 0 auto;
        }

        .LogoWrap {
            height: 50px;

            img {
                width: 120px;
                margin: 15px 0;
            }
        }

        .footerCopyRightLine {
            height: 50px;

            span {
                float: right;
                line-height: 50px;
            }
        }

        @media (max-width: 374px) {
            .footerLogo {
                width: 40%;
            }

            .footerCopyRightLine {
                width: 60%;
            }

            .LogoWrap img {
                width: 100px;
            }
        }

        @media (max-width: 424px) {
            .footerLogo {
                width: 45%;
            }

            .footerCopyRightLine {
                width: 55%;
            }
        }

        @media (max-width: 767px) {
            .LogoWrap {
                padding-left: 10px;
            }

            .footerCopyRightLine span {
                padding-right: 10px;
                text-align: right;
            }
        }
    }

    .Copyright {
        max-width: none;
        margin: 0;

        span {
            margin: 0;
        }
    }

    .FooterSubGroup {
        padding: 0 15px 0 0;

        @media (max-width: 767px) {
            padding: 0;
        }
    }

    .footerMenu > .col-xs-12:last-child .FooterSubGroup {
        padding-right: 0;
    }
}
