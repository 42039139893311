.BreadcrumbGroupWrapper {
    max-width: var(--max-container-width) !important;
    margin: 0 auto;
    position: relative;
    padding: 16px 0;

    .flanProdList &,
    .FlanProdDet & {
        padding: 16px 20px;

        @media (min-width: 1022px) {
            padding: 16px 32px;
        }
    }

    @media (min-width: 768px) {
        padding: 16px 20px;
    }

    @media (min-width: 1022px) {
        padding: 16px 32px;
    }

    > div {
        padding: 0;
    }
}

.breadcrumbs__list {
    display: flex;
    flex-flow: wrap;
    list-style: none;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.breadcrumbs__link,
li.breadcrumbs__item > a {
    display: block;
    line-height: 24px;
    color: #707070 !important;
    text-transform: uppercase;
    text-decoration: underline;
}

li.bc-inserted.last {
    display: block;
    line-height: 24px;
    color: #707070 !important;
    text-transform: uppercase;
    text-decoration: none;
}

.breadcrumb-seperator {
    display: inline-block;
    height: 13px;
    width: 10px;
    background-image: url("/images/core/chevron-sprite.svg");
    background-size: 100px 100px;
    background-position: -44px -26px;
    margin: 4px 5px;
    text-indent: 100%;
    overflow: hidden;
    vertical-align: text-bottom;
}

.breadcrumbs__link:hover,
.breadcrumbs__link:focus,
li.breadcrumbs__item > a:hover,
li.breadcrumbs__item > a:focus {
    text-decoration: none;
}

.breadcrumbs__link:not(.breadcrumbs__link--current) {
    position: relative;
    padding-right: 26px;
}

.breadcrumbs__link:not(.breadcrumbs__link--current)::after {
    position: absolute;
    top: 50%;
    right: 14px;
    display: block;
    width: 4px;
    height: 7px;
    transform: translate(0, -50%);
    background-image: url("/images/core/chevron-sprite.svg");
    background-repeat: no-repeat;
    background-size: 100px 100px;
    background-position: -46px -30px;
    content: "";
}

.breadcrumbs__link--current {
    text-decoration: none;
}

.breadcrumbs__link {
    font-size: 12px;
}

li#breadcrumbs__back {
    font-weight: 800;
    color: #000;
    cursor: pointer;
    line-height: 24px;
    font-size: 12px;
    text-transform: uppercase;
}
li#breadcrumbs__back:hover {
    text-decoration: none;
}

li#breadcrumbs__back .global-icon-chevron,
li#breadcrumbs__back .breadcrumbs__link {
    display: inline-block;
}

li#breadcrumbs__back .global-icon-chevron svg {
    width: 10px;
    height: 10px;
}

@media (max-width: 767px) {
    .BreadcrumbStore {
        padding: 0 16px;
    }

    .BreadcrumbStoreFinder {
        padding: 10px 45px 0;
    }

    .breadcrumbs__link:not(.breadcrumbs__link--current) {
        padding-right: 18px;
    }

    .breadcrumbs__link:not(.breadcrumbs__link--current)::after {
        right: 6px;
    }
}

@media (min-width: 768px) and (max-width: 1021px) {
    .BreadcrumbStore {
        padding: 0 16px;
    }

    .BreadcrumbStoreFinder {
        padding: 10px 45px 0;
    }

    .breadcrumbs {
        flex: 0 0 100%;
    }

    .breadcrumbs__link {
        font-size: 12px;
    }

    .breadcrumbs__link:not(.breadcrumbs__link--current) {
        padding-right: 26px;
    }

    .breadcrumbs__link:not(.breadcrumbs__link--current)::after {
        right: 12px;
    }
}

@media (max-width: 1021px) {
    .s-breadcrumbs-container {
        text-align: center;
    }
}

@media (min-width: 1022px) {
    .BreadcrumbStore {
        padding: 0 16px;
    }

    .BreadcrumbStoreFinder {
        padding: 10px 21px 0;
    }
}
