.flanProdList .productFilterList,
.ChildCategoriesListWrapper #ChildCategoriesList,
.ui-autocomplete.ui-front.ui-menu,
#innerfiltercontainer,
.innerdivBagItems #divulBagParent,
.dropdown-menu,
#divWishList .ul-container {
    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: #ccc;
    }

    &::-webkit-scrollbar-thumb {
        background: #000;
        border-radius: 0;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #888;
    }
}

.flanProdList .productFilterList,
.ChildCategoriesListWrapper #ChildCategoriesList,
.innerdivBagItems #divulBagParent,
#innerfiltercontainer,
.dropdown-menu,
#divWishList .ul-container {
    &::-webkit-scrollbar {
        width: 5px;
    }
}
