#hotspotModal {
    .modal-dialog {
        max-width: 800px;
    }

    .PinWrapImage {
        padding: 0 5px;

        @media (min-width: 768px) {
            width: 45%;
        }

        @media (max-width: 767px) {
            max-width: none;
            margin-bottom: 10px;
        }
    }

    .swiper-horizontal {
        .swiper-button-next,
        .swiper-button-prev {
            background-image: none;
            width: 40px;
            height: 40px;

            &::before,
            &::after {
                font-size: 20px;
            }
        }

        .swiper-pagination.swiper-pagination-bullets {
            bottom: 0;
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            justify-content: center;

            .swiper-pagination-bullet {
                height: 3px;
                width: 34px;
                margin: 0;
                border-radius: 0;
                background: var(--flan-inactive-pagination);
                opacity: 1;
            }

            .swiper-pagination-bullet-active {
                background: var(--flan-black);
            }
        }

        @media (max-width: 767px) {
            max-width: 200px;
            position: static;

            .swiper-pagination-bullets {
                bottom: 0;
            }
        }
    }

    .PinWrapText {
        h2 {
            text-transform: uppercase;
            padding: 0 0 5px;
            font-size: 20px;

            span {
                display: block;
                font-size: 14px;
                font-weight: var(--fw-normal);
            }
        }

        @media (min-width: 768px) {
            width: 55%;
            z-index: 10;
            background-color: #fff;
        }
    }

    #hsPriceWrapper {
        display: flex;
        padding-bottom: 25px;

        div {
            font-size: 14px;
            line-height: 1em;
            padding: 0 5px 0 0;
        }

        #hsPrice {
            color: var(--flan-black);
            font-weight: var(--fw-semibold);
        }

        #hsRefPrice {
            color: #b30000;
        }

        &:has(#hsRefPrice) #hsPrice {
            color: var(--flan-red);
        }
    }

    .member-price {
        font-size: 14px;
        line-height: 1em;
        padding: 0 5px;
    }

    .hsColourDesc,
    .BuySizeText {
        font-size: 14px;
        padding-bottom: 5px;

        span:first-child::after {
            content: ":";
        }
    }

    .hsColourSelection {
        padding: 0 5px 25px;

        .hsVariantButtons {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;

            li {
                height: 50px;
                width: 50px;
                margin: 0;
            }

            li::before {
                display: none;
            }

            li a {
                padding: 2px;
                border: 1px solid var(--secondary-border-colour);
            }

            li.hsVariantHighlight a {
                border-color: var(--flan-black) !important;
            }
        }
    }

    .BuySizeText {
        .hsSizeLabel {
            padding: 0;
        }
    }

    .hsSizeSelection {
        padding: 0 5px 25px;

        .hsVariantButtons {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 10px;

            li {
                margin: 0;
            }

            li::before {
                display: none;
            }

            li a {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;
                line-height: 1em;
                font-size: 14px;
                border: 1px solid var(--secondary-border-colour);
            }

            li.hsVariantHighlight a {
                font-weight: var(--fw-semibold);
                border-color: var(--flan-black) !important;
            }

            li.greyOut a {
                background: none;
                opacity: 0.2;
            }
        }
    }

    #hsAddToBagContainer {
        width: 100%;
        float: left;
        margin-bottom: 25px;

        &.sizeRequired a#addHotspotToBag {
            background-color: #979797;
            border-color: #979797;
        }

        .innerHotSpotLine {
            text-transform: capitalize;
        }
    }

    #addHotspotToWishList {
        display: block;
        float: left;
        width: 100%;
        height: auto;
        letter-spacing: 0.1em;
        font-weight: var(--fw-medium);
        text-transform: capitalize;
    }

    #hsViewProduct {
        float: left;
        width: 100%;
        text-align: left;

        a {
            font-size: 14px;
            text-decoration: underline;
        }
    }
}
