@import "./images-swiper.css";
@import "./images-video.css";

@keyframes loading-spinner {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.FlanProdDet #productImages.ImageNotReady::before,
.FlanProdDet #productImages.ImageNotReady::after {
    content: "";
    display: block;
    position: absolute;
}

.FlanProdDet #productImages.ImageNotReady::before {
    background-color: var(--flan-white);
    opacity: 0.8;
    block-size: 100%;
    inline-size: 100%;
    z-index: 2;
    inset-block-start: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    inset-block-end: 0;
}

.FlanProdDet #productImages.ImageNotReady::after {
    border-radius: 50%;
    block-size: 48px;
    inline-size: 48px;
    z-index: 3;
    inset-inline-start: 50%;
    inset-block-start: 50%;
    translate: -50% -50%;
    border-width: 3px;
    border-style: solid;
    border-color: var(--flan-black) var(--flan-black) var(--flan-neon) var(--flan-black);
    animation: 1s loading-spinner linear infinite;
}

.FlanProdDet {
    #productImages {
        position: relative;
        z-index: 1;
        padding-right: 0;

        .imageControls .zoomControl img {
            vertical-align: text-top;
        }

        .ThumbProdWrap {
            display: flex;
            flex-direction: column;
            position: relative;
            z-index: 1;
            opacity: 1;
        }

        #productImageContainer {
            .productRollOverPanel {
                overflow: hidden;
            }

            .productImageSash {
                top: 0;
                left: 0;
            }

            .productImage {
                height: 100%;

                img {
                    display: inline-block;
                    aspect-ratio: 1/1;
                }
            }
        }

        .pdpThumbs {
            order: 2;
        }
    }

    #ZoomPopup .close {
        background: none !important;
        /* Important used here to override bootstraps uneccessary important ;( */
        border: 0;
        top: 0;
        transform: translateY(50%);
        font-size: 0;
        width: 32px;
        height: 32px;
        text-indent: unset;
        color: transparent;
        right: 30px;
        border-radius: 0;

        &::before,
        &::after {
            position: absolute;
            top: 0;
            right: 15px;
            content: "";
            height: 32px;
            width: 3px;
            background-color: var(--flan-black);
        }

        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }
    }

    .viewMoreNumber {
        display: none;
    }

    .pdp-image-carousel-view {
        .u-contain {
            flex-wrap: wrap;
        }

        #productImages {
            display: flex;
            flex-wrap: wrap;
            width: auto;
            padding: 0;

            #productImageContainer {
                box-shadow: 0 0 10px 0 hsla(0deg, 0%, 0%, 0.1);
            }

            .imgdot-zoom {
                display: none;
            }
        }
    }

    .pdp-image-grid-view {
        #productImages {
            #productImageContainer .productImage {
                position: relative;
                height: 100%;
            }

            .innerImageContainer {
                display: grid;
                gap: 5px;
                grid-template-columns: repeat(2, auto);
            }

            .gridDisplayOne .innerImageContainer {
                grid-template-columns: repeat(1, auto);
            }

            #ImagesLoadingPlaceHolder {
                position: relative;
            }
        }
    }
}

@media (max-width: 767px) {
    .FlanProdDet {
        .pdp-image-grid-view #productImages.ImageNotReady #productImageContainer .productImage {
            display: flex;
            overflow: hidden;
        }

        .pdp-image-grid-view #productImages.ImageNotReady #productImageContainer .productRollOverPanel {
            flex: 0 0 100%;
        }

        #productImages {
            width: 100%;
            padding-top: 10px;
        }

        .pdp-image-carousel-view {
            #productImages #productImageContainer {
                box-shadow: none;
            }
        }

        .pdp-image-grid-view {
            #productImages {
                .innerImageContainer {
                    display: flex;
                    gap: 0;
                }
            }
        }

        #ZoomPopup .close {
            right: 16px;
        }
    }
}

@media (min-width: 768px) {
    .FlanProdDet {
        #productImages #productImageContainer .productImage.zIndexAboveColourSelector {
            box-shadow: 0 0 10px 4px hsla(0deg, 0%, 0%, 0.1);
            border: none;
            margin: 0;
        }

        .viewMoreNumber {
            position: relative;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;
            cursor: pointer;
            font-family: var(--brand-font) !important;
            font-weight: var(--fw-semibold);

            span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 5em;
                color: #000;
                z-index: 1;
            }

            .viewMoreImageGrid {
                flex: 0 0 50%;
                opacity: 0.3;
                background-size: cover;

                &::before {
                    content: "";
                    display: block;
                    padding-bottom: 100%;
                }
            }
        }

        .viewMoreHide {
            display: none;
        }
    }
}

@media (min-width: 1022px) {
    .FlanProdDet {
        #productImages {
            #productImageContainer .productImage.zIndexAboveColourSelector {
                z-index: 5000 !important;
            }

            .keyBenefitsWrapper {
                margin-top: 60px;
                width: 100%;
            }
        }

        .pdp-image-carousel-view {
            #productImages {
                flex: 0 0 50%;
                z-index: 2;
            }
        }

        .pdp-image-grid-view {
            #productImages {
                .keyBenefitsWrapper {
                    margin-top: 20px;
                }
            }
        }
    }
}

@media (max-width: 1021px) {
    .FlanProdDet {
        #productImages {
            .ThumbProdWrap .pdpThumbs {
                bottom: auto;
                position: relative;
            }
        }

        .pdp-image-grid-view {
            #productImages {
                .innerImageContainer {
                    grid-template-columns: repeat(1, auto);
                }
            }
        }
    }
}
