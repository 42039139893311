.product-line-card {
    a,
    p,
    div,
    label,
    &__description-remove-button {
        font-size: var(--body-font-size) !important;
        font-weight: var(--fw-normal) !important;
        color: var(--flan-black);
    }

    &__description-line-error-message {
        fill: var(--flan-red) !important;
    }

    &__description-move button .button-icon svg,
    &__description-remove button .button-icon svg {
        width: 20px;
        height: 20px;
    }

    &__description-move button .button-icon {
        svg path {
            stroke-width: 2px;
        }

        .global-icon-bag {
            svg path {
                stroke-width: 1px;
            }
        }
    }

    &__description-brand {
        font-size: 16px !important;
        color: #000 !important;
        font-weight: var(--fw-semibold) !important;
    }

    &__description-sizes label {
        font-weight: var(--fw-normal) !important;
    }

    &__price {
        justify-content: space-between !important;
    }

    .wishlist-summary & {
        &__description-move button .button-icon svg {
            height: auto;
            width: auto;
        }
    }

    .printess-header-container {
        font-weight: var(--fw-medium) !important;
    }
}
