.ndd-countdown {
    background-color: transparent;
    padding: 8px;
    text-align: center;
    overflow: hidden;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
}

.ndd-countdown-title,
.inline-countdown .ndd-countdown-title {
    font-size: 14px;
    font-weight: var(--fw-semibold);
    margin-bottom: 5px;
    flex: 1 1 100%;
}

.ndd-countdown .countdown-timer {
    margin: 0 0 0 4px;
}

.ndd-countdown .countdown-timer .countdown-timer-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: var(--flan-black);
    background-color: transparent;
    padding: 0;
}

.ndd-countdown .countdown-timer-hour-container,
.ndd-countdown .countdown-timer-minute-container,
.ndd-countdown .countdown-timer-second-container {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.ndd-countdown .countdown-timer-minute-container,
.ndd-countdown .countdown-timer-second-container {
    margin-left: 4px;
}

.ndd-countdown .ndd-countdown-time-hour,
.ndd-countdown .ndd-countdown-time-minute,
.ndd-countdown .ndd-countdown-time-second {
    font-weight: var(--fw-semibold);
    min-width: 20px;
    min-height: 19px;
    position: relative;
    text-align: right;
}

.ndd-countdown .countdown-timer .countdown-timer-wrapper .ndd-countdown-time-unit {
    font-weight: var(--fw-semibold);
}

.ndd-countdown .ndd-counter-time-loader {
    color: var(--flan-black);
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: 0.5s;
    transform: translateX(-3px);
    display: inline-block;
}

.inline-countdown.ndd-countdown {
    padding: 10px;
    text-align: center;
    overflow: hidden;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    background-color: #f2f2f2;
    font-size: 14px;
}

.inline-countdown.ndd-countdown-title {
    font-weight: var(--fw-normal);
    margin-bottom: 0;
    order: 1;
}

.inline-countdown.ndd-countdown-description {
    order: 3;
}

.inline-countdown.ndd-countdown .countdown-timer {
    font-weight: var(--fw-semibold);
    display: flex;
    justify-content: center;
    order: 2;
    margin: 0 6px 0 0;
}

.inline-countdown.ndd-countdown .countdown-timer .countdown-timer-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    color: var(--flan-black);
    background-color: transparent;
    padding: 0;
}

.inline-countdown.ndd-countdown .countdown-timer-hour-container,
.inline-countdown.ndd-countdown .countdown-timer-minute-container,
.inline-countdown.ndd-countdown .countdown-timer-second-container {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.inline-countdown.ndd-countdown .countdown-timer-minute-container,
.inline-countdown.ndd-countdown .countdown-timer-second-container {
    margin-left: 4px;
}

.inline-countdown.ndd-countdown .ndd-countdown-time-hour,
.inline-countdown.ndd-countdown .ndd-countdown-time-minute,
.inline-countdown.ndd-countdown .ndd-countdown-time-second {
    min-width: 20px;
    min-height: 19px;
    display: inline-block;
    position: relative;
    text-align: right;
    font-weight: var(--fw-semibold);
}

.inline-countdown.ndd-countdown .countdown-timer .countdown-timer-wrapper .ndd-countdown-time-unit {
    font-weight: var(--fw-semibold);
}

.inline-countdown.ndd-countdown .ndd-counter-time-loader {
    color: #e10098;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: 0.5s;
    transform: translateX(-3px);
    display: inline-block;
}

.productNextDayDeliveryCountdownWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 10px solid var(--flan-neon);
    margin: 0 0 20px;
}

.frasers-plus-enabled {
    .productNextDayDeliveryCountdownWrapper {
        margin: 0 0 15px;
    }
}

@keyframes dotFlashing {
    0% {
        color: var(--flan-black);
    }

    50%,
    100% {
        color: var(--flan-white);
    }
}
