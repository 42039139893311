#ulBag li .fulfilledBy {
    line-height: 1.2em;

    span {
        text-transform: none;

        span {
            text-transform: uppercase;
        }
    }

    a {
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}

#DropShipSupplierModal {
    .close {
        background: url("/images/core/flannels-20-sprite-sheet-v9.svg") no-repeat -162px -248px #f5f5f5 !important;
        width: 30px;
        height: 30px;
        background-size: 500px 500px !important;
        border: none;
    }

    .header-text {
        font-size: 14px;
        font-weight: var(--fw-medium);

        @media (min-width: 768px) {
            font-size: 16px;
        }
    }

    #dropshipAccordion {
        h4.panel-title a {
            background: var(--flan-white);
            color: var(--flan-black) !important;
            font-weight: var(--fw-medium);
            text-decoration: underline;
            position: relative;
            padding: 10px 0 15px 10px;

            @media (min-width: 768px) {
                font-size: 16px;
            }

            &.collapsed {
                &::before {
                    transform: rotate(-180deg);
                }

                &::after {
                    transform: rotate(-180deg);
                    top: 15px;
                }
            }

            &::before {
                content: "";
                background-image: none;
                position: absolute;
                width: auto;
                height: auto;
                top: 15px;
                right: 2px;
                border-bottom: 8px solid #000;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
            }

            &::after {
                content: "";
                background-image: none;
                position: absolute;
                width: auto;
                height: auto;
                top: 18px;
                right: 5px;
                border-bottom: 5px solid #fff;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                transform: none;
            }
        }
    }
}

.FlanProdDet {
    .product-info {
        .dropshipTitle {
            margin-bottom: 20px;

            a {
                text-transform: uppercase;
            }
        }
    }
}

.fulfilledBy {
    span strong {
        text-transform: uppercase;
    }

    a {
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}

.prodDescContainer .s-basket-save-for-later-button {
    float: none;
}
