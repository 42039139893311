.categoryCopyContainer {
    max-width: var(--max-container-width);
    margin: 0 auto;
    padding: 0;
}

.categoryCopyContainer > div {
    padding: 0;
}

.mobile-read-more-less-enabled #lblCategoryCopy .textDesc + #read-more-less {
    font-family: var(--brand-font) !important;
    font-weight: var(--fw-medium);
    text-align: left;
    padding: 0 !important;
}

@keyframes moveSlideshow {
    100% {
        transform: translateX(-35%);
    }
}

.flanProdList {
    .topheadbox {
        position: relative;
        padding: 0 0 15px;
        background-image: none !important;
        display: flex;
        justify-content: center;
        text-align: left;
    }

    .topheadbox > h1.s-white {
        display: block !important;
    }

    &.flan20BLP {
        .topheadbox .categorycopyd4 {
            position: relative;
            bottom: 0;
            margin: 0;
            max-width: none !important;
            background-color: rgba(255, 255, 255, 0.8);
            transition: min-height 400ms linear 0s;
            padding: 0 calc(var(--padding-unit) * 5);

            h1 {
                text-align: left;
                padding: calc(var(--padding-unit) * 2.5);
            }

            @media (min-width: 768px) {
                padding: calc(var(--padding-unit) * 5);
            }

            @media (min-width: 1022px) {
                padding: calc(var(--padding-unit) * 5) calc(var(--padding-unit) * 8);
            }

            .catTemplate .catDesc,
            .catTemplate .topSection {
                position: relative;
                padding: 0 0 calc(var(--padding-unit) * 6);
            }
        }

        .topheadbox {
            display: block;
            max-width: var(--max-container-width);
            margin: 0 auto;
            position: relative;
            overflow: visible;
            z-index: 5010;
            padding: 0;
        }

        .topheadbox .blp_carousel {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transform: translate3d(0, 0, 0);
            overflow: hidden;
            z-index: -1;

            &::before {
                content: "";
                display: block;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                transform: translate3d(0, 0, 0);
                background-repeat: repeat-x !important;
                background-size: cover !important;
                aspect-ratio: 1;

                @media (min-width: 768px) {
                    width: 2645px;
                    animation: moveSlideshow 15s linear 0s infinite;
                }
            }

            @media (min-width: 768px) {
                display: block;
                position: relative;
                min-height: 250px;
            }
        }

        #catHeader {
            padding: 0;
        }

        .siblingLinks {
            padding: calc(var(--padding-unit) * 4) 0 0;

            @media (min-width: 768px) {
                padding: calc(var(--padding-unit) * 4) 0 0;
            }

            span:first-of-type {
                margin-left: calc(var(--padding-unit) * 5);

                @media (min-width: 1022px) {
                    margin-left: 0;
                }
            }
        }
    }

    .categorycopyd4 {
        padding: 0;
        text-align: left;

        .catTemplate .catDesc,
        .catTemplate .topSection {
            padding: 0 calc(var(--padding-unit) * 5) calc(var(--padding-unit) * 2.5);
            display: flex;
            flex-direction: column;

            @media (min-width: 1022px) {
                padding: 0 calc(var(--padding-unit) * 8) calc(var(--padding-unit) * 2.5);
            }
        }
    }

    #catHeader {
        padding: 0 calc(var(--padding-unit) * 5);
        text-align: left;

        @media (min-width: 1022px) {
            padding: 0 calc(var(--padding-unit) * 8);
        }
    }

    &.browse-search {
        .categorycopyd4 {
            padding: 0 calc(var(--padding-unit) * 5);

            @media (min-width: 1022px) {
                padding: 0 calc(var(--padding-unit) * 8);
            }
        }

        #catHeader {
            padding: 0 0 calc(var(--padding-unit) * 5);
        }
    }

    .siblingLinks {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow-x: auto;
        max-width: 1520px;
        width: 100%;
        min-height: 45px;
        right: 0;
        z-index: 5020;
        padding: 0 calc(var(--padding-unit) * 5);
        clear: left;
        margin: calc(var(--padding-unit) * 5) auto 0;

        span:first-of-type {
            margin-left: calc(var(--padding-unit) * 5);

            @media (min-width: 1022px) {
                margin-left: 0;
            }
        }

        @media (min-width: 1022px) {
            padding: calc(var(--padding-unit) * 2.5) 0;
        }
    }

    #lblCategoryCopy.has-line-clamp .siblingLinks {
        margin: 10px auto 0 0;
    }

    .siblingLinks .siblingSlide {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        transform: translate3d(0, 0, 0);
    }

    .siblingLinks a {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: var(--brand-font) !important;
        font-weight: var(--fw-medium);
        color: var(--flan-black);
        min-width: 175px;
        border: 1px solid var(--flan-black);
        text-transform: uppercase;
        text-decoration: none;
        padding: 0 calc(var(--padding-unit) * 5);
        height: 40px;
        margin: 0 calc(var(--padding-unit) * 5) calc(var(--padding-unit) * 5) 0;
        white-space: nowrap;
        font-size: 14px;
        line-height: 14px;
        overflow: hidden;
        background-color: transparent;

        &:hover {
            background-color: var(--flan-black);
            color: var(--flan-neon);
        }

        @media (min-width: 768px) {
            padding: 0 calc(var(--padding-unit) * 8);
        }
    }

    .siblingLinks .siblingSlide .swiper-slide {
        max-width: fit-content;

        &:last-child a {
            margin-right: 0;
        }
    }

    .textDesc {
        position: relative;
        padding: 0;
        margin: 0;
        text-align: left !important;
        text-overflow: ellipsis;
        font-size: 14px;
        line-height: 20px;
        overflow: hidden;
        transition: height 0.25s linear 0s;
        color: var(--flan-black);

        a {
            text-decoration: underline;
        }
    }

    .textDesc.read-more {
        max-height: none;
    }

    #lblCategoryCopy {
        margin-top: 20px;

        > div {
            padding: 0 calc(var(--padding-unit) * 5);

            @media (min-width: 1022px) {
                padding: 0 calc(var(--padding-unit) * 8);
            }
        }

        .catTemplate {
            padding: 0;

            .catDesc {
                position: relative;
                padding: 0 calc(var(--padding-unit) * 5) calc(var(--padding-unit) * 6);

                @media (min-width: 1022px) {
                    padding: 0 calc(var(--padding-unit) * 8) calc(var(--padding-unit) * 6);
                }
            }
        }
    }

    #lblCategorySearchCopy,
    #CategoryAcross {
        font-size: 14px;
        line-height: 20px;
    }

    .textDesc + #read-more-less {
        position: absolute;
        bottom: 0;
    }

    .textDesc #catCopyMobileReadMoreLess + #catCopyMobileReadMoreLess {
        display: none;
    }
}

.ContentWrapper.ProductsFiltered .topheadbox {
    height: auto;
    display: block;

    .categorycopyd4 {
        max-width: 100%;
        margin-left: 0;
    }

    .blp_carousel,
    #lblCategoryCopy {
        display: none;
    }
}

@media (max-width: 425px) {
    .flanProdList {
        .topheadbox .blp_carousel::before {
            display: none;
            animation: none;
        }

        .topheadbox .categorycopyd4 {
            margin: 0;
            max-width: 100%;
        }

        &.flan20BLP {
            .topheadbox {
                padding-top: calc(var(--padding-unit) * 2.5);

                .blp_carousel::before {
                    display: none;
                    animation: none;
                }

                .categorycopyd4 {
                    margin: 0;
                    max-width: 100%;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .Browse #lblCategoryCopy {
        display: block;
    }
}

@media (max-width: 1021px) {
    .flanProdList {
        .topheadbox {
            height: auto;
            padding: calc(var(--padding-unit) * 2.5) 0 0;
            margin-bottom: 0;
            min-height: auto;
        }

        &.flan20BLP .topheadbox {
            margin-bottom: 0 !important;
            padding-top: 0;
            height: auto;

            .categorycopyd4 {
                min-height: auto;
                margin: 0;
            }

            .blp_carousel {
                &::before {
                    background-size: contain !important;
                }
            }
        }
    }
}

#divsiblinglistwrapper ul.ulFirst,
#lblCategoryCopy .siblingLinks,
.topheadbox .siblingLinks {
    &::-webkit-scrollbar-thumb {
        background: var(--flan-black);
        border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
        background: var(--secondary-border-colour);
        border-radius: 4px;
        margin-left: 20px;
        margin-right: 20px;
    }

    &::-webkit-scrollbar {
        height: 5px;
    }
}
