.sitewide-banner-enabled {
    &.searchFocus .ui-autocomplete.ui-front.ui-menu {
        transform: translateY(160px);
    }

    .wishlist-page__header {
        top: calc(110px + var(--sitewide-banner-height));

        @media (min-width: 768px) {
            top: calc(126px + var(--sitewide-banner-height));
        }

        @media (min-width: 1022px) {
            top: calc(146px + var(--sitewide-banner-height));
        }
    }

    .menu-search-hidden .wishlist-page__header {
        top: 0;

        @media (min-width: 1022px) {
            top: 146px;
        }
    }

    #divWishList .wishlist-summary {
        top: calc(54px + var(--sitewide-banner-height)) !important;

        @media (min-width: 1022px) {
            top: calc(65px + var(--sitewide-banner-height)) !important;
        }
    }

    #divBagItems {
        @media (max-width: 1021px) {
            top: calc(14px + var(--sitewide-banner-height));
            bottom: auto;
        }
    }

    &.sitewide-banner-two-lines {
        #divBagItems {
            @media (min-width: 1022px) {
                top: calc(10px + var(--sitewide-banner-height));
            }
        }
    }

    @media (min-width: 1022px) {
        .menu-search-hidden .HeaderWrap {
            transform: translateY(calc(-1 * var(--sitewide-banner-height)));
        }

        #mp-pusher #mp-menu {
            height: calc(100% - (110px + var(--sitewide-banner-height)));
            top: calc(110px + var(--sitewide-banner-height));
        }

        nav#topMenu ul li.root div.SubMenuWrapper {
            max-height: calc(100vh - (150px + var(--sitewide-banner-height)));
        }

        .menu-search-hidden nav#topMenu ul li.root div.SubMenuWrapper {
            max-height: calc(100vh - (130px + var(--sitewide-banner-height)));
        }

        &.flanProdList #FiltersHeader {
            top: calc(130px + var(--sitewide-banner-height)) !important;
        }

        &.flanProdList #innerfiltercontainer {
            top: calc(180px + var(--sitewide-banner-height)) !important;
            max-height: calc(100vh - calc(200px + var(--sitewide-banner-height)));
        }

        &.flanProdList .menu-search-hidden #FiltersHeader {
            top: 130px !important;
        }

        &.flanProdList .menu-search-hidden #innerfiltercontainer {
            top: 180px !important;
            max-height: calc(100vh - 200px);
        }

        &.Basket .elevated-cart .basket-header,
        &.Basket .elevated-cart .basket-summary {
            top: calc(146px + var(--sitewide-banner-height)) !important;
        }

        .menu-search-hidden .elevated-cart .basket-header,
        &.Basket .menu-search-hidden .elevated-cart .basket-summary {
            top: 146px !important;
        }
    }

    @media (max-width: 1021px) {
        &.searchFocus .ui-autocomplete.ui-front.ui-menu {
            transform: translateY(var(--sitewide-banner-height));
        }

        &.body-menu-open .menu-search-hidden .HeaderWrap {
            transform: none;
        }

        .menu-search-hidden .HeaderWrap {
            transform: translate3d(0, calc(-1 * (110px + var(--sitewide-banner-height))), 0);
        }

        .PullMenuActive #mp-pusher #mp-menu {
            transform: none;
            top: calc(110px + var(--sitewide-banner-height));
            height: calc(100% - (110px + var(--sitewide-banner-height)));
        }

        &.flanProdList .mobile-sort-and-filter-wrapper {
            top: calc(110px + var(--sitewide-banner-height));
        }

        &.FlanProdDet .menu-search-shown .sticky-atb-wrapper.stickyCTA-active {
            top: calc(110px + var(--sitewide-banner-height));
        }
    }
}
