/* I believe we can remove these */

/* This won't be applied to anything */
:focus {
    outline: 1px dotted #999;
}

/* I can't find anything in the FGP solution using this class */
.content {
    border: 12px solid #fff;
}

/* Style News uses it's own stylesheet. */
/* No results when regex searching the FGP solution for "class=".*styleNews" */

.styleNews .postWrap {
    display: block;
}

.styleNews h3 {
    font-size: 1.3em;
    text-transform: uppercase;
    font-weight: var(--fw-normal);
    line-height: 1.3em;
    padding: 0;
    letter-spacing: 1px;
}

.styleNews .postLink {
    position: relative;
    display: inline-block;
    letter-spacing: 1px;
    font-size: 1.1em;
    margin-top: 10px;
}
