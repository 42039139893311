.ToplinksGroup .search {
    padding: 0;
    height: 100%;
    width: 100%;
}

.ToplinksGroup .search #txtSearch {
    width: calc(100% - 40px);
    padding: 5px 10px;
    letter-spacing: 1px;
    background: transparent;
    color: #fff;
    border: 0;
    float: right;
    height: 40px;
}

.ToplinksGroup .search #txtSearch:focus {
    color: #000;
    border: none;
    padding-right: 49px;
}

.ToplinksGroup .search input#txtSearch::placeholder {
    color: #fff !important;
    opacity: 1 !important;
}

.ToplinksGroup .search #txtSearch:focus ~ a::before,
.ToplinksGroup .search .TextBoxClear:hover ~ a::before {
    opacity: 1;
    right: 0;
    left: auto;
}

.ToplinksGroup .search #txtSearch:focus:placeholder {
    opacity: 0 !important;
}

#MobtxtSearch::placeholder {
    color: #ddd !important;
    opacity: 1 !important;
}

#MobtxtSearch:focus::placeholder {
    opacity: 0 !important;
}

.ToplinksGroup .search .dvSearch {
    box-shadow: 0 2px 0 -1px #434343;
}

.searchFocus .ToplinksGroup .search .dvSearch {
    z-index: 100;
}

.ToplinksGroup .search .dvSearch:focus-within {
    box-shadow: 0 0 10px var(--flan-neon);
    border-radius: 15px 0 0 15px;
}

.ToplinksGroup .search .dvSearch #cmdSearch {
    height: 100%;
    width: 40px;
    cursor: pointer;
    line-height: 40px;
    text-align: center;
}

.ToplinksGroup .search .dvSearch #cmdSearch svg {
    display: inline-block;
    vertical-align: middle;
}

.ToplinksGroup .search .dvSearch #cmdSearch:hover svg {
    stroke-width: 2px;
}

.ToplinksGroup .search .dvSearch #cmdSearch:hover svg path {
    stroke-width: 3px;
}

.dvSearch .TextBoxClear {
    right: 10px;
}

.dvSearch .TextBoxClear::before {
    content: "";
    display: block;
    width: 20px;
    font-size: 11px;
    height: 20px;
    background-size: 600px 600px;
    background-position: -119px -87px;
}

.search-suggestions-dropdown__section--product__price,
.search-suggestions-dropdown__section--product__price:hover,
.search-suggestions-dropdown__section--product__discount-ticket-price,
.search-suggestions-dropdown__section--product__discount-selling-price {
    font-weight: var(--fw-medium);
}

.search-suggestions-dropdown__section--product__discount-selling-price,
.search-suggestions-dropdown__section--product__discount-selling-price:hover {
    color: #b30000;
}

@media (min-width: 1022px) {
    .search-suggestions-dropdown__close {
        height: 50px;
        width: 50px;
    }
}

@media (min-width: 1022px) {
    #searchSuggestionsDropdown[data-visible="yes"] {
        margin-top: -50px;
    }
}

@media (max-width: 1021px) {
    #searchSuggestionsDropdown[data-visible="yes"] {
        top: calc(var(--top) + -15px);
        width: calc(100vw - 70px);
        left: 55px;
        max-height: calc(100vh - var(--top) - -15px);
    }

    .search-dropdown__wrapper {
        padding: 10px;
    }
}
