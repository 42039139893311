.rating-container [data-bv-show="rating_summary"] .bv_main_container {
    margin: 0 !important;
    display: flex !important;
    justify-content: flex-end;
}

.rating-container [data-bv-show="rating_summary"] .bv_main_container .bv_avgRating_component_container,
.reviews-container [data-bv-show="inline_rating"] .bv_main_container .bv_averageRating_component_container,
[data-bv-show="inline_rating"] a[aria-label="0.0 out of 5 stars.  "].bv_main_container,
[data-bv-show="inline_rating"] a[aria-label="0.0 out of 5 stars. "].bv_main_container {
    display: none !important;
}

.rating-container [data-bv-show="rating_summary"] .bv_main_container .bv_numReviews_component_container {
    padding-top: 1px !important;
}

.rating-container [data-bv-show="rating_summary"] .bv_main_container .bv_main_container_row_flex {
    padding-right: 8px !important;
}

.rating-container [data-bv-show="rating_summary"] .bv_main_container .bv_stars_component_container {
    padding-right: 5px !important;
}

.reviews-container [data-bv-show="inline_rating"] > div {
    display: block !important;
}

.reviews-container [data-bv-show="reviews"],
.reviews-container [data-bv-show="questions"] {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    padding: 0 0 40px;
    max-width: var(--max-container-width);
}

.bv_modal_component_container .bv_histogram_row_container {
    height: auto !important;
    padding: 5px !important;
    display: block !important;
}

.bv_modal_component_container .bv_button_component_container {
    padding-top: 15px !important;
}

.bv_modal_component_container .bv_histogram_row_star {
    padding-left: 3px !important;
}

.bv_modal_component_container .bv_button_component_container button.bv_button_buttonFull {
    width: 100% !important;
    padding: 15px !important;
    transition: background-color 0.3s ease-in;
}

#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-mbox-wide .bv-mbox {
    background-color: #f0f0f0 !important;
}

#bv-mboxzone-lightbox.bv-cv2-cleanslate
    .bv-mbox-fullprofile
    .bv-sidebar
    .bv-author-profile-userinfo
    .bv-author-userstats-list
    .bv-author-userstats-value {
    background-color: rgba(25, 25, 25, 0.1) !important;
}

.reviews-container
    .bv-cv2-cleanslate
    .bv-content-pagination
    .bv-content-pagination-container
    .bv-content-btn-pages-load-more {
    text-transform: capitalize !important;
    background-color: #000 !important;
    color: #fff !important;
    padding: 10px !important;
    border: 0 !important;
    width: auto !important;
    min-width: 200px !important;
    font-weight: var(--fw-medium) !important;
    letter-spacing: 0.1em !important;
}

.reviews-container
    .bv-cv2-cleanslate
    .bv-content-pagination
    .bv-content-pagination-container
    .bv-content-btn-pages-load-more:hover {
    background-color: #222 !important;
    color: #fff !important;
}

.reviews-container
    .bv-cv2-cleanslate
    .bv-content-pagination
    .bv-content-pagination-container
    .bv-content-btn-pages-load-more
    .bv-content-btn-pages-load-more-text {
    text-transform: capitalize !important;
    cursor: pointer !important;
    position: relative !important;
    letter-spacing: 0.1em !important;
}

.reviews-container .bv-cv2-cleanslate .bv-content-header .bv-content-title-container {
    padding-top: 10px !important;
}

.reviews-container .bv-cv2-cleanslate .bv-header .bv-section-summary .bv-content-title {
    padding-top: 25px !important;
}

.reviews-container .bv-cv2-cleanslate {
    margin: 20px 0;
}

@media (min-width: 768px) and (max-width: 1021px) {
    .reviews-container [data-bv-show="reviews"],
    .reviews-container [data-bv-show="questions"] {
        padding-left: 24px;
        padding-right: 24px;
    }
}

@media (max-width: 767px) {
    .rating-container [data-bv-show="rating_summary"] {
        text-align: left !important;
    }

    .reviews-container .bv-cv2-cleanslate {
        margin: 20px 10px;
    }
}

.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-placeholder .bv-rating-stars-container.bv-rating-none {
    margin: 10px 5px 10px 10px !important;
}

.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-placeholder {
    padding-bottom: 30px !important;
}

.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-noreviews-label,
.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-placeholder .bv-write-review-label {
    vertical-align: middle !important;
    line-height: 20px !important;
    margin: 10px 5px 10px 10px !important;
    display: inline-block !important;
}

.reviews-container [data-bv-rating] .bv_main_container .bv_text,
.reviews-container [data-bv-show="inline_rating"] .bv_main_container .bv_text {
    line-height: 1.2em !important;
    font-size: 1.2em !important;
    padding: 0 !important;
}

.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-slider-sublabel1,
.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-slider-sublabel2 {
    display: inline-block !important;
    float: none !important;
}

.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-placeholder .bv-write-review-label {
    display: none !important;
}

.reviews-panel + a {
    display: flex;
}

.reviews-panel h4 {
    display: flex;
    align-items: center;
}
