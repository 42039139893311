:root {
    --a11y: #f00;
    --grey: hsla(0, 0%, 44%, 1);
    --grey-light: hsla(0, 0%, 96%, 1);
    --grey-mid: hsla(0, 0%, 82%, 1);
    --grey-dark: hsla(0, 0%, 35%, 1);
    --grey-black: hsla(0, 0%, 8%, 1);
    --green: hsla(67deg, 100%, 50%, 1);
}

#Body #main-content .DnnModule,
#Body section.mainBody .DnnModule,
#Body #main-content .ecomContent {
    margin: 0;
    padding: 0;
    font-family: var(--brand-font) !important;
}

.Home {
    .col-xs-12 {
        min-height: 0;
    }

    .swiper-button-next::after,
    .swiper-button-prev::after {
        display: none;
    }

    .Home .ContentWrapper,
    .ContentWrapper {
        max-width: 100%;
        padding: 0;
    }

    .u-vh {
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
    }

    .u-vh--is-focusable:active,
    .u-vh--is-focusable:focus {
        position: static;
        overflow: visible;
        clip: auto;
        width: auto;
        height: auto;
        margin: 0;
    }

    .no-focus a,
    .no-focus button,
    .no-focus input,
    .no-focus select,
    .no-focus textarea,
    .no-focus label {
        outline: 0;
    }

    .keyboard-use a:focus,
    .keyboard-use button:focus,
    .keyboard-use input:focus,
    .keyboard-use select:focus,
    .keyboard-use textarea:focus,
    .keyboard-use label:focus {
        outline: 2px solid var(--a11y);
    }

    .u-contain {
        margin-left: auto;
        margin-right: auto;
        box-sizing: border-box;
    }

    .u-contain::after {
        content: "";
        display: block;
        clear: both;
    }

    .u-button {
        display: block;
        height: 44px;
        padding: 0 16px;
        border: 2px solid #000;
        font-size: 14px;
        line-height: 40px;
        font-weight: var(--fw-medium);
        text-transform: uppercase;
        text-decoration: none;
        color: #fff;
        background-color: #000;
        cursor: pointer;
        box-sizing: border-box;
        text-align: center;
        font-family: var(--body);
        transition:
            0.25s background-color ease,
            0.25s border-color ease,
            0.25s color ease;
    }

    .u-button:hover,
    .u-button:focus {
        background-color: var(--flan-white);
        color: var(--flan-black);
        border-color: #000;
    }

    .u-button--alt {
        background-color: #fff;
        color: #000;
    }

    .u-button--outline {
        background-color: #000;
        border-color: #fff;
    }

    .u-button--outline:hover,
    .u-button--outline:focus {
        background-color: #fff;
        border-color: #fff;
        color: #000;
    }

    .u-button--action {
        background-color: #fff;
        color: #000;
        border-color: #fff;
    }

    .u-button--action:hover,
    .u-button--action:focus {
        background-color: #000;
        color: var(--green);
        border-color: #000;
    }

    button.u-button--play {
        position: relative;
        padding-left: 48px;
        color: #fff;
    }

    .u-button--close,
    .u-button--pause {
        display: block;
        width: 44px;
        height: 44px;
        padding: 0;
        border: 0;
        overflow: hidden;
        text-indent: 115%;
        white-space: nowrap;
        background-color: transparent;
        background-image: url("/Images/Marketing/icons/icon-close-black.svg");
        background-repeat: no-repeat;
        background-size: 20px auto;
        background-position: 50% 50%;
        cursor: pointer;
    }

    .u-button--pause {
        background-image: url("/Images/Marketing/icons/icon-pause-white.svg");
    }

    .u-button--pause.is_paused {
        background-image: none;
    }

    .u-button--pause.is_paused::before {
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 20px solid #fff;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .u-button--play::before {
        left: 24px;
        margin-right: 12px;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 8px solid #fff;
        transform: translate(0, -50%);
        transition: 0.25s border-color ease;
    }

    .u-button--play::before,
    .u-button--pause.is_paused::before {
        content: "";
        position: absolute;
        top: 50%;
        display: block;
        width: 0;
        height: 0;
    }

    .u-button--play:hover::before,
    .u-button--play:focus::before {
        border-left-color: #000;
    }

    .u-button--close:hover,
    .u-button--close:focus,
    .u-button--pause:hover,
    .u-button--pause:focus {
        background-color: var(--grey-black);
    }

    .u-feature {
        box-sizing: border-box;
    }

    .u-feature__contain {
        display: block;
        position: relative;
        overflow: hidden;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 0;
    }

    .u-feature__contain::after {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 150%;
        z-index: 2;
        content: "";
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 33.33333%, rgba(0, 0, 0, 0.6) 100%);
        transition: 0.25s transform ease;
    }

    .u-feature__contain:hover::after,
    .u-feature__contain:focus::after {
        transform: translate(0, -33.33333%);
    }

    .u-feature__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 3;
        width: 100%;
        text-decoration: none;
        padding-left: 12px;
        padding-right: 12px;
        box-sizing: border-box;
    }

    .u-feature__label {
        font-size: 16px;
        line-height: 22px;
        font-weight: var(--fw-normal);
    }

    .u-feature__label,
    .u-feature__title {
        color: #fff;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
        text-transform: uppercase;
    }

    .u-feature__smallprint {
        font-size: 12px;
        line-height: 16px;
        color: #fff !important;
        margin-top: 12px;
    }

    .u-feature__title {
        position: relative;
        margin-top: 0;
        margin-bottom: 10px;
        padding-bottom: 12px;
        text-align: center;
        line-height: 24px;
        font-size: 21px;
        font-weight: var(--fw-semibold);
        font-family: var(--body) !important;
    }

    a.u-feature__content .u-feature__title {
        margin-bottom: 30px;
    }

    .u-feature__title::after {
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        height: 2px;
        width: 140px;
        background-color: hsla(67deg, 100%, 50%, 1);
        content: "";
        transform: translate(-50%, 0);
    }

    .u-feature__intro {
        text-align: center;
        color: #fff;
        font-size: 18px;
        line-height: 22px;
        margin-top: 12px;
        margin-bottom: 24px;
        max-width: 80%;
    }

    .u-feature__intro:hover {
        color: #fff;
    }

    .u-feature__link {
        color: #fff;
        text-transform: uppercase;
        font-size: 14px;
        display: block;
        line-height: 42px;
        padding-left: 16px;
        padding-right: 16px;
        transition:
            0.25s background-color ease,
            0.25s color ease;
        text-decoration: none;
        font-family: var(--body) !important;
        font-weight: var(--fw-medium);
    }

    a.u-feature__content .u-feature__link {
        background-color: #fff;
        color: #000;
    }

    a.u-feature__content:hover .u-feature__link,
    a.u-feature__content:focus .u-feature__link {
        background-color: #000;
        color: #fff;
    }

    a.u-feature__contain:hover .u-feature__link,
    a.u-feature__contain:focus .u-feature__link {
        background-color: #fff;
        color: #000;
    }

    .u-product {
        text-decoration: none;
        display: block;
    }

    .u-product__image {
        width: 100%;
        position: relative;
    }

    .u-product__image-src {
        width: 100%;
        height: auto;
        display: block;
    }

    .u-product__details {
        text-transform: uppercase;
        text-align: center;
    }

    .u-product__brand {
        font-weight: var(--fw-semibold);
        font-family: var(--body) !important;
    }

    .hero {
        width: 100%;
        background-color: var(--grey-dark);
    }

    .hero--action {
        position: relative;
        overflow: hidden;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
    }

    .hero--action::after {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        content: "";
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
    }

    .hero--action.hide-overlay::after {
        display: none;
    }

    .hero__image {
        vertical-align: bottom;
        height: 100%;
    }

    .hero--action img {
        width: 100%;
    }

    .hero__content {
        position: absolute;
        bottom: 54px;
        left: 50%;
        transform: translate(-50%, 0);
        display: flex;
        flex-flow: wrap column;
        align-items: center;
        z-index: 3;
    }

    .hero__label {
        font-size: 16px;
        line-height: 22px;
    }

    .hero__label,
    .hero__title {
        color: #fff;
        text-transform: uppercase;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
    }

    .hero__title {
        position: relative;
        margin-top: 0;
        margin-bottom: 20px;
        padding-bottom: 12px;
        text-align: center;
        font-size: 36px;
        line-height: 40px;
        font-family: var(--body) !important;
    }

    .hero__title::after {
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        height: 2px;
        width: 140px;
        background-color: var(--green);
        content: "";
        transform: translate(-50%, 0);
    }

    .hero__button-contain {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
    }

    .hero__link {
        display: inline-block;
        padding-left: 24px;
        padding-right: 24px;
        min-width: 160px;
    }

    .herologo img {
        margin: 15px auto;
        max-width: 200px;
        padding-bottom: 5px;
    }

    .product-showcase .u-contain {
        position: relative;
        flex: 0 0 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .product-showcase__video {
        max-width: 100%;
        height: 0;
        position: relative;
    }

    .product-showcase__video-player {
        position: absolute;
        height: 100%;
    }

    .product-showcase__overview {
        display: flex;
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: #fff;
        box-sizing: border-box;
    }

    .product-showcase__images {
        position: relative;
    }

    .product-showcase__image {
        width: 100%;
        display: none;
    }

    .product-showcase__image.is_active {
        display: block;
    }

    .product-showcase__toggle {
        position: relative;
        padding: 0 0 0 24px;
        font-size: 16px;
        line-height: 44px;
        border: 0;
        background-color: transparent;
        vertical-align: bottom;
        cursor: pointer;
        font-family: var(--body);
        transition: 0.25s color ease;
        color: var(--grey-dark);
    }

    .product-showcase__toggle.is_active {
        font-weight: var(--fw-medium);
        color: #000;
    }

    .product-showcase__toggle:hover,
    .product-showcase__toggle:focus {
        color: var(--grey-mid);
    }

    .product-showcase__toggle.is_active:hover,
    .product-showcase__toggle.is_active:focus {
        color: #000;
    }

    .product-showcase__toggle::before {
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        width: 16px;
        height: 4px;
        content: "";
    }

    .product-showcase__toggle--white::before {
        background-color: #f0f0ee;
    }

    .product-showcase__toggle--black::before {
        background-color: #000;
    }

    .product-showcase__toggle--blue::before {
        background-color: #819acc;
    }

    .product-showcase__toggle--pink::before {
        background-color: #ff0b9d;
    }

    .product-showcase__toggle:not(:last-of-type) {
        margin-right: 24px;
    }

    .product-showcase__title {
        margin-top: 0;
        margin-bottom: 24px;
        font-weight: var(--fw-medium);
        font-size: 24px;
    }

    .product-showcase__price {
        display: inline-block;
        font-weight: var(--fw-normal);
    }

    .product-showcase__blurb {
        margin-top: 0;
        margin-bottom: 24px;
        font-size: 16px;
    }

    .product-showcase__actions {
        display: flex;
        justify-content: space-between;
    }

    .product-showcase__cart-button {
        width: calc(100% - 56px);
    }

    .showcase--trending .u-contain,
    .showcase--top-cats,
    .showcase--triple .u-contain,
    .showcase--featured {
        display: flex;
    }

    .showcase--trending {
        background-color: #000;
    }

    .showcase--trending .u-contain {
        padding: 40px;
        padding-bottom: 80px;
        overflow: hidden;
    }

    .showcase--trending .u-contain::after,
    .showcase--featured.u-contain::after,
    .showcase--triple .u-contain::after {
        display: none;
    }

    .showcase-swiper {
        position: relative;
        overflow: visible;
        width: 100%;
    }

    .showcase-swiper-wrapper {
        display: flex;
    }

    .showcase-swiper__button {
        background-image: url("/images/marketing/icons/icon-arrow-down-white.svg");
        background-repeat: no-repeat;
        background-position: 50% 50%;
        position: absolute;
        top: calc(50% - 40px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        right: -40px;
        z-index: 1;
        height: 40px;
        width: 40px;
        cursor: pointer;
        box-shadow:
            0 0 8px rgba(0, 0, 0, 0.05),
            0 5px 10px rgba(0, 0, 0, 0.1);
    }

    .showcase-swiper__button.swiper__button--prev {
        left: -40px;
        transform: rotate(90deg);
    }

    .showcase-swiper__button.swiper__button--next {
        transform: rotate(270deg);
    }

    .showcase-swiper-pagination {
        color: #fff;
        margin-top: 26px;
        text-align: right;
        width: 100%;
    }

    .showcase--triple-christmas {
        background-repeat: repeat-x;
        background-size: auto 100%;
    }

    .showcase--featured .showcase-item {
        margin-bottom: 40px;
    }

    .showcase--featured {
        padding: 40px;
    }

    .showcase--triple-christmas + .showcase-item--large-christmas,
    .showcase-item--large + .showcase--top-cats {
        padding-top: 0;
    }

    .showcase__title {
        margin-top: 0;
        text-transform: uppercase;
        color: #fff;
        text-align: center;
        font-weight: var(--fw-normal);
        line-height: 1;
    }

    .showcase-item--large {
        background-color: #fff;
    }

    .showcase-item--large-christmas .showcase-item__contain {
        border-image: url("/Images/Marketing/patterns/pattern-glitter-tile.jpg") 18;
        border-image-repeat: repeat;
        border-width: 8px;
        border-style: solid;
    }

    .showcase--top-cats .showcase-item__contain::after,
    .showcase--top-cats .showcase-item__contain:hover::after,
    .showcase--top-cats .showcase-item__contain:focus::after {
        background: hsla(0deg, 0%, 0%, 0.2);
    }

    .showcase--triple-christmas .showcase-item__contain {
        border: 8px solid #fff;
    }

    .showcase-item__content {
        position: absolute;
        left: 50%;
        bottom: 20px;
        transform: translate(-50%, 0);
    }

    .newest-arrivals {
        background-color: #000;
    }

    .newest-arrivals .u-contain {
        display: flex;
        position: relative;
    }

    .newest-arrivals__product {
        position: relative;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        transition: 0.25s transform ease;
        color: #fff;
    }

    .newest-arrivals__product:hover,
    .newest-arrivals__product:focus {
        text-decoration: underline;
    }

    .newest-arrivals__product::before,
    .newest-arrivals__image::before {
        display: block;
        content: "";
        position: absolute;
    }

    .newest-arrivals__product:not(.newest-arrivals__product--alt)::before,
    .newest-arrivals__product:not(.newest-arrivals__product--alt) .newest-arrivals__image::before {
        background-color: #373737;
    }

    .newest-arrivals__product--alt::before,
    .newest-arrivals__product--alt .newest-arrivals__image::before {
        background-color: #c2c2c4;
    }

    .newest-arrivals__details {
        color: #fff;
    }

    .newest-arrivals__link {
        padding-left: 16px;
        padding-right: 16px;
    }

    .newest-arrivals__title {
        margin-bottom: 30px;
    }

    .topMenuSlider .outerContain {
        max-width: 1440px;
        margin: 0 auto;
        position: relative;
    }

    .topMenuSlider .topBrandSwiper {
        margin-top: 30px;
        padding: 0;
    }

    .topMenuSlider .swiper-container-topBrand {
        max-width: 1210px;
        padding: 0 40px;
    }

    .topMenuSlider .hp_brands_Title {
        font-size: 1.7em;
        text-transform: uppercase;
        margin: 30px auto;
        letter-spacing: 0.03em;
    }

    .topMenuSlider .swiper-container.swiper-container-topBrand .swiper-slide {
        width: auto;
        margin-right: 20px;
    }

    .topMenuSlider .swiper-container.swiper-container-topBrand a {
        display: block;
        height: 44px;
        padding: 0 24px;
        border: 1px solid #000;
        font-size: 14px;
        line-height: 42px;
        font-weight: var(--fw-medium);
        text-transform: uppercase;
        text-decoration: none;
        color: #000;
        background-color: #fff;
        cursor: pointer;
        box-sizing: border-box;
        text-align: center;
        font-family: var(--body);
        transition:
            0.25s background-color ease,
            0.25s border-color ease,
            0.25s color ease;
    }

    .topMenuSlider .swiper-container.swiper-container-topBrand a:hover {
        color: var(--green);
        background-color: #000;
    }

    .topMenuSlider .swiper-button-prevBrand,
    .topMenuSlider .swiper-button-nextBrand {
        background-image: url("/images/marketing/icons/icon-arrow-down-black.svg");
        background-repeat: no-repeat;
        background-position: 50% 50%;
        position: absolute;
        top: calc(50% - 20px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        right: 0;
        z-index: 1;
        height: 40px;
        width: 40px;
        background-color: #fff;
        cursor: pointer;
        box-shadow:
            0 0 8px rgb(0 0 0 / 0.05),
            0 5px 10px rgb(0 0 0 / 0.1);
    }

    .topMenuSlider .swiper-button-prevBrand {
        left: 35px;
        transform: translate(0, 0) rotate(90deg);
    }

    .topMenuSlider .swiper-button-nextBrand {
        right: 35px;
        left: auto;
        transform: translate(0, 0) rotate(270deg);
    }

    .topMenuSlider .swiper-button-nextBrand:hover,
    .topMenuSlider .swiper-button-nextBrand:focus,
    .topMenuSlider .swiper-button-prevBrand:hover,
    .topMenuSlider .swiper-button-prevBrand:focus {
        background-color: #000;
        background-image: url("/images/marketing/icons/icon-arrow-down-flannels-primary.svg");
    }

    .topMenuSlider .swiper-scrollbarFeat {
        opacity: 1 !important;
        position: relative;
        left: auto;
        bottom: auto;
        width: 30%;
        margin: 30px auto 10px;
        padding: 1px;
        transition: all 0.3s ease-out;
    }

    .topMenuSlider .swiper-scrollbar.swiper-scrollbartopBrand {
        opacity: 1 !important;
        position: relative;
        left: auto;
        bottom: auto;
        width: 30%;
        margin: 30px auto 10px;
        padding: 1px;
        transition: all 0.3s ease-out;
        background-color: #ddd;
    }

    .topMenuSlider .swiper-scrollbar.swiper-scrollbartopBrand:focus .swiper-scrollbar-drag,
    .topMenuSlider .swiper-scrollbar.swiper-scrollbartopBrand:hover .swiper-scrollbar-drag,
    .topMenuSlider .swiper-scrollbar.swiper-scrollbartopBrand .swiper-scrollbar-drag:hover .swiper-scrollbar-drag,
    .topMenuSlider .swiper-scrollbar.swiper-scrollbartopBrand .swiper-scrollbar-drag:active {
        height: 10px;
        margin: -3px 0;
        transition: all 0.3s ease-out;
    }

    .topMenuSlider .swiper-scrollbartopBrand .swiper-scrollbar-drag {
        background-color: #808080;
    }

    .topMenuSlider .swiper-paginationBrand.swiper-pagination-fraction {
        position: relative;
        width: auto;
        right: 0;
        left: auto;
        text-align: right;
        padding: 25px 15px 0;
        font-size: 1.2em;
    }

    .style-news__label,
    .style-news__title {
        color: #000;
        text-shadow: none;
        text-rendering: geometricprecision;
    }

    .style-news__title {
        margin-bottom: 0;
    }

    .style-news__articles {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .style-news__article {
        position: relative;
        display: block;
        text-decoration: none;
        color: #fff;
        background-color: #000;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 0;
    }

    .style-news__article:hover::after,
    .style-news__article:focus::after {
        transform: translate(0, -33.33333%);
    }

    .style-news__article-title {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 3;
        font-family: var(--body) !important;
        font-weight: var(--fw-medium);
        margin-top: 0;
        margin-bottom: 0;
        width: 100%;
        background-color: #000;
        transition: 0.25s transform ease;
        box-sizing: border-box;
        color: #fff;
        text-rendering: geometricprecision;
    }

    .style-news__article:hover .style-news__article-title,
    .style-news__article:focus .style-news__article-title {
        transform: translate(0, 0);
    }

    .style-news__article:hover .style-news__article-link,
    .style-news__article:focus .style-news__article-link {
        opacity: 1;
    }

    .style-news__article-label {
        font-weight: var(--fw-normal);
    }

    .style-news__article-label,
    .style-news__article-link {
        text-transform: uppercase;
    }

    .style-news__article-link {
        color: var(--green);
    }

    .style-news__more {
        display: flex;
        justify-content: center;
        margin: 30px 0 0;
    }

    .style-news__more-link {
        display: inline-block;
        width: fit-content;
    }

    .hero-video {
        background-color: #000;
        position: relative;
    }

    .hero-video.is_static {
        background-image: url("/Images/Marketing/xmas/xmas-hero-banner.jpg");
        background-position: 50% 100%;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .is_static .hero-video__poster .hero-video__poster-video,
    .is_static .hero-video__poster .hero-video__play,
    .is_static .hero-video__poster .hero-video__pause {
        display: none;
    }

    .hero-video .hero-video__poster-video {
        min-height: 110%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .hero-video__video {
        width: 100%;
        position: absolute;
        z-index: 3;
        transition:
            0.5s opacity ease,
            0.5s z-index ease;
        left: 50%;
    }

    .hero-video__poster.is_hidden,
    .hero-video__video.is_hidden {
        opacity: 0;
        pointer-events: none;
        z-index: -1;
    }

    .hero-video__poster.is_active,
    .hero-video__video.is_active {
        opacity: 1;
    }

    .hero-video__poster::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: block;
        height: 100%;
        content: "";
        z-index: 2;
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0.9) 33%,
            rgba(0, 0, 0, 0.8) 40%,
            rgba(0, 0, 0, 0) 60%
        );
    }

    .hero-video .hero-video__source {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .hero-video__content {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 54px;
        left: 50%;
        z-index: 4;
        transform: translate(-50%, 0);
        text-align: center;
        width: 100%;
    }

    .hero-video__title {
        margin-top: 0;
        margin-bottom: 24px;
        padding-bottom: 0;
        line-height: 1;
        text-transform: uppercase;
        font-weight: var(--fw-semibold);
        font-family: var(--body) !important;
        color: transparent;
        background-clip: text;
        background-image: url("/Images/Marketing/patterns/pattern-glitter-tile.jpg");
        -webkit-text-stroke: 1px #fff;
        background-size: 150px auto;
    }

    .hero-video__intro {
        font-weight: var(--fw-normal);
        color: #fff;
        max-width: 866px;
        margin-top: 0;
    }

    .hero-video__play {
        background-color: transparent;
    }

    .hero-video__pause {
        position: absolute;
        z-index: 4;
    }

    .hero-video__poster.is_hidden .hero-video__pause {
        display: none;
    }

    .hero-video .u-contain {
        padding-left: 0;
        padding-right: 0;
        max-width: 1206px;
    }

    .hero-video__poster,
    .hero-video .hero-video__player {
        overflow: hidden;
    }

    .hero-video .hero-video__player {
        height: 0;
        padding-bottom: 56.25%;
    }

    .hero-video__stop {
        position: absolute;
        background-image: url("/Images/Marketing/icons/icon-close-white.svg");
    }

    .u-contain.banner {
        padding: 0;
        margin-bottom: 40px;
    }

    .banner__link {
        display: flex;
        position: relative;
        text-decoration: none;
        color: #fff;
        background-color: #fff;
        align-items: center;
        background-position:
            0 0,
            100% 0;
        background-repeat: no-repeat, no-repeat;
        box-sizing: border-box;
    }

    .banner__link::before {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        z-index: 1;
    }

    .banner--gift-card .banner__link::after {
        display: block;
        content: "";
        background-image: url("/Images/Marketing/banners/gift-card.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50% 50%;
        z-index: 3;
    }

    .banner__content {
        position: relative;
        z-index: 2;
    }

    .banner__text {
        color: #fff;
    }

    .banner--gift-card .banner__content {
        order: 3;
    }

    .banner__callout {
        display: inline-block;
    }

    .banner__link .banner__callout {
        border-color: #fff;
    }

    .banner__link:hover .banner__callout,
    .banner__link:focus .banner__callout {
        background-color: #000;
        border-color: #000;
        color: var(--green);
    }

    .gifts-by-gender {
        padding-bottom: 40px;
    }

    .gifts-by-price__title,
    .gift-giving__title {
        margin-top: 0;
        text-transform: uppercase;
        color: #000;
        text-align: center;
        font-weight: var(--fw-normal);
        margin-bottom: 40px;
        line-height: 1;
    }

    .gifts-by-gender__items {
        display: flex;
    }

    .gifts-by-gender__link {
        display: block;
        position: relative;
        border-image: url("/Images/Marketing/patterns/pattern-glitter-tile.jpg") 18;
        border-image-repeat: repeat;
        border-width: 8px;
        border-style: solid;
        box-sizing: border-box;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 0;
    }

    .gifts-by-gender__button {
        position: absolute;
        display: inline-block;
        bottom: 0;
        left: 50%;
        width: 185px;
        transform: translate(-50%, 26px);
    }

    .gifts-by-gender__link:hover .gifts-by-gender__button,
    .gifts-by-gender__link:focus .gifts-by-gender__button {
        background-color: var(--green);
        border-color: var(--green);
        color: #000;
    }

    .swiper-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        transition-property: transform;
        box-sizing: content-box;
    }

    .swiper-pointer-events {
        touch-action: pan-y;
    }

    .swiper-pointer-events.swiper-vertical {
        touch-action: pan-x;
    }

    .swiper-slide {
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        position: relative;
        transition-property: transform;
        box-sizing: border-box;
    }

    .swiper-slide-invisible-blank {
        visibility: hidden;
    }

    .swiper-autoheight,
    .swiper-autoheight .swiper-slide {
        height: auto;
    }

    .swiper-autoheight .swiper-wrapper {
        align-items: flex-start;
        transition-property: transform, height;
    }

    .swiper-button-next.swiper-button-lock,
    .swiper-button-prev.swiper-button-lock,
    .swiper-pagination.swiper-pagination-lock,
    .swiper-scrollbar.swiper-scrollbar-lock,
    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled,
    .swiper-button-next::after,
    .swiper-button-prev::after {
        display: none;
    }

    .swiper-pagination.swiper-pagination-hidden {
        opacity: 0;
    }

    .swiper-scrollbar {
        position: relative;
        width: 30%;
        margin: 0 auto;
        display: flex;
        background: rgba(221, 221, 221, 0.86667);
        height: 4px;
    }

    .swiper-scrollbar-drag {
        width: 100%;
        position: relative;
        height: 4px;
        background: #808080;
        transition:
            all 0.1s ease-in,
            transform 0s;
    }

    .swiper-scrollbar-cursor-drag {
        cursor: move;
    }

    .swiper-cards .swiper-slide {
        transform-origin: center bottom;
        backface-visibility: hidden;
        overflow: hidden;
    }

    .swiper {
        height: 100%;
        width: 100%;
        position: relative;
        margin: 0 auto;
    }

    .banner__brands-swiper.swiper {
        max-width: 1360px;
    }

    .swiper-pagination {
        position: relative;
        text-align: right;
        line-height: 20px;
    }

    .swiper-container-styleNews .swiper-button-prev,
    .swiper-container-styleNews .swiper-button-next {
        background-image: url("/Images/Marketing/icons/icon-arrow-down-black.svg");
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 14px auto;
        height: 40px;
        width: 40px;
        background-color: rgba(255, 255, 255, 1);
        position: absolute;
        top: 42%;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 1;
    }

    .swiper-container-styleNews .swiper-button-prev {
        transform: rotate(90deg) translate(0, -50%);
    }

    .swiper-container-styleNews .swiper-button-next {
        transform: rotate(270deg) translate(0, -50%);
    }

    .swiper-scrollbar-drag:hover,
    .swiper-scrollbar-drag:focus {
        background: #000;
        height: 6px;
        top: -1px;
    }

    .christmas-style-news {
        background-image: url("/Images/Marketing/patterns/pattern-glitter-tile-fade.jpg");
        background-repeat: repeat-x;
        background-size: auto 80%;
        background-position: 0 80px;
        padding: 40px;
        overflow: hidden;
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
    }

    .style-news__lead--christmas {
        margin-bottom: 40px;
    }

    .christmas-style-news__items {
        max-width: 1360px;
        margin-left: auto;
        margin-right: auto;
        overflow: visible;
    }

    .christmas-style-news__prev {
        left: -40px;
    }

    .christmas-style-news__next {
        right: -40px;
    }

    .gift-giving {
        background-color: var(--grey-light);
        padding: 40px;
        overflow: hidden;
    }

    .gift-giving__slides {
        max-width: 1360px;
        margin-left: auto;
        margin-right: auto;
    }

    .gift-giving__title {
        margin-top: 0;
        text-transform: uppercase;
        color: #000;
        text-align: center;
        font-weight: var(--fw-normal);
        margin-bottom: 40px;
    }

    .gift-giving__contain {
        border-image: url("/Images/Marketing/patterns/pattern-glitter-tile.jpg") 18;
        border-image-repeat: repeat;
        border-width: 8px;
        border-style: solid;
        box-sizing: border-box;
        height: 420px;
    }

    .gift-giving-contain--gift-finder::after {
        background-color: hsl(0deg, 0%, 0%, 0.4);
        transform: translate(0, 0);
        height: 100%;
    }

    .gift-giving-contain--gift-finder:hover::after,
    .gift-giving-contain--gift-finder:focus::after {
        transform: translate(0, 0);
    }

    .gift-giving__link {
        margin-bottom: 14px;
        z-index: 2;
    }

    .gift-giving__content {
        position: absolute;
        bottom: 0;
    }

    .gift-giving__content--gift-finder::after {
        display: block;
        content: "";
        width: 100%;
        height: 135px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-image: url("/Images/Marketing/patterns/pattern-glitter-tile-gift-finder.png");
        background-repeat: repeat-x;
        background-size: auto 100%;
        background-position: 0 100%;
        z-index: 1;
    }

    .gift-giving__content--gift-finder .u-feature__intro {
        margin-bottom: 90px;
    }

    a.u-feature__contain.gift-giving-contain--gift-finder:hover .gift-giving__link,
    a.u-feature__contain.gift-giving-contain--gift-finder:focus .gift-giving__link {
        background-color: var(--green);
        border-color: var(--green);
        color: #000;
    }

    .hero-banner-parent-link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 3;
    }

    .u-contain.showcase-item--large .showcase-item__content {
        bottom: 25px;
    }

    .u-contain.showcase-item--large .u-feature__smallprint {
        position: absolute;
        left: 50%;
        bottom: 50px;
        transform: translateX(-50%);
        z-index: 10;
    }

    .u-contain.showcase-item--large .u-feature__smallprint a {
        color: #fff;
        padding: 10px;
    }

    .u-contain.u-feature.showcase-item.showcase-item--large.livepoolStore {
        padding: 0 40px 80px;
    }

    .termsLink {
        color: #fff !important;
        text-align: center;
    }

    @media (max-width: 767px) {
        .u-contain {
            padding-left: 16px;
            padding-right: 16px;
        }

        .u-feature__image {
            width: auto !important;
            height: 120% !important;
            min-height: 100%;
        }

        .u-product__image {
            margin-bottom: 12px;
        }

        .u-product__details {
            font-size: 14px;
            line-height: 18px;
        }

        .hero__link {
            margin: 6px;
        }

        .hero__content {
            bottom: 40px;
            width: 80%;
        }

        .hero__label,
        .hero__title {
            text-align: center;
        }

        .product-showcase__video {
            overflow: hidden;
        }

        .product-showcase__video-player {
            top: 10%;
            left: 50%;
            min-width: 100%;
            transform: translate(-50%, -50%);
        }

        .product-showcase__overview {
            flex-direction: column;
            align-items: center;
            padding: 24px 0 48px;
        }

        .product-showcase__gallery {
            flex: 0 0 auto;
            width: calc(100% - 64px);
        }

        .product-showcase--loubirain .product-showcase__gallery {
            flex: 0 0 auto;
            width: calc(100% - 64px);
            max-width: 420px;
        }

        .product-showcase__images {
            margin-bottom: 32px;
        }

        .product-showcase__variants {
            display: none;
        }

        .product-showcase__info {
            padding: 0 24px;
        }

        .product-showcase__price {
            margin-left: 24px;
        }

        .showcase--trending .u-contain,
        .showcase--top-cats,
        .showcase--triple .u-contain,
        .showcase--featured {
            flex-direction: column;
            justify-content: center;
        }

        .showcase--top-cats .showcase-item {
            margin-bottom: 2px;
        }

        .showcase--trending .showcase-item,
        .showcase--top-cats .showcase-item,
        .showcase--triple .showcase-item,
        .showcase--featured .showcase-item {
            flex: 0 0 auto;
        }

        .showcase--top-cats,
        .showcase--triple .u-contain {
            padding: 40px;
            max-width: 480px;
        }

        .showcase--featured {
            padding: 40px;
        }

        .showcase--triple-christmas {
            background-image: url("/Images/Marketing/patterns/pattern-glitter-tile-fade-mobile.jpg");
        }

        .showcase--triple-christmas .u-contain {
            padding: 20px 40px 110px;
        }

        .showcase--triple-christmas .showcase-item__image {
            min-width: 100% !important;
            width: auto !important;
            height: 110% !important;
        }

        .showcase__title {
            margin-bottom: 20px;
            font-size: 18px;
        }

        .showcase-item--large {
            padding: 40px;
        }

        .showcase-item--large .showcase-item__image {
            min-width: 100%;
            width: auto;
            height: 100%;
        }

        .showcase--triple-christmas .showcase-item:not(:last-of-type) {
            margin-bottom: 40px;
        }

        .newest-arrivals .u-contain {
            padding: 40px 0;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .newest-arrivals__content {
            order: 1;
            margin-bottom: 40px;
        }

        .newest-arrivals__content .newest-arrivals__link {
            display: none;
        }

        .newest-arrivals__product {
            padding-left: 75px;
            padding-right: 75px;
            margin-bottom: 40px;
        }

        .newest-arrivals__product::before {
            display: none;
        }

        .newest-arrivals__image::before {
            width: 35px;
            height: 100%;
        }

        .newest-arrivals__product:not(.newest-arrivals__product--alt)::before {
            left: 0;
        }

        .newest-arrivals__product:not(.newest-arrivals__product--alt) .newest-arrivals__image::before {
            bottom: -40px;
            left: -75px;
        }

        .newest-arrivals__product--alt::before {
            right: 0;
        }

        .newest-arrivals__product--alt .newest-arrivals__image::before {
            top: -40px;
            right: -75px;
        }

        .newest-arrivals__product:not(.newest-arrivals__product--alt) {
            order: 2;
        }

        .newest-arrivals__product--alt {
            order: 3;
        }

        .newest-arrivals__link--alt {
            display: inline-block;
            order: 4;
        }

        .style-news {
            padding: 40px 0;
        }

        .style-news__lead,
        .style-news__lead--christmas {
            margin-bottom: 40px;
        }

        .style-news__article-title {
            font-size: 14px;
            line-height: 21px;
            padding: 10px;
        }

        .style-news__article-link {
            display: none;
        }

        .hero-video__content {
            width: calc(100% - 80px);
            text-align: left;
        }

        .hero-video__title {
            font-size: 48px;
        }

        .hero-video__intro {
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 64px;
        }

        .hero-video__pause {
            bottom: 0;
            left: 0;
        }

        .hero-video__video {
            top: 50%;
            transform: translate(-50%, -50%);
        }

        .hero-video__poster {
            height: 600px;
        }

        .hero-video__stop {
            top: 0;
            right: 0;
            z-index: 4;
        }

        .gifts-by-gender {
            padding: 40px 40px 0;
            max-width: 480px;
        }

        .gifts-by-price__title,
        .gift-giving__title {
            font-size: 18px;
        }

        .gifts-by-gender__items {
            flex-direction: column;
        }

        .gifts-by-gender__link {
            flex: 0 0 auto;
            height: 320px;
            margin-bottom: 40px;
        }

        .gifts-by-price__link {
            height: 140px;
        }

        .christmas-style-news {
            padding-left: 40px;
            padding-right: 40px;
        }

        .christmas-style-news__items .style-news__article {
            padding-top: 0;
            flex: 0 0 100%;
            height: 235px;
        }

        .christmas-style-news__items .style-news__article:not(:last-child) {
            margin-right: 40px;
        }

        .swiper-scrollbar {
            display: none;
        }

        .christmas-style-news__prev,
        .christmas-style-news__next {
            top: 50%;
        }

        .gift-giving__swiper-wrapper {
            margin-bottom: 18px;
        }

        .gift-giving__prev,
        .gift-giving__next {
            top: 50%;
            background-color: var(--grey-light);
        }

        .u-contain.showcase-item--large .showcase-item__content {
            bottom: 27px;
        }

        .hero__content.secondaryBanner {
            bottom: 2%;
        }
    }

    @media (max-width: 534px) {
        .product-showcase__video {
            padding-top: 200%;
        }

        .product-showcase__gallery {
            margin-top: -120px;
        }

        .product-showcase--loubirain .product-showcase__gallery {
            margin-top: -220px;
        }
    }

    @media (min-width: 535px) and (max-width: 767px) {
        .product-showcase__video {
            padding-top: 120%;
        }

        .product-showcase__gallery {
            margin-top: -160px;
        }

        .product-showcase--loubirain .product-showcase__gallery {
            margin-top: -260px;
        }

        .showcase--top-cats .u-feature__image,
        .showcase--triple .u-feature__image {
            height: auto;
            width: 100%;
        }
    }

    @media (min-width: 506px) and (max-width: 619px) {
        .showcase-item__image {
            width: 80% !important;
        }
    }

    @media (min-width: 620px) and (max-width: 767px) {
        .showcase-item__image {
            width: 70% !important;
        }
    }

    @media (min-width: 590px) and (max-width: 767px) {
        .showcase-swiper__button.swiper__button--next {
            right: 0;
        }

        .showcase-swiper__button.swiper__button--prev {
            left: 0;
        }
    }

    @media (min-width: 768px) and (max-width: 1023px) {
        .hero-video__content {
            padding-left: 40px;
            padding-right: 40px;
            width: 95%;
        }

        .product-showcase__overview {
            padding: 24px 24px 48px;
        }

        .product-showcase__gallery {
            flex: 0 0 50%;
            margin-top: -100px;
        }

        .product-showcase--loubirain .product-showcase__gallery {
            flex: 0 0 30%;
            margin-top: -100px;
        }

        .product-showcase__info {
            flex: 0 0 calc(50% - 32px);
        }

        .hero-video__intro {
            margin-bottom: 146px;
        }

        .hero-video__poster {
            height: 700px;
        }

        .showcase--featured .showcase-item__image.u-feature__image {
            height: 100%;
        }
    }

    @media (min-width: 768px) {
        .u-contain {
            max-width: 1440px;
            padding-left: 40px;
            padding-right: 40px;
        }

        .u-feature__image {
            height: 110% !important;
            width: auto !important;
        }

        .u-feature__title {
            font-size: 28px;
            line-height: 32px;
        }

        .u-product__image {
            margin-bottom: 20px;
        }

        .u-product__details {
            font-size: 16px;
            line-height: 21px;
        }

        .hero__link {
            margin: 0 6px 12px;
        }

        .hero__content {
            bottom: 54px;
        }

        .product-showcase__video {
            padding-top: 56.25%;
        }

        .product-showcase__video-player {
            top: 0;
            left: 0;
            width: 100%;
        }

        .product-showcase__overview {
            justify-content: space-between;
        }

        .product-showcase__images {
            margin-bottom: 72px;
        }

        .product-showcase__price {
            margin-left: 48px;
        }

        .showcase--trending .u-contain,
        .showcase--top-cats,
        .showcase--triple .u-contain,
        .showcase--featured {
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .showcase--trending .showcase-item {
            flex: 0 0 calc(33.33333% - 26px);
        }

        .showcase--top-cats .showcase-item {
            flex: 0 0 calc(33.33333% - 2px);
        }

        .showcase--triple .showcase-item {
            flex: 0 0 31.09453%;
        }

        .showcase--featured .showcase-item {
            flex: 0 0 calc(50% - 20px);
        }

        .showcase--top-cats,
        .showcase--triple .u-contain {
            padding-top: 40px;
            padding-bottom: 40px;
        }

        .showcase--triple-christmas {
            background-image: url("/Images/Marketing/patterns/pattern-glitter-tile-fade.jpg");
        }

        .showcase--triple-christmas .u-contain {
            padding: 60px 40px 180px;
        }

        .showcase__title {
            flex: 0 0 100%;
            margin-bottom: 40px;
            font-size: 21px;
            line-height: 34px;
        }

        .showcase-item--large {
            padding: 60px 40px 80px;
        }

        .showcase-swiper__button {
            display: none;
        }

        .showcase-swiper .swiper-wrapper {
            transform: none !important;
            justify-content: space-between;
        }

        .showcase-swiper-pagination {
            display: none;
        }

        .newest-arrivals .u-contain {
            padding: 0 80px;
            justify-content: space-between;
            align-items: center;
        }

        .newest-arrivals__content {
            flex: 0 0 26.534%;
            order: 2;
        }

        .newest-arrivals__product {
            flex: 0 0 33.83085%;
            padding-top: 100px;
            padding-bottom: 100px;
        }

        .newest-arrivals__product::before {
            left: 0;
            width: 100%;
            height: 60px;
        }

        .newest-arrivals__image::before {
            display: none;
        }

        .newest-arrivals__product:not(.newest-arrivals__product--alt)::before,
        .newest-arrivals__product:not(.newest-arrivals__product--alt) .newest-arrivals__image::before {
            top: 0;
        }

        .newest-arrivals__product--alt::before,
        .newest-arrivals__product--alt .newest-arrivals__image::before {
            bottom: 0;
        }

        .newest-arrivals__product:not(.newest-arrivals__product--alt) {
            order: 1;
            align-self: flex-start;
        }

        .newest-arrivals__product--alt {
            order: 3;
            align-self: flex-end;
        }

        .newest-arrivals__product:not(.newest-arrivals__product--alt) .newest-arrivals__details {
            order: 2;
            margin-top: 20px;
        }

        .newest-arrivals__product--alt .newest-arrivals__details {
            order: 1;
            margin-bottom: 20px;
        }

        .newest-arrivals__product:not(.newest-arrivals__product--alt) .newest-arrivals__image {
            order: 1;
        }

        .newest-arrivals__product--alt .newest-arrivals__image {
            order: 2;
        }

        .newest-arrivals__link.newest-arrivals__link--alt {
            display: none;
        }

        .style-news {
            padding-top: 80px;
            padding-bottom: 80px;
        }

        .style-news__lead {
            margin-bottom: 80px;
        }

        .style-news__lead--christmas {
            margin-bottom: 40px;
        }

        .style-news__article::after {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 150%;
            z-index: 2;
            content: "";
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 33.33333%, rgba(0, 0, 0, 0.6) 100%);
            transition: 0.25s transform ease;
        }

        .style-news__article-title {
            font-size: 16px;
            line-height: 24px;
            padding: 16px;
            transform: translate(0, 24px);
        }

        .style-news__article-link {
            opacity: 0;
            transition: 0.25s opacity ease;
            margin-top: 0;
        }

        .hero-video__title {
            font-size: 72px;
            max-width: 90%;
        }

        .hero-video__intro {
            font-size: 18px;
            line-height: 32px;
        }

        .hero-video__pause {
            bottom: 24px;
            left: 24px;
        }

        .hero-video__video {
            top: 50px;
            transform: translate(-50%, 0);
        }

        .hero-video__stop {
            top: 24px;
            right: 24px;
        }

        .banner__link {
            margin-left: 40px;
            margin-right: 40px;
            height: 196px;
            background-size:
                auto 100%,
                auto 100%;
        }

        .banner__link::before {
            background: linear-gradient(
                90deg,
                rgba(0, 0, 0, 0) 15%,
                rgba(0, 0, 0, 1) 30%,
                rgba(0, 0, 0, 1) 75%,
                rgba(0, 0, 0, 0) 90%
            );
        }

        .banner--gift-card .banner__link {
            background-image: url("/Images/Marketing/banners/banner-gift-card-stuff.jpg"),
                url("/Images/Marketing/banners/banner-gift-card-man.jpg");
        }

        .banner--inspired .banner__link {
            background-image: url("/Images/Marketing/banners/banner-inspired-pot.jpg"),
                url("/Images/Marketing/banners/banner-inspired-heels.jpg");
        }

        .gifts-by-gender {
            padding-top: 80px;
        }

        .gifts-by-price__title,
        .gift-giving__title {
            flex: 0 0 100%;
            font-size: 21px;
        }

        .gifts-by-gender__items {
            justify-content: space-between;
        }

        .gifts-by-gender__link {
            flex: 0 0 31.09453%;
            height: 350px;
        }

        .gifts-by-price__link {
            height: 250px;
        }

        .christmas-style-news__items .style-news__article {
            padding-top: 0;
            height: 338px;
            flex: 0 0 calc(50% - 20px);
        }

        .christmas-style-news__items .style-news__article:not(:last-child) {
            margin-right: 20px;
        }

        .swiper-pagination {
            display: none;
        }

        .christmas-style-news__prev,
        .christmas-style-news__next {
            top: 44%;
        }

        .gift-giving__title {
            flex: 0 0 100%;
            font-size: 21px;
        }

        .gift-giving__swiper-wrapper {
            margin-bottom: 50px;
        }

        .gift-giving__prev,
        .gift-giving__next {
            top: 46%;
        }

        .u-contain.showcase-item--large .u-feature__smallprint {
            bottom: 100px;
        }

        .secondaryImage .hero__content {
            width: 100%;
            flex-flow: row wrap;
            align-items: center;
            justify-content: center;
            bottom: 7%;
        }
    }

    @media (max-width: 1023px) {
        .banner__link {
            flex-direction: column;
            justify-content: flex-end;
            padding-bottom: 40px;
            background-size: cover;
        }

        .banner__link::before {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 1) 100%);
        }

        .banner--gift-card .banner__link::before {
            order: 1;
        }

        .banner--gift-card .banner__link::after {
            width: 210px;
            height: 136px;
            order: 2;
            margin-bottom: 16px;
        }

        .banner--gift-card .banner__link {
            height: 340px;
            background-image: url("/Images/Marketing/banners/banner-gift-card-stuff.jpg");
        }

        .banner--inspired .banner__link {
            height: 238px;
            background-image: url("/Images/Marketing/banners/banner-inspired-pot.jpg");
        }

        .banner__content {
            text-align: center;
            max-width: 66%;
        }

        .banner__text {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 16px;
        }
    }

    @media (min-width: 1022px) {
        .u-contain.u-feature.showcase-item.showcase-item--large.livepoolStore
            .u-feature__content.showcase-item__content {
            bottom: auto;
            top: calc(50% + 120px);
            transform: translate(-50%, -50%);
        }
    }

    @media (min-width: 1024px) {
        .product-showcase__overview {
            padding: 48px;
        }

        .product-showcase__gallery {
            flex: 0 1 57.81487%;
            margin-top: -240px;
        }

        .product-showcase--loubirain .product-showcase__gallery {
            flex: 0 1 30%;
            margin-top: -260px;
        }

        .product-showcase__info {
            flex: 0 0 31.25948%;
        }

        .showcase--featured .showcase-item__image.u-feature__image {
            width: 110% !important;
            height: auto !important;
        }

        .hero-video__poster,
        .hero-video__poster-video,
        .hero-video__player {
            position: relative;
            width: 100%;
        }

        .hero-video__intro {
            margin-bottom: 196px;
        }

        .hero-video__poster {
            height: 800px;
        }

        .banner__link {
            padding: 0 170px 0 388px;
        }

        .banner--gift-card .banner__link::after {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            width: 210px;
            height: 136px;
            left: 90px;
        }

        .banner__text {
            font-size: 20px;
            line-height: 40px;
            margin-bottom: 16px;
        }

        .swiper-button-prev {
            left: 8px;
        }

        .swiper-button-next {
            right: 8px;
        }

        .christmas-style-news__items .style-news__article {
            flex: 0 0 29.41176%;
        }
    }

    @media (min-width: 1366px) {
        .u-feature__image {
            width: 120% !important;
            height: auto !important;
        }
    }
}
