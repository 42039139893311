.cms-sitewide-banner-enabled {
    &.cms-sitewide-banner-above {
        --cart-sticky-element-default-top-value: calc(var(--sitewide-banner-height) + var(--headertop-height) + 46px);

        .sitewide-banner {
            position: absolute;
            top: 0;
        }

        #HeaderGroup.HeaderWrap {
            top: 0;
            padding-top: var(--sitewide-banner-height);
        }

        .sticky-header-applied .mobile-sort-and-filter-wrapper {
            top: calc(var(--sitewide-banner-height) + var(--headertop-height) + 10px);
        }

        .menu-search-hidden {
            .HeaderWrap {
                transform: translate3d(0, calc(-110px - var(--sitewide-banner-height)), 0);
            }

            .mobile-sort-and-filter-wrapper {
                top: 0;
            }
        }

        #divWishList .wishlist-summary {
            top: calc(var(--headertop-height) + 10px);
        }

        @media (min-width: 768px) {
            .wishlist-page__header {
                top: calc(var(--sitewide-banner-height) + var(--headertop-height) + 46px);
            }
        }

        @media (min-width: 1022px) {
            .menu-search-hidden {
                .HeaderWrap {
                    transform: translateY(calc(-1 * var(--sitewide-banner-height)));
                }

                .basket-header {
                    top: 145px;
                }

                .basket-summary {
                    top: 145px;
                }
            }
        }
    }

    .wishlist-page {
        margin-top: 20px;
    }

    .wishlist-page__header {
        top: 110px;
    }

    @media (min-width: 1021px) {
        .wishlist-page__header {
            top: 150px;
        }
    }
}
