.max-1600 {
    max-width: 1600px;
}

.max-1440,
.ContentWrapper.max-1210 {
    max-width: 1440px;
}

hr {
    border-top: 1px solid #ccc;
}
