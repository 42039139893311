.OrderComplete.Checkout22 a.button,
.OrderComplete.Checkout22 a.button:visited {
    background-color: var(--site-button-primary-background) !important;
    color: var(--site-button-primary-color);
    font-weight: var(--fw-normal);
    padding: calc(var(--spacing-unit) * 4);
    letter-spacing: 0.7px;

    &:hover,
    &:focus {
        background-color: var(--flan-light-black) !important;
    }
}

.OrderComplete {
    font-family: var(--brand-font);

    .orderInformationWrapper {
        .order-complete-messages {
            font-family: var(--brand-font);

            h1 {
                font-family: var(--brand-font);
                font-style: normal;
                color: var(--site-button-primary-background);
                font-weight: var(--fw-normal);
            }

            .pii-item {
                font-size: var(--body-font-size);
            }

            a.button {
                font-family: var(--brand-font);
                background-color: var(--site-button-primary-background);
                color: var(--site-button-primary-color);
                font-weight: var(--fw-normal);
                font-style: normal;
                border: 1px solid var(--site-button-primary-background);
                display: block;
                line-height: 1em;
                letter-spacing: 0.7px;
                max-width: 320px;
                border-radius: var(--site-button-border-radius);
                padding: calc(var(--spacing-unit) * 4);

                &:hover,
                &:focus {
                    background-color: var(--flan-light-black);
                }
            }
        }

        .order-details-wrapper {
            .order-details-section {
                h2 {
                    font-family: var(--brand-font);
                    font-weight: var(--fw-semibold);
                }

                .order-details-title {
                    font-size: var(--subtitle-font-size);
                    font-weight: var(--fw-semibold);
                }

                p {
                    font-size: var(--body-font-size);
                    font-weight: var(--fw-normal);
                }

                .order-details-delivery-date span {
                    color: var(--site-primary);
                    font-style: normal;
                }
            }
        }
    }

    .order-summary {
        h2 {
            font-family: var(--brand-font);
        }

        .order-summary-table p {
            font-size: var(--subtitle-font-size);
        }
    }

    .RegisterWrapper {
        padding-top: 0;

        .RegisterWrapper-inner {
            font-family: var(--brand-font);

            .OrderCompleteRegistration {
                .OrderCompleteRegistrationForm {
                    h2 {
                        font-family: var(--brand-font);
                    }

                    input[type="submit"] {
                        background-color: var(--site-button-primary-background);
                        color: var(--site-button-primary-color);
                        font-weight: var(--fw-normal);
                        font-style: normal;
                        border: 0;
                        text-transform: none;
                        display: block;
                        line-height: 1em;
                        letter-spacing: 0.7px;
                        max-width: 320px;
                        border-radius: var(--site-button-border-radius);
                        padding: calc(var(--spacing-unit) * 4);

                        @media (min-width: 768px) {
                            max-width: 100%;
                        }

                        &:hover,
                        &:focus {
                            background-color: var(--flan-light-black);
                        }
                    }
                }
            }
        }
    }
}

.OrderCompleteRegistration .SignUpLabel {
    font-size: var(--body-font-size);
}

.Checkout22 .OrderCompleteRegistration .ContinueOn {
    font-family: var(--brand-font);
    background-color: var(--site-button-primary-background) !important;
    border: 1px solid var(--site-button-secondary-color);
    color: var(--site-button-primary-color) !important;
    font-weight: var(--fw-normal);
    font-style: normal;
    border: 0;
    text-transform: uppercase;
    display: block;
    line-height: 1em;
    letter-spacing: 0.7px;
    border-radius: var(--site-button-border-radius);
    padding: calc(var(--spacing-unit) * 4);
    max-width: 230px;
}

.OrderCompleteRegistration input[type="submit"].ContinueOn:hover,
.OrderCompleteRegistration input[type="submit"].ContinueOn:focus {
    background-color: var(--flan-light-black) !important;
}

.OrderComplete.Checkout22 a.button.secondary {
    font-family: var(--brand-font);
    font-style: normal;
    background-color: var(--site-button-secondary-background) !important;
    border: 1px solid var(--site-button-secondary-color);
    color: var(--site-button-secondary-color);
    font-weight: var(--fw-normal);
    text-transform: capitalize;
    letter-spacing: 0.7px;
    padding: calc(var(--spacing-unit) * 4);

    &:hover,
    &:focus {
        background-color: var(--site-button-primary-background) !important;
        color: var(--flan-white);
    }
}

.orderCompleteRegisterShown .OrderCompleteLoginHeader h2 {
    font-size: var(--body-font-size);
    text-decoration: underline;
}

.order-summary-table {
    text-align: left;
}

.order-summary .st1 {
    fill: var(--site-primary) !important;
}

.order-summary .product-gallery .swiper-button-next:focus,
.order-summary .product-gallery .swiper-button-next:hover,
.order-summary .product-gallery .swiper-button-prev:focus,
.order-summary .product-gallery .swiper-button-prev:hover {
    background-color: var(--site-button-primary-background) !important;

    .st1 {
        fill: var(--site-button-primary-color) !important;
    }
}

.registration-benefit .global-icon svg {
    width: 25px;
    height: 25px;
    margin-right: calc(var(--spacing-unit) * 3);
}

.registration-benefit .global-icon-wishlist {
    svg path {
        stroke-width: 3px;
    }
}

.order-details-section,
.Checkout22 .OrderCompleteRegisteredSignUp,
.RegisterWrapper-inner,
.Checkout22 .newsletterCompletion,
.order-summary,
.rokt-emdedded-placement-container:has(iframe) {
    background: var(--site-white);
    border: 1px solid #ddd;
}

.Checkout22 .newsletterCompletion,
.Checkout22 .OrderCompleteRegisteredSignUp {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.OrderCompleteRegistrationDone {
    border: none;
    padding: 0;
}

.Checkout22 .OrderCompleteRegistration .ContinueOn.OrderCompleteRegisteredSignUpButton {
    margin: 30px 0 0;
}

.Checkout22
    .OrderCompleteRegistrationDone:not(
        .OrderCompleteRegisteredSignUp,
        .newsletterCompletion .OrderCompleteRegistrationDone
    ) {
    padding: 0;
}

.Checkout22 .OrderCompleteRegistrationDone p:first-of-type:not(.registration-benefits p) {
    display: block;
    margin-left: calc(var(--spacing-unit) * 12.5);
    position: relative;
}

.Checkout22 .OrderCompleteRegistrationDone p .global-icon-tick {
    left: -50px !important;
    top: 0;
}

.Checkout22 .global-icon-tick {
    position: absolute;
}

.Checkout22 .global-icon-tick svg {
    width: 34px;
}

.Checkout22 .global-icon-tick .tick-outer {
    stroke-width: 1px;
    stroke: var(--site-primary);
}

.orderCompleteRegisterShown .OrderCompleteRegistrationDone p:not(.OrderComplete .registration-benefit p) {
    margin-bottom: calc(var(--spacing-unit) * 8);
}

.OrderCompleteRegistrationDone .reward-message p:not(:last-child) {
    margin-bottom: calc(var(--spacing-unit) * 2.5);
}

.OrderComplete.Checkout22:not(.orderCompleteRegisterShown) .footer-nav {
    text-align: center;
    margin-top: calc(var(--spacing-unit) * 6);
}

.OrderComplete.Checkout22 .footer-nav p {
    margin-bottom: calc(var(--spacing-unit) * 5);
}

@media (min-width: 768px) {
    .OrderComplete.Checkout22:not(.orderCompleteRegisterShown) .footer-nav {
        justify-content: center;
    }
}

@media (min-width: 1022px) {
    .OrderComplete.Checkout22 .footer-nav {
        justify-content: center;
    }
}

.Checkout22 .OrderCompleteRegistration {
    max-width: none;
}

.orderCompleteRegisterShown .innerBorder {
    margin-top: calc(var(--spacing-unit) * 2.5);
}

.Checkout22 .newsletterCompletion .OrderCompleteRegistrationDone,
.Checkout22 .OrderCompleteRegisteredSignUp .OrderCompleteRegistrationFormInner,
.order-summary {
    padding: calc(var(--spacing-unit) * 4) calc(var(--spacing-unit) * 4) calc(var(--spacing-unit) * 8);
}

.Checkout22 .OrderCompleteRegistrationFormInner {
    max-width: 750px;
}

.Checkout22 .newsletterCompletion p {
    font-size: 1.45em;
    line-height: 1.6em;
}

.OrderCompleteRegistrationFormInner .dnnFormMessage {
    padding: calc(var(--spacing-unit) * 2.5) 0;
}

.OrderComplete.Checkout22 h2,
.Checkout22 .SignUpWrapper,
.Checkout22 .RegisterPrompt {
    text-align: left;
}

.OrderComplete.Checkout22 h2 {
    color: var(--site-primary);
    margin: 0 0 calc(var(--spacing-unit) * 6);
}

.OrderComplete.Checkout22 h2.account-info {
    padding-top: calc(var(--spacing-unit) * 8);
    border-top: 1px solid #ddd;
}

.Checkout22 .OrderCompleteRegistration .SignUpWrapper {
    margin-bottom: calc(var(--spacing-unit) * 2.5);
    display: flex;
    font-size: 1.45em;
    line-height: 1.6em;
    font-weight: var(--fw-normal);
}

.Checkout22 .NewsletterFree,
.Checkout22 .OrderCompleteRegistration .SignUpLabel .NewsletterFree {
    text-transform: uppercase;
    color: var(--site-primary);
}

.Checkout22 .OrderCompleteRegistration .SignUpLabel .NewsletterFree {
    color: var(--site-primary);
}

.Checkout22 .OrderCompleteLogin .OrderCompleteLoginForm .dnnPrimaryAction {
    text-transform: uppercase;
    letter-spacing: 0.7px;
    border-radius: var(--site-button-border-radius);
    padding: calc(var(--spacing-unit) * 4);
    width: auto;
    border: 1px solid var(--site-primary);
    margin: 0;

    &:hover,
    &:focus {
        background-color: var(--site-button-primary-background) !important;
        color: var(--flan-neon) !important;
    }
}

.Checkout22 .OrderCompleteRegistration .SignUpWrapper input {
    width: 15px;
    height: 15px;
    margin-right: calc(var(--spacing-unit) * 3);
}

.orderCompleteRegisterShown .OrderCompleteRegistrationFormInner {
    padding: 0;
}

.Checkout22 .OrderCompleteRegistration .ContinueOn,
.RegisterWrapper-inner a.button {
    width: 100%;
}

.orderCompleteRegisterShown .OrderCompleteRegistration .ContinueOn {
    margin: calc(var(--spacing-unit) * 5) 0;
}

.orderCompleteRegisterShown .OrderCompleteLoginHeader {
    padding: 0;
}

.orderCompleteRegisterShown .OrderCompleteLoginHeader .glyphicon {
    display: none;
}

.orderCompleteRegisterShown .OrderCompleteLoginHeader .glyphicon::before {
    background: none !important;
}

.orderCompleteRegisterShown .OrderCompleteLogin .OrderCompleteLoginForm {
    margin: 0;
    max-width: none;
}

.orderCompleteRegisterShown .OrderCompleteLoginPrompt,
.OrderCompleteRegisteredSignUp {
    text-align: left;
}

.OrderCompleteRegistrationForm .field {
    margin: 0;
    max-width: none;
}

.orderCompleteRegisterShown .OrderCompleteRegistrationForm,
.orderCompleteRegisterShown .OrderCompleteLogin {
    border: none;
}

.orderCompleteRegisterShown .OrderCompleteLogin {
    margin-bottom: 0;
}

.orderCompleteRegisterShown .OrderCompleteLoginForm {
    padding: calc(var(--spacing-unit) * 2.5) 0 0;
}

.orderCompleteRegisterShown .OrderCompleteLogin .NewCustWrap {
    position: relative;
}

.orderCompleteRegisterShown .loginContainer .ForgotPass {
    padding: calc(var(--spacing-unit) * 2.5) 0;
    text-align: left;
}

.OrderComplete.Checkout22 .OrderCompleteLogin .ForgotPass .ForgotPasswordLinkButton {
    font-weight: var(--fw-normal);
}

.Checkout22 .OrderCompleteRegistrationDone p:not(.RegisterWrapper .OrderCompleteRegistrationDone p) {
    display: block;
    text-align: left;
    margin: 0 0 0 calc(var(--spacing-unit) * 12.5);
}

.Checkout22 .OrderCompleteRegistrationDone p a {
    color: var(--site-primary);
}

.Checkout22 .OrderCompleteRegistrationDone p {
    text-align: left;
}

#OrderCompleteRegisterModalBottom {
    padding: calc(var(--spacing-unit) * 4);
    gap: 16px;

    #OrderCompleteRegisterModalCancel,
    #OrderCompleteRegisterModalContinue {
        background-color: var(--site-button-primary-background);
        color: var(--site-button-primary-color);
        border-radius: var(--site-button-border-radius);
        font-weight: var(--fw-normal);
        text-transform: uppercase;
        letter-spacing: 0.7px;
        padding: calc(var(--spacing-unit) * 4);
    }

    #OrderCompleteRegisterModalCancel {
        border: 1px solid var(--site-primary);

        &:hover,
        &:focus {
            background-color: var(--site-button-primary-background) !important;
            color: var(--flan-neon) !important;
        }
    }

    #OrderCompleteRegisterModalContinue {
        border: 1px solid var(--flan-neon);

        &:hover,
        &:focus {
            background-color: var(--site-button-primary-background) !important;
            color: var(--flan-white) !important;
            border: 1px solid var(--site-button-primary-background);
        }
    }
}

@media (min-width: 768px) {
    .Checkout22 .OrderCompleteRegistration .ContinueOn,
    .RegisterWrapper-inner a.button,
    .Checkout22 .OrderCompleteLogin .OrderCompleteLoginForm .dnnPrimaryAction {
        width: auto;
        min-width: 330px;
    }
}

@media (min-width: 1022px) {
    .orderCompleteRegisterShown .OrderCompleteLogin,
    .orderCompleteRegisterShown .OrderCompleteRegistrationFormInner {
        padding-bottom: calc(var(--spacing-unit) * 5);
    }

    .orderCompleteRegisterShown .RegisterWrapper,
    .orderCompleteRegisterShown .OrderCompleteRegistration {
        order: 2;
    }

    .orderCompleteRegisterShown .order-summary {
        order: 3;
    }

    .orderCompleteRegisterShown .footer-nav {
        order: 4;
        margin-bottom: calc(var(--spacing-unit) * 6);
    }

    .orderCompleteRegisterShown .orderInformationWrapper,
    .orderCompleteRegisterShown .RegisterWrapper {
        width: 50%;
    }

    .orderCompleteRegisterShown .RegisterWrapper {
        padding-left: calc(var(--spacing-unit) * 6);
    }

    .Checkout22 .OrderCompleteLogin .OrderCompleteLoginForm .dnnPrimaryAction {
        display: inline-block;
    }

    .OrderComplete.Checkout22 .footer-nav p {
        margin-bottom: 0;
    }

    .Checkout22 .newsletterCompletion,
    .Checkout22 .OrderCompleteRegisteredSignUp {
        flex-direction: row;
    }

    .Checkout22 .newsletterCompletion .OrderCompleteRegistrationDone,
    .Checkout22 .OrderCompleteRegisteredSignUp .OrderCompleteRegistrationFormInner,
    .order-summary {
        padding: calc(var(--spacing-unit) * 6) calc(var(--spacing-unit) * 6) calc(var(--spacing-unit) * 8);
    }

    .Checkout22 .OrderCompleteRegistrationDoneImage {
        width: 100%;
        max-width: 450px;
    }
}

.Checkout22 .OrderCompleteRegistrationDoneImage > div {
    height: 100%;
}

@media (max-width: 767px) {
    .OrderComplete.Checkout22 a.button.secondary {
        width: 100%;
    }

    .Checkout22 .OrderCompleteRegistration .ContinueOn,
    .Checkout22 .OrderCompleteLogin .OrderCompleteLoginForm .dnnPrimaryAction {
        max-width: 100%;
    }

    .OrderCompleteRegistrationForm input[type="submit"] {
        max-width: 100%;
    }

    .OrderComplete .orderInformationWrapper .order-complete-messages a.button {
        max-width: 100%;
    }
}

.OrderComplete.Checkout22 {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 1600px;
    margin: 0 auto;
}

.orderCompleteRegisterShown {
    display: flex;
    flex-wrap: wrap;
}

.orderCompleteRegisterShown .orderInformationWrapper {
    order: 1;
}

.orderCompleteRegisterShown .RegisterWrapper,
.orderCompleteRegisterShown .OrderCompleteRegistration {
    order: 2;
}

.orderCompleteRegisterShown .order-summary {
    order: 3;
}

.orderCompleteRegisterShown .footer-nav {
    order: 4;
}

.OrderComplete.Checkout22 h1,
.order-complete-subtitle {
    text-align: left;
    margin-bottom: 30px;
    font-weight: var(--fw-normal);
}

.order-details-delivery-date span {
    color: var(--flan-black);
}

.OrderComplete.Checkout22 h1,
.Checkout22 .OrderCompleteRegistration .ContinueOn {
    text-transform: capitalize;
}

.OrderComplete.Checkout22 h1 em {
    font-style: inherit;
}

.OrderComplete.Checkout22 h1 em::before {
    display: none;
}

.order-complete-messages .subtitle,
.order-complete-subtitle {
    margin-bottom: calc(var(--spacing-unit) * 2.5);
}

.OrderComplete.Checkout22 a.button,
.Checkout22 .OrderCompleteRegistration .ContinueOn {
    text-transform: capitalize;
    border: 1px solid;
    display: block;
    line-height: 1em;
}

.OrderComplete .order-complete-messages a.button {
    margin-top: 20px;
}

.order-summary-discount-value {
    color: var(--flan-red);
}

@media (min-width: 768px) {
    .OrderComplete.Checkout22 {
        padding-left: 40px;
        padding-right: 40px;
    }

    .OrderComplete.Checkout22 a.button {
        display: inline-block;
        min-width: 330px;
    }

    .order-complete-subtitle {
        margin-bottom: 30px;
    }

    .OrderComplete .order-complete-messages a.button {
        margin-top: 40px;
    }

    .sticky-atb .originalprice {
        padding-right: 0;
    }
}

.order-summary .product-gallery .swiper-scrollbar .swiper-scrollbar-drag {
    background-color: var(--site-primary);
}
