/* Chanel Brand Styling */
.FlanProdDet.brand-chanel #productImages #productImageContainer,
.FlanProdDet.brand-chanel #productImages #productImageContainer .productImage.zIndexAboveColourSelector {
    box-shadow: none;
}

.FlanProdDet.brand-chanel .product-detail__name {
    text-transform: none;
}

@media (min-width: 768px) {
    .FlanProdDet.brand-chanel .product-detail__brand-logo {
        max-width: 120px;
    }
}
