.FlanProdDet {
    .product-info {
        .delivery-method,
        .pdp-delivery-message {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .delivery-method-header {
            font-weight: var(--fw-semibold);
            line-height: 20px;
        }

        .delivery-method-description {
            margin: 0;
            font-size: 14px;
        }

        .returns-method {
            p {
                font-size: 14px;
            }
        }
    }
}
