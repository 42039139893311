:root {
    --brand-font: "Montserrat", arial, helvetica, sans-serif;
    --fw-normal: 400;
    --fw-medium: 500;
    --fw-semibold: 600;
    --fw-bold: 700;
}

@font-face {
    font-family: Montserrat;
    src: url("/fonts/Montserrat-Variable.woff2") format("woff2-variations");
    font-weight: 100 900;
    font-stretch: 100%;
    font-style: normal;
    font-display: swap;
}

#Body,
p,
a,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
html,
blockquote,
th,
td,
tr,
legend,
select,
button,
input[type="submit"],
label,
.prc_comment_postdatetime,
#StoreFinderResultsAttributes ul li,
nav#topMenu li.root > ul li a,
nav#topMenu > ul li a,
nav#topMenu li.root .SubMenuWrapper > ul li a,
.PSSellPrice,
.s-productthumbtext .productdescriptionname,
.am-menu .shop ul li.level2 > a,
.lillBasket #divBagItemsChild .bagHeader p .basketTotalItemsHeader,
.brand-room-2 .loving-swiper .beautySwiper .InfoTitle,
.u-button,
#productDetails .product-detail__name,
#productDetails .product-detail__price,
.FlanProdDet .WishListContain a,
a#aAddToBag,
a#addHotspotToBag,
.FlanProdDet .addToBasketContainer .ImgButWrap a span,
.FlanProdDet .addToBasketContainer .ImgButWrap a#aAddToBag span,
.u-form__select,
.image-dropdown span.value,
.SizeGuideText,
.u-form__label,
.shadeSwitch #divColour::before,
#divColour .s-productextras-column-1 span,
.matchMyMakeupContainer .MMMHeader,
.matchMyMakeupContainer .MMMContent,
.product-detail__variants-singular,
.product-detail__sizes-singular,
.FlanProdDet .product-usage-attributes .nav-tabs > li > a,
.product-usage-attributes .panel-default > .panel-heading a,
.FlanProdDet .title h1 #lblProductBrand,
.FlanProdDet .ShopTheLook h2.LookHeader,
.FlanProdDet .ShopTheLook .productdescriptionbrand,
.free-samples-promo-container .promo-details h2,
.gwpPromotion div.promotionInfo .promotionInfoTitle,
.brand-room-2 .brand-title h5,
.brand-room-2 .loving-swiper .sideLogo > div,
.brand-room-2 .lovingSlider .loving-swiper .swiper-container-loving span.slideName,
.loving-swiper .swiper-container-homepageLower span.slideName,
.brand-room-2 .loving-swiper .InfoTitle,
.brand-room-2 .shop-look .sideLogo > div,
.brand-room-2 .shop-look .innerSubHeader,
.brand-room-2 .shop-look .subHeader,
.brand-room-2 .hp_content .InfoTitle,
.brand-room-2 .tutorialHub .innerSubHeader,
.brand-room-2 .tutorialHub .subHeader,
.brand-room-2 .tutorialHub .sideLogo > div,
#GwpPromotionDetailModal .promoTitle .promoTitleText,
[data-bv-show="rating_summary"] .bv_main_container .bv_button_buttonMinimalist,
[data-bv-show="rating_summary"] .bv_main_container .bv_numReviews_text,
.reviews-container [data-bv-rating] .bv_main_container .bv_text,
.reviews-container [data-bv-show="inline_rating"] .bv_main_container .bv_text,
.bv_modal_component_container .bv_button_component_container button.bv_button_buttonFull {
    font-family: var(--brand-font) !important;
}

nav#topMenu li.root .SubMenuWrapper > ul .sdmColHeader > a,
nav#topMenu li.root .SubMenuWrapper > ul .sdmColStrong > a,
.styleNews h3,
nav#topMenu li.root .SubMenuWrapper .styleNews .blogMainHeader,
nav#topMenu ul li.root .SubMenuWrapper ul li.KidsRight.Right .kidsFeatured .postTitle,
nav#topMenu ul li.root .SubMenuWrapper ul li.KidsRight.Right .kidsFeatured .postSection1::before,
.Browse h1,
.ShopTop,
.saleprice,
.am-menu .shop ul li > a,
#divBagItemsChild p,
nav#topMenu li.root .SubMenuWrapper .styleNews .styleNewsLink,
li.productFilter .productFilterTitle,
.flanProdList .refineByLeftHeader h2 span,
.sortOptionsHeader,
.FlanProdDet .PSPlacementHorizontal span.PSProdBrand,
.FlanProdDet .RecentlyViewedListOnProductsPage span.AltStratBrand,
.FlanProdDet .PSPlacementHorizontal .PSProdPrice .PSSellPrice,
.FlanProdDet .RecentlyViewedListOnProductsPage .AltStratRefSell .AltStratSellPrice,
.FooterSubGroup .FooterHeader,
.FooterGroupLink li a:hover,
nav#topMenu li.root .SubMenuWrapper > ul li.Center a:hover > .postTitleWrapper h3.postTitle,
.Responsive .FilterListItem a .SelectedFilter .FilterName,
.Responsive .FilterListItem a:hover .FilterName,
.emailWrapper .SocialWrapperHeader,
.flanProdList .catTemplate .viewMore a span,
.reviews-container
    .bv-cv2-cleanslate
    .bv-content-pagination
    .bv-content-pagination-container
    .bv-content-btn-pages-load-more,
#topLinkMenu ul.TopSubLinkMenu li a:hover,
#mp-menu .shop .mp-level ul.mobMenGroup > li.sdmColViewAll > a,
nav#topMenu ul li.root .SubMenuWrapper ul li.level2.sdmColViewAll a,
nav#topMenu li.root .SubMenuWrapper > ul li.Bottom a,
.brand-room-2 .brand-name h2,
.ChildCategoriesListWrapper h2,
.s-productthumbtext .productdescriptionbrand,
.s-producttext-price .s-largered span,
#filterByMob .MobFiltersText,
#mobFilterControls li span {
    font-family: var(--brand-font) !important;
    font-weight: var(--fw-semibold) !important;
}

.FooterSubGroup .FooterHeader,
.FlanProdDet .infoRow .left-info ul li:first-child,
.FlanProdDet .infoRow > div h3,
.s-productextras-checkbox-column-1 label,
.s-productextras-column-1 label,
.s-productextras-column-1 span,
.FlanProdDet #productDetails .productVariantContainer .productDescription h3,
.FlanProdDet .AltProdDet .pdpPrice span,
.emailWrapper .SocialWrapperHeader,
#ulBag li .BaskName,
#ulBag li .BaskPrice,
.FlanProdDet .mobileInfoRow .pd-accordion .acc-title h3,
.flanProdList.Browse h1,
.flanProdList .flan20PLP .textDesc #catCopyMobileReadMoreLess,
.flanProdList .flan20BLP .textDesc #catCopyMobileReadMoreLess,
.brand-room .brand-name h1,
.brand-room-2 .brand-name h1,
.brand-room-2 .brand-title h1,
.brand-room-2 .brand-name .beauty-title {
    font-family: var(--brand-font) !important;
    font-weight: var(--fw-semibold) !important;
}

.MenuGroupA > ul,
.MenuGroupB > ul {
    max-width: 1190px;
    width: 100%;
}

.shop > ul > li#liAllDepts a {
    background-color: transparent !important;
}

a,
a:active,
a:focus,
a:link,
a:visited,
.RightBox p,
.RightBox h3,
.RightBox,
.TopHeader p,
.infoTabPage p,
.infoTabPage ul,
.lillBasket .BaskQuant,
.lillBasket .ColrandSize,
.FlanProdDet .helpContainer .pd-accordion .acc-content ul li a,
.FlanProdDet #productVariantAndPrice .s-productextras-column-3 .SizeGuideText {
    color: #000;
}

a.plpactive {
    color: #b90000;
}

body,
h1,
h1 span,
h2,
h3,
h4,
h5,
p,
.Basket h1,
.weWrap,
.Basket #BasketDiv table a,
.Basket #BasketDiv table td,
.Basket #BasketDiv .basketsubtotal,
.sizeText,
#productImages .imageControls .zoomControl:hover,
ins,
mark,
.Profile .field label,
.pagination .sortbyfilter label,
.Responsive .StoreFinderResultsDetailsLinkContainer a,
h6,
a:hover,
a p:hover,
.outofstock,
.csHomeTable a,
.csHomeTable a:hover,
.saleprice,
.PSSellPrice,
.SubBask .PriceTotalBask,
.discountText,
.SubTotal,
.s-largered,
.csHomeTable li.header a,
.csHomeTable li.header a:hover,
nav#topMenu > ul li.FlanHome a,
.BaskName:hover,
.AltStratSellPrice,
.originalprice,
.BaskPrice,
#FootcmdSearch,
#infoaccordion .FooterHeader span,
#divBagTotalLink span#bagQuantity,
.s-productthumbtext a,
#MobcmdSearch,
.Help_Title,
.s-smalltext,
.AltStratRefPrice,
.Responsive .StoreFinderResultsDetailsLinkContainer a:hover,
nav#topMenu ul li.root .SubMenuWrapper ul.New li.NewCenter .level1 a,
.OrderCompleteRegistration .SignUpLabel .NewsletterFree,
nav#topMenu li.root .SubMenuWrapper > ul .sdmColHeader > a,
.styleNews h3,
nav#topMenu li.root .SubMenuWrapper .styleNews .blogMainHeader,
.styleNews a:hover,
nav#topMenu ul li.root .SubMenuWrapper ul li.KidsRight.Right .kidsFeatured .postTitle,
nav#topMenu ul li.root .SubMenuWrapper ul li.KidsRight.Right .kidsFeatured .postSection1::before,
nav#topMenu ul li.root .SubMenuWrapper ul li.KidsRight.Right .kidsFeatured .postSnippet::after,
#accountMenu .sidebar-nav li.active a,
.FooterSubGroup .FooterHeader,
.FooterGroupLink li a,
.Copyright span,
.am-menu ul li > a,
.accordionMenuContentWrap .menuitemtext,
.accordionMenuContentWrap #spanLanguageMenuItem,
.accordionMenuContentWrap #spanCurrencyMenuItem,
.accordionMenuContentWrap #spanSelectedCurrency,
.accordionMenuContentWrap .mob-login,
.accordionMenuContentWrap .glyphicon-chevron-right,
#topLinkMenu ul li.TopLinkDrop ul li a,
.ui-autocomplete li #ui-active-menuitem,
.s-productthumbtext .productdescriptionbrand,
nav#topMenu li.columnGroup a,
.styleNews a,
nav#topMenu ul li.root .SubMenuWrapper ul li.KidsRight.Right .kidsFeatured .postSnippet,
nav#topMenu li.columnGroup a:hover,
.accordionMenuContentWrap .shop .glyphicon-chevron-right,
.dropdown-menu li a,
nav#topMenu li.root .SubMenuWrapper .styleNews .styleNewsLink,
.styleNews .postLink,
nav#topMenu ul li.root .SubMenuWrapper ul li.level1 > ul,
nav#topMenu ul li.root .SubMenuWrapper ul li.level1.sdHover > a,
nav#topMenu ul li.root .SubMenuWrapper ul li.level2,
nav#topMenu li.columnGroup li.level1 .itemViewAll a,
nav#topMenu .styleNews .itemView.All a,
nav#topMenu li.root .SubMenuWrapper > ul li.Center a:hover > .postTitleWrapper h3.postTitle,
.Responsive .FilterListItem a .SelectedFilter .FilterName,
.Responsive .FilterListItem a:hover .FilterName,
.Responsive .StoreFinderResultsLink,
.Responsive .StoreFinderResultsLinkMobile,
.Responsive #StoreDetailsWrapper .StoreFinderDirectionsButton,
.lillBasket #divBagTotalLink #bagQuantity,
.FlanProdDet .productDescription .infoPage .infoTabPage span,
.FlanProdDet .productDescription .infoPage .infoTabPage span a,
.FlanProdDet .productDescription .infoaccordion .panel-collapse span,
.FlanProdDet .productDescription .infoaccordion .panel-collapse span a,
.s-breadcrumbs-bar > span ol li a:hover span,
.flanProdList .pagination span.PageSelector,
.signup-wrapper input[type="button"] {
    color: #000;
}

.ImgButWrap a,
.ImgButWrap a:active,
.ImgButWrap a:visited,
.ImgButWrap a:link,
.ImgButWrap input,
button,
.Basket #BasketDiv .UpdateQuant,
.Profile a.dnnPrimaryAction,
input[type="button"],
input[type="submit"],
.primary2 a.dnnPrimaryAction,
#aPayNow,
#aViewBag,
.CheckWrap a.ContinueOn,
.CheckWrap .ContinueOn a,
.CheckWrap input.ContinueOn,
a.prc_comment_postcomment,
.Wishlistcontainer #ImgButWrapResponsive a,
.sg_colHead,
.Brands .allLettersBrand a,
.Brands .letTilte a,
.Brands .letTilte a:hover,
.Brands .letItems a:hover,
.Brands .whiteBoxBrand .greyBgBrand.boxContentBrand,
.OrderGroup .OrderColC .ButtonOrder a,
.OrderColC .TrackOrder a,
.Responsive #StoreFinderResultsListing .backButton,
.Responsive #StoreDetailsWrapper .backButton,
.HeaderIcons > li a:hover,
#divBagTotalLink a#aBagLink:hover,
#NewsLetterModal .form-wrapper label,
.signup-wrapper input.signbut:hover {
    color: #fff;
}

.flanProdList .pagination .PageNumber a {
    color: #676767;
}

.FlanProdDet .price-container #TicketPriceDiv2 span {
    color: #7b7b7b;
}

.FlanProdDet #productVariantAndPrice .s-productextras-column-3 a,
p#lblProductCode {
    color: #868686;
}

.search input[type="text"] {
    color: #494646;
}

.emailWrapper .newsletterPrivacy p,
.emailWrapper .newsletterPrivacy a,
.am-level .mobMenuNewsletter .newsletterPrivacy p,
.am-level .mobMenuNewsletter .newsletterPrivacy a {
    color: #555;
}

.flanBasket #divBagTotalLink #bagQuantity,
.ShopTop {
    color: #333;
}

.productcolour,
.productsize,
.categorycopyd4 {
    color: #555;
}

.Basket #BasketDiv table th,
.s-smalltext,
.sizeDetail,
#productImages .imageControls .zoomControl,
#NewsLetterModal .header-text {
    color: #666;
}

.AltFooterGroups li {
    color: #747474;
}

.s-breadcrumbs-bar li span,
.s-breadcrumbs-bar li,
.s-breadcrumbs-bar ol {
    color: #888;
}

input[type="button"]:disabled,
input[type="submit"]:disabled,
input[type="reset"]:disabled,
input[type="file"]:disabled::-webkit-file-upload-button,
button:disabled,
select:disabled,
keygen:disabled,
optgroup:disabled,
option:disabled {
    color: #888;
}

nav#topMenu li.root .SubMenuWrapper > ul .sdmColHeader.saleColour > a {
    color: #f00;
}

.progressBarItem {
    color: #bbb;
    background-color: #ddd;
}

.progressBarActive,
nav#topMenu li.root > a:hover,
nav#topMenu li.root > a,
#divBagTotalLink span,
#topLinkMenu > ul > li > a,
#findAstore a,
.langlogin a.login,
#topLinkMenu ul li a:hover,
.ImgButWrap a,
.ImgButWrap a:active,
.ImgButWrap a:visited,
.ImgButWrap a:link,
.ImgButWrap input,
.Basket #BasketDiv .UpdateQuant,
button,
input[type="button"],
input[type="submit"],
.primary2 a.dnnPrimaryAction,
.primary2 input,
#aPayNow,
.basketLink .langlogin a.login,
#aViewBag,
.addToBasketContainer .ImgButWrap,
.pagination span.PageSelector,
.CheckWrap a.ContinueOn,
.CheckWrap .ContinueOn a,
.CheckWrap input.ContinueOn,
.CareersBrand .navbar-nav > li > a,
.footerDesktop h3,
.shop > ul > li > a,
.dbc,
header#HeaderGroup.secondLevelMenuSelected nav#topMenu ul li.mmHasChild.secondLevelMenuClick > a,
.csImageHeader h2,
.flanProdList #mobFilterControls li.mobClearFilters span {
    color: #fff;
}

.spanCurrencyLanguageSelector p,
.spanCurrencyLanguageSelector p:hover {
    color: #ddd;
}

.Basket #BasketDiv .productcode span,
.pppt,
.RefandPrice span,
.originalprice .originalTicket {
    color: #888;
}

.maxText {
    color: #e81515;
}

.SubMenuGroup li:last-child a {
    color: #e90808;
}

.flanProdList #mobControlBar #filterByMob .mobappfltrsNo {
    color: var(--flan-neon);
}

header#HeaderGroup.secondLevelMenuSelected nav#topMenu > ul > li.mmHasChild > a {
    color: #9f9898;
}

.Profile a.dnnPrimaryAction,
.currentPage {
    color: #fff !important;
}

nav#topMenu li.columnGroup .pinkText a {
    color: #d40081 !important;
}

nav#topMenu li.columnGroup .pinkText a::before {
    background-color: #d40081 !important;
}

.productDescription .RadTabStrip .rtsLevel .rtsOut,
.productDescription .RadTabStrip .rtsLevel .rtsIn {
    color: #888 !important;
}

.productDescription .RadTabStrip .rtsLevel .rtsOut:hover,
.productDescription .RadTabStrip .rtsLevel .rtsIn:hover {
    color: #444 !important;
}

.productDescription .RadTabStrip .rtsLevel .rtsSelected .rtsIn {
    color: #444 !important;
}

.contentwrap,
.header,
nav#topMenu li.root > a:hover,
nav#topMenu ul li.root:hover,
#topLinkMenu ul li a:hover,
.ui-autocomplete li #ui-active-menuitem,
#divBagTotalLink a#aBagLink:hover,
.FooterSubGroup .FooterHeader,
.FooterGroup1,
.FooterGroup2,
.search input[type="text"],
.searchstores .store-fields-main,
#productDetails .ColnSize select:disabled,
.letTilte a,
.zoomText,
.Help_Title,
.csHomeTable li.header,
#contactuscontent .SubjectDrop,
.HeaderWrap,
#topMenuWrapper,
nav#topMenu > ul li,
.PSPlacementTitle,
.AltStratTitle,
#mp-menu .shop > ul li.has-dropdown,
#mp-menu .shop > ul > li.has-dropdown > a,
#mp-menu .shop > ul > li.has-dropdown > .am-level,
#mp-menu .shop ~ .shop,
#mp-menu .shop ul.mobMenGroup li.level1 a,
.HeaderIcons > li a:hover,
.currencyLanguageSelector,
.flanProdList .pagination span.PageSelector,
#mp-menu .accordionMenuContentWrap > ul li,
.accordionMenuContentWrap > ul li,
.SizenContact .panel-default > .panel-heading {
    background-color: transparent;
}

body,
.cservices #profileList,
.RadTabStripTop_Vista .rtsLevel .rtsLink,
.RadTabStripTop_Vista .rtsLevel .rtsOut,
.RadTabStrip .rtsLevel .rtsSelected .rtsIn,
#divBagItems,
#divEmptyErrorMessage,
#divAjaxLoaderImage,
#ulBag li,
.BodyWrap,
#BodyWrap,
.CareersBrand .vacancy-search-submit a,
.CareersBrand .vacancy-more-info-btn a,
nav#topMenu ul li.root .SubMenuWrapper,
.accordionMenuContentWrap .shop > ul > li.root.open > a,
.accordionMenuContentWrap .has-dropdown li.level1.open > a,
.am-level .shop .has-dropdown .am-level,
#topLinkMenu ul.TopSubLinkMenu > li,
.lillBasket #divButtons,
.RadTabStrip .rtsLevel .rtsOut,
.RadTabStrip .rtsLevel .rtsIn,
nav#topMenu ul li.root .SubMenuWrapper ul li.level1.sdHover > a,
.lillBasket #divButtons a#aCheckout::before,
.basketLink .langlogin a.login > .hidden-xs::before,
.basketLink .langlogin .TopLink > a span::before,
.spanCurrencyLanguageSelector p::before,
nav#topMenu ul li.root > a:hover::before,
.SubMenuWrapper .newImages .postTitle,
.Responsive #StoreDetailsWrapper .StoreFinderDirectionsButton,
.Responsive #StoreFinderResultsListing .backButton span::before,
.Responsive #StoreDetailsWrapper .backButton span::before,
#GatedAddToBagEmailPopup .modal-header,
#GatedAddToBagEmailSentPopup .modal-header,
.accordionMenuContentWrap #liMobileCurrencySelector,
.accordionMenuContentWrap #liMobileLanguageSelector:hover,
.accordionMenuContentWrap #liMobileCurrencySelector:hover {
    background-color: #fff;
}

nav#topMenu ul li.root .SubMenuWrapper,
nav#topMenu ul li.root .SubMenuWrapper::before,
nav#topMenu ul li.root .SubMenuWrapper::after {
    background-color: rgba(255, 255, 255, 0.9);
}

#divBagTotalLink span#bagQuantity,
#NewsLetterModal .modal-body,
.NewsLetterModal .modal-content,
#advertPopup .modal-body {
    background-color: #181816;
}

.byPostGroup {
    background-color: #f7f7f7;
}

.SubMenuPaneWrap {
    background-color: #232323;
}

ins {
    background-color: #ff9;
}

mark {
    background-color: #ff0;
}

div.NewMenuWrap,
.ImgButWrap a,
.ImgButWrap a:active,
.ImgButWrap a:visited,
.ImgButWrap a:link,
.ImgButWrap input,
.Basket #BasketDiv .UpdateQuant,
button,
input[type="button"],
input[type="submit"],
.primary2 a.dnnPrimaryAction,
.primary2 input,
#aPayNow,
#aViewBag,
.addToBasketContainer .ImgButWrap a,
.pagination span.PageSelector,
.CheckWrap a.ContinueOn,
.CheckWrap .ContinueOn a,
.CheckWrap input.ContinueOn,
.CareersBrand .HeaderWrap,
.CareersBrand .vacancy-search-submit a,
.CareersBrand td.vacancy-more-info-btn > a,
.mobile-menu-container .navbar-header .navbar-toggle .icon-bar,
.OrderGroup .OrderColC .ButtonOrder a,
.OrderColC .TrackOrder a,
.Profile a.dnnPrimaryAction,
.lillBasket #aCheckout,
.DeliveryOptions .SelectButton,
.LogoCheck,
.fullforBackg,
#accountMenu .sidebar-nav li.active a::before,
.HeaderWrap .TopNavBar,
#BodyWrap #divMobSearch,
.footerWrap input[type="button"],
nav#topMenu ul li.root .SubMenuWrapper ul.New li.NewCenter .level1:hover::after,
.Responsive #StoreFinderResultsListing .backButton,
.Responsive #StoreDetailsWrapper .backButton,
.Responsive #StoreFinderResultsListing .StoreFinderResultsLink span::before,
.StoreFinderResultsDetailsLinkContainer a::before,
.SeeOnMapContainer .SeeOnMapLink::before,
.Responsive #StoreDetailsWrapper .StoreFinderDirectionsButton span::before,
.Responsive #StoreDetailsWrapper .SeeOnMapContainer .btnSeeOnMap::before,
.accordionMenuContentWrap ul li.has-dropdown > a.menuitemtext span::before {
    background-color: #000;
}

.ImgButWrap a:hover,
.ImgButWrap input:hover,
.ImgButWrap a:focus,
button:hover,
.Profile a.dnnPrimaryAction:hover,
input[type="button"]:hover,
input[type="submit"]:hover,
.primary2 a.dnnPrimaryAction:hover,
.primary2 input:hover,
#aPayNow:hover,
#aViewBag:hover,
.CheckWrap a.ContinueOn:hover,
.CheckWrap .ContinueOn a:hover,
.CheckWrap input.ContinueOn:hover,
a.prc_comment_postcomment:hover,
.Wishlistcontainer #ImgButWrapResponsive a:hover,
.OrderGroup .OrderColC .ButtonOrder a:hover,
.OrderColC .TrackOrder a:hover,
.DeliveryOptions .SelectButton:hover,
.signup-wrapper input.signbut:hover,
.Basket #BasketDiv .UpdateQuant:hover {
    background-color: #222;
}

.mp-level {
    background-color: #f1f1f1;
}

.FlanProdDet .infoRow .innerInfoRow::before,
.sizeButtons li.greyOut a {
    background-color: #ebebeb;
}

.FooterWrap .fullFooter,
.signup-wrapper input[type="button"] {
    background-color: var(--flan-neon);
}

nav#topMenu ul li.root div.SubMenuWrapper {
    background-color: #fff;
}

.lillBasket #aViewBag {
    color: #000 !important;
}

.lillBasket #aViewBag:hover {
    background-color: #fff !important;
    color: #000 !important;
}

#divCurrencyLanguageSelector:hover,
.langlogin a.login:hover,
#divBagTotalLink a#aBagLink:hover,
#divBag #divBagTotalLink a#aBagLink:hover,
.lillBasket #ulBag li + li::before,
#topLinkMenu ul li.TopLinkDrop > a:hover,
a#aWishListLink:hover {
    background-color: #3c3c3c !important;
}

.currentPage {
    background-color: #666;
}

.progressBarActive {
    background-color: #888;
}

.sizeButtons li.greyOut a::before,
.sizeButtons li.greyOut a::after {
    background-color: #ccc;
}

.dropprods_Order {
    background-color: #f1f1f1;
}

.SizenContact .AccIcon,
.SizenContact .TextTab {
    background-color: #f2f2f2;
}

.accordionMenuContentWrap .shop > ul li,
.accordionMenuContentWrap > ul li,
#mp-menu {
    background-color: #f3f3f3;
}

.MenuCloseActive {
    background-color: #f7f7f7;
}

.lillBasket #divBagItemsChild .bagHeader p,
#topLinkMenu ul li.TopLinkDrop ul li.TopSubViewAll::before {
    background-color: #f5f5f5;
}

.RightPane1 .c_GreyBox {
    background: none;
}

.piActiveDot,
.FlanProdDet #piThumbList li .imgdot {
    background-color: #000 !important;
}

#advertPopup .close {
    background-color: #666 !important;
}

#advertPopup .close:hover {
    background-color: #999 !important;
}

.greyBoxBrand {
    background-color: #fff !important;
}

nav#topMenu li.root > ul .redHeader > a {
    color: #c60b0b;
}

#CheckoutSpaRoot .CheckoutHeader,
#CheckoutSpaRoot header,
#CheckoutSpaRoot .summaryfooter {
    background-color: #000;
}

#CheckoutSpaRoot .headerSummaryContainer,
#CheckoutSpaRoot .activeSection .sectionGroup h1::before,
#CheckoutSpaRoot footer {
    background-color: #eaff00;
}

#CheckoutSpaRoot .activeSection .sectionGroup h1::before {
    border: 1px solid #000;
}

#CheckoutSpaRoot .copyrightTextMob,
#CheckoutSpaRoot #footerStepMsg,
#CheckoutSpaRoot .CheckoutHeader .headerSummary .summaryText,
#CheckoutSpaRoot .CheckoutHeader .headerSummary .summaryPrice {
    color: #000;
}

#CheckoutSpaRoot .CheckoutHeader .headerSummary .summaryText,
#CheckoutSpaRoot .CheckoutHeader .headerSummary .summaryPrice {
    font-weight: var(--fw-medium);
}

#CheckoutSpaRoot .copyrightTextMob,
#CheckoutSpaRoot #footerStepMsg {
    font-weight: var(--fw-semibold);
}

#CheckoutSpaRoot .checkChevron span {
    background-image: url("/images/core/flannels-20-sprite-sheet-v9.svg");
    background-position: -243px -263px;
    transform: scale(1.5) rotate(-90deg);
}

#CheckoutSpaRoot .basketSummaryActive .checkChevron span {
    transform: scale(1.5) rotate(90deg);
}

#CheckoutSpaRoot .control-label {
    font-weight: var(--fw-medium);
    font-size: 15px;
}

#CheckoutSpaRoot .sectionGroup h1::before {
    background-color: #000;
}

#CheckoutSpaRoot .formCompleteCTA {
    button {
        &:hover,
        &:focus {
            background-color: #000;
            color: var(--flan-neon);
            border: none;
        }
    }
}

#CheckoutSpaRoot .formCompleteCTA button {
    font-weight: var(--fw-medium);
    letter-spacing: 0.1em;
    text-transform: capitalize;
}

.CareersTopMenu a {
    color: #fff;
}

.CareersBrand .topLevelLink,
.CareersBrand .topLevelLink:hover {
    background-color: #000;
    border-bottom: solid 1px #000;
}

.MenuText {
    color: #fff;
}

.CareersBrand td.vacancy-more-info-btn > a {
    background-image: none;
}

.CareersBrand td.vacancy-more-info-btn > a,
.CareersBrand td.vacancy-more-info-btn > a:hover {
    border-color: #000;
}

.CareersBrand .mobile-menu-container .navbar-header {
    background-color: #181816;
    border: 1px solid #fff;
}

.CareersBrand .vacancy-search-submit a,
.CareersBrand .vacancy-search-submit a:hover {
    border-color: #000;
}

.CareersBrand .navbar-nav > li > a {
    border-right: 1px solid #fff;
}

.CareersBrand .mobile-menu-container .navbar-header .navbar-toggle .icon-bar {
    background-color: #fff;
}

@media (max-width: 767px) {
    .menulinks li {
        background-color: #000;
    }

    .menulinks li a {
        color: #fff;
    }
}

@media (max-width: 1021px) and (min-width: 768px) {
    #findAstore {
        background-image: none;
        text-indent: -9999px;
        white-space: nowrap;
    }
}

.personalisation,
.preorder {
    color: #444;
}

.FooterCardIcons.gbp,
.FooterCheck .ThreeHelpTips .CardPayments .CheckFootImg.gbp,
.BasketCardsIcons .PayImage.gbp {
    background-image: url("/images/payment/cardpayments-gbp.png");
}

#helpMenu {
    padding: 0 10px 20px;
}

#helpMenu h2 {
    display: none;
}

#helpMenu .sidebar-nav li {
    border: none;
    margin-bottom: 10px;
    font-size: 1em;
}

#helpMenu .sidebar-nav li a {
    text-align: center;
    background-color: #f3f3f3;
    text-transform: uppercase;
    padding: 12px 0;
    color: #9e9e9e;
    transition: all 400ms linear 0s;
}

#helpMenu .sidebar-nav li a:hover {
    background-color: #000;
    color: #fff;
}

.Help_Title,
.c_BlackTitle .c_Title {
    margin-top: 9px;
    text-transform: uppercase;
    background-color: #000;
    color: #fff;
    padding: 12px 15px;
}

.c_BlackTitle .c_Title {
    background-color: #000 !important;
}

@media (min-width: 1022px) {
    #helpMenu {
        padding: 0 10px;
    }

    #helpMenu .sidebar-nav {
        padding: 10px 40px 0 0;
    }

    .csImageHeader {
        margin-bottom: 30px;
    }
}

.allLetterBrandHeader,
.allLetterBrandFooter {
    display: none;
}

.allLettersBrand {
    margin: 0;
    border-top: 2px solid #000;
    border-bottom: 1px solid #000;
    width: 100%;
}

.letTilte a {
    font-size: 4em;
    font-weight: var(--fw-semibold);
    color: #181816;
    min-width: 75px;
    text-align: center;
}

.c_GreyBox {
    background: #fff;
    padding: 10px;
}

.TopHeader > div,
.TopHeader p {
    text-align: center;
    font-size: 2.6em;
    padding: 0;
}

.TopHeader > div > a {
    font-weight: var(--fw-semibold);
    text-transform: uppercase;
    color: #181816;
}

#contactuscontent .SubjectDrop {
    padding: 20px 0;
    width: 100%;
}

#contactuscontent .SubjectDrop div {
    padding: 0;
}

#contactuscontent .SubjectDrop label {
    padding: 0;
    text-transform: uppercase;
}

.InputContactGroup div {
    width: 50%;
}

.InputContactGroup label {
    display: block;
    width: 100%;
    padding-left: 0;
    padding-bottom: 5px;
    text-transform: uppercase;
}

#contactuscontent input {
    border: none;
    background-color: #ececec;
    padding: 12px;
    color: #757575;
    width: 100%;
}

.SuperStar {
    width: auto;
    position: absolute;
    top: 0;
    left: -8px;
}

.confirmfound {
    margin: 10px 0;
}

.InputContactGroup div.MsgContactBox,
.InputContactGroup div#FormOptions {
    width: 100%;
}

.InputContactGroup div.MsgContactBox textarea {
    border: none !important;
    background-color: #ececec;
    padding: 12px;
    color: #757575;
}

.InputContactGroup div#FormOptions a {
    text-transform: uppercase;
}

.BasketNew .PromoCodeInput input {
    border: none;
    box-shadow: none;
    background-color: #ececec;
}

.newBasketPromoCode .PromoCodeBut a {
    background-color: #888;
    background-image: none;
    color: #fff;
    text-transform: uppercase;
    text-shadow: 0 0 0 #fff;
}

.newBasketPromoCode .PromoCodeBut a:hover {
    background-color: #333 !important;
}

.siblingcategory {
    display: none;
}

a:hover,
nav#topMenu li.root > ul li a:hover,
nav#topMenu ul li.root > a:hover,
#findAstore a:hover,
nav#topMenu > ul .NewMenuWrap li.level2 a:hover,
.flanFooterContainer li a:hover,
.basketLink .langlogin a.login:hover,
.spanCurrencyLanguageSelector p:hover,
.breadcrumb ol li a:hover,
.SortQtyName a:hover,
.Responsive .FilterListItem a:hover *,
.s-producttext-top-wrapper a:hover span span,
.lillBasket .BaskName:hover,
.Basket #BasketDiv table .productTitle:hover,
a.prodelete:hover,
.letItems a:hover,
#topLinkMenu ul li a:hover {
    text-decoration: none;
}

.accordionMenuContentWrap .shop #liMobileLanguageSelector:hover,
.accordionMenuContentWrap .shop #liMobileCurrencySelector:hover {
    background-color: #f0f0f0;
}

.CheckWrap .PaymentMethodList .PointsCardPay {
    background-position: -325px -221px;
}

.DeliveryOptions .DeliveryOptionsItem.active .InnerMethods {
    background-color: #888;
}

.CheckoutProgress .CheckoutProgressSteps > li.Active,
.DeliveryOptions .DeliveryOptionsItem .SpendVoucher {
    background-color: #333;
}

.CheckoutProgress .CheckoutProgressSteps > li.Active::after {
    border-left: 7px solid #333;
}

.DeliveryOptionsItem.active .fullwidthlabel {
    border: 1px solid #333;
}

.Checkout .ContentWrapper {
    padding-top: 0;
    overflow: visible;
}

.Checkout .ContentWrapper .mainBody .row > .col-xs-12 {
    min-height: 0;
}

.Checkout #BodyWrap {
    overflow: hidden;
}

.OrderCompleteLogin,
.OrderCompleteRegistrationForm,
.OrderCompleteRegistrationDone {
    border: none;
}

.OrderCompleteRegistration .ContinueOn,
.OrderCompleteLogin .OrderCompleteLoginForm .dnnPrimaryAction {
    max-width: 250px;
    margin: 0 auto;
    padding: 7px;
    background-color: #fff !important;
    color: #000 !important;
    border: 2px solid #000;
    text-transform: capitalize;
}

#OrderCompleteRegisterModalCancel,
#OrderCompleteRegisterModalContinue,
.shop > ul > li#liAllDepts a,
.accordionMenuContentWrap .shop .menuitemtext,
.shop > ul > li#liAllDepts a span {
    color: #000 !important;
}

#OrderCompleteRegisterModalCancel,
#topLinkMenu ul.TopSubLinkMenu li:hover {
    background-color: #fff !important;
}

#OrderCompleteRegisterModalContinue {
    background-color: #e0ff01 !important;
}

.LogoCheck img.visible-xs {
    display: none !important;
}

.LogoCheck img.hidden-xs {
    display: inline-block !important;
    max-height: 30px;
}

.CheckoutHeader .CheckoutProgressBack .backCheck {
    background-position: -94px -86px;
}

.CheckoutHeader > div,
.CheckoutHeader > a {
    width: 33.333333%;
}

.CheckoutHeader .headerImg a img {
    max-height: 24px;
    max-width: 162px;
}

@media (min-width: 768px) {
    .LogoCheck.col-xs-6 {
        right: 33.333333%;
    }
}

.summaryListText {
    width: calc(100% - 150px);
}

.formCompleteCTA button:hover {
    background-color: #595959;
}

.formCompleteCTA.inactiveSubmit button:hover {
    background-color: #000;
}
