.ProdDetails,
#hotspotModal {
    .printess-button-cta {
        font-size: var(--body-font-size);
        text-transform: capitalize;
        border-radius: 0;
        font-weight: var(--fw-medium);

        &:hover {
            background-color: var(--flan-black) !important;
            color: var(--flan-neon) !important;
        }
    }

    .personalisation-applied {
        border-radius: 0;

        .printess-pdp-global-icon .global-icon-tick .tick-inner {
            fill: var(--printess-pdp-tick-outer) !important;
            stroke: var(--printess-pdp-tick-inner);
        }
    }
}
