.FlanProdDet {
    .colour-image-dropdown-group {
        padding: 0;

        .image-dropdown {
            button {
                width: 100%;
                height: 62px !important;
                border: 1px solid #d1d1d1 !important;
                line-height: 62px;

                &.dropdown-toggle {
                    padding-left: 4px !important;
                    padding-right: 68px !important;
                }

                span.caret {
                    background-image: url("/images/marketing/icons/icon-arrow-down-black.svg");
                    background-repeat: no-repeat;
                    background-size: auto 10px;
                    background-position: 50% 50%;
                    border: none;
                    height: 22px;
                    width: 22px;
                    right: 20px;
                }
            }

            ul li.image-dropdown-option {
                a {
                    display: flex;
                    height: 62px;
                    align-items: center;
                    border-bottom: 1px solid #ddd;
                    text-transform: capitalize;
                    padding: 0 20px 0 4px;
                }

                &:last-child {
                    a {
                        border-bottom: none;
                    }
                }
            }

            .value {
                font-weight: var(--fw-normal);
                font-size: 16px;
                padding-left: 20px;
            }

            .optionimage {
                max-width: 60px;
                display: block;
            }

            &.open .dropdown-toggle.btn-default {
                background-color: #fff;
            }
        }

        .no-image-dropdown-option {
            a {
                padding: 0 20px;
                height: 62px;
                display: flex;
                align-items: center;
                border-bottom: 1px solid #ddd;
            }

            .value {
                padding-left: 4px;
            }

            &:last-child {
                a {
                    border-bottom: none;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .colour-image-dropdown-group {
        .image-dropdown {
            display: block;
            max-width: 100%;
        }
    }
}

@media (min-width: 768px) {
    .colour-image-dropdown-group {
        max-width: 400px;

        .image-dropdown {
            max-width: 400px;

            button span.caret {
                display: inline-block;
            }
        }
    }
}
