#divWishList .wishlist-summary {
    z-index: 5900;
    top: 65px;
    transform: translateX(calc(-100% + 50px));

    &::before {
        content: none;
    }

    &-inner {
        margin: 0;
    }

    &-signinMessage {
        font-size: var(--body-font-size);
    }

    .wishlist-summary-header .clsWishlistMob svg {
        width: 18px;
        height: 100%;
    }

    .wishlist-summary-footer {
        background-color: var(--background-color-secondary);
    }

    .wishlist-summary-footer .ctaBtn {
        border-radius: 0;
        text-align: center;
        border: 1px solid var(--flan-black);
        line-height: normal;
        font-weight: var(--fw-medium);

        &:hover {
            color: var(--flan-neon) !important;
        }
    }

    .wishlist-summary-footer .ctaBtn.signIn {
        line-height: normal;

        &:hover {
            background-color: transparent;
            color: var(--flan-black) !important;
        }
    }

    @media (max-width: 767px) {
        right: auto;
        bottom: auto;
    }

    @media (max-width: 490px) {
        right: 0;
        transform: none;
    }
}

#divWishList.show-wishlist .wishlist-summary {
    @media (max-width: 767px) {
        max-height: calc(100vh - 55px);
    }
}

.wishlist-page {
    max-width: var(--max-container-width);
    margin: 0 auto;

    &__primary-button {
        font-style: normal;
        border-radius: 0;
        border: 1px solid var(--flan-black);
        transition:
            background-color 0.25s ease-in-out,
            color 0.25s ease-in-out;

        &:hover {
            background-color: var(--wishlist-button-background-color);
            color: var(--flan-neon) !important;
        }
    }

    &__header {
        top: 110px;
        transition: top 0.3s ease-in-out;

        @media (max-width: 1021px) {
            top: calc(130px + (var(--padding-unit) * 4));
        }
    }

    &__body {
        @media (min-width: 768px) {
            margin-top: calc(var(--padding-unit) * 8);
        }
    }

    .wishlist-page__empty .global-icon svg {
        width: 30px;
    }
}

@media (max-width: 1021px) {
    .menu-search-hidden .wishlist-page__header {
        top: 0;
    }
}

.wishlist-page,
.wishlist-summary {
    .global-icon.global-icon-tick {
        .tick-outer {
            fill: var(--flan-neon);
        }

        .tick-inner {
            fill: var(--flan-neon);
            stroke: var(--flan-black);
        }
    }
}

.wishlist-share {
    &__close {
        top: 24px;
    }

    &__item,
    &__input-email {
        border-radius: 0;
    }

    &__button input {
        font-style: normal;
        font-weight: var(--fw-normal);
        background-color: var(--wishlist-button-background-color);
        color: var(--wishlist-button-hover-color);
        border: 1px solid var(--flan-black);
        border-radius: 0 !important;

        &:hover {
            background-color: var(--wishlist-button-background-color-hover);
            color: var(--flan-black);
        }
    }
}
