@import url("./delivery-methods.css");
@import url("./colour-images.css");

.FlanProdDet {
    .product-info {
        border-bottom: 1px solid var(--main-border-color);

        .panel-heading {
            padding: 0;
            border-top: 1px solid var(--main-border-color);
            border-radius: 0;

            a {
                padding: 30px 0;
                display: block;

                &.collapsed {
                    h4 {
                        &::before,
                        &::after {
                            transform: rotate(-180deg);
                        }

                        &::after {
                            top: 7px;
                        }
                    }
                }

                &:focus {
                    text-decoration: underline;
                }
            }

            h4 {
                padding: 0;
                position: relative;
                font-weight: var(--fw-semibold);
                letter-spacing: 0.4px;

                &::before {
                    content: "";
                    position: absolute;
                    top: 7px;
                    right: 15px;
                    border-bottom: 8px solid #000;
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                }

                &::after {
                    content: "";
                    position: absolute;
                    top: 10px;
                    right: 18px;
                    border-bottom: 5px solid #fff;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                }
            }

            &:first-child {
                border-top: none;
            }

            &:last-child {
                h4 {
                    &::before,
                    &::after {
                        display: none;
                    }
                }

                a {
                    position: relative;
                    display: flex;
                }

                .rating-container {
                    flex: 1 1 auto;
                }
            }
        }

        .panel-collapse {
            font-size: 14px;
            line-height: 20px;

            p {
                font-size: 14px;
            }
        }

        .panel-body {
            padding: 0 0 30px;
        }
    }

    #DisplayAttributes {
        max-width: none;
        margin: 0;

        li:last-child {
            border-bottom: none;
        }
    }

    #lblProductCode {
        margin: 30px 0 10px;
        font-size: 14px;
    }

    .productVariantContainer {
        margin-bottom: 20px;
    }
}

@media (max-width: 767px) {
    .FlanProdDet {
        .product-info {
            .panel-heading {
                a {
                    padding: 25px 0;
                }
            }

            .panel-body {
                padding: 0 0 25px;
            }
        }
    }
}
