.error-main {
    h1 {
        font-size: 4em;
    }
    h2 {
        font-size: 1.5em;
    }
    p,
    .error-list-options {
        font-size: 1.5em;
        padding-bottom: calc(2.5 * var(--padding-unit));
    }
    .error-list-options {
        padding-left: calc(5 * var(--padding-unit));

        li {
            padding-bottom: calc(1.25 * var(--padding-unit));
            list-style: disc;
        }
        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
    .error-nav {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 30px;
        max-width: 700px;

        a {
            background-color: var(--flan-black);
            border-radius: 2px;
            width: 100%;
            text-align: center;
            text-transform: uppercase;
            padding: calc(2.5 * var(--padding-unit));
            letter-spacing: 0.03em;

            span {
                display: inline-flex;
                font-size: 1rem;
                text-transform: uppercase;
                font-weight: var(--fw-semibold);
                color: var(--flan-white);
                letter-spacing: 0.03em;
            }
        }
    }

    @media screen and (min-width: 768px) {
        margin: 50px auto;
        padding: 0 calc(7.5 * var(--padding-unit));

        .error-nav a {
            width: calc(50% - 5px);
        }
    }
}

footer.error-footer {
    margin: 30px auto 0;
}

.socialLinksWrapper {
    max-width: 1200px;
    border-bottom: 0;

    ul {
        display: flex;
    }

    li {
        display: flex;
        width: 25%;
        text-align: center;
        justify-content: center;

        a {
            background-image: var(--spritesheet);
            text-indent: -9999px;
            background-repeat: no-repeat;
            background-size: 750px 750px;
            width: 50px;
            height: 50px;
        }
    }

    li.socFacebook a {
        background-position: -11px -300px;
        max-width: 50px;
    }

    li.socInstagram a {
        background-position: -12px -425px;
        max-width: 50px;
    }

    li.socTwitter a {
        background-position: -950px -634px;
    }

    li.socTikTok a {
        background-position: -75px -425px;
        max-width: 50px;
    }

    li.socPinterest a {
        background-position: -11px -478px;
        max-width: 50px;
    }

    li.socSpotify a {
        background-position: -90px -673px;
    }

    @media screen and (min-width: 768px) {
        width: 25%;

        li {
            justify-content: flex-start;
        }
    }
}
