.flanProdList {
    #FilterContainer {
        width: 20% !important;
        min-width: 250px;
        margin: 0;
    }

    #FiltersHeader {
        position: sticky;
        top: 130px !important;
        margin: 0;
        padding: 16px 20px 16px 0;
        display: flex;
        transition: all 0.3s ease-in-out;
        z-index: 1;
        background-color: #fff;
    }

    .refineByLeftHeader .FiltersTitle {
        padding: 0;
        font-size: var(--filter-header-text-font-size);
        flex: 0 0 auto;
        text-transform: capitalize;

        .FiltersText,
        .MobFiltersText {
            font-family: var(--brand-font) !important;
            font-weight: var(--fw-medium);
            font-size: var(--filter-header-text-font-size);
            letter-spacing: 0;
        }
    }

    .refineByLeftHeader .productFiltersReset {
        flex: 1 1 auto;
        border: none;
        text-transform: none;

        a {
            text-transform: none;

            &::before {
                height: 1px;
                bottom: 0;
            }
        }
    }

    .ChildCategoriesListWrapper {
        padding: 0 0 8px;
        border-bottom: 1px solid var(--flan-black);

        #ChildCategoriesList li a {
            font-size: var(--filter-text-font-size);
            line-height: var(--filter-text-font-size);
            padding: 8px 8px 8px 0;

            &:hover {
                text-decoration: none;
                font-family: var(--brand-font) !important;
                font-weight: var(--fw-semibold);
            }
        }

        h2 {
            padding-bottom: 8px;
            font-size: var(--filter-text-font-size);
        }
    }

    #innerfiltercontainer {
        width: 100% !important;
        padding-right: 20px;
        position: sticky !important;
        height: 100% !important;
        top: 180px !important;
        max-height: calc(100vh - 200px);
        overflow-y: auto;
        overflow-x: hidden;
        transition: all 0.3s ease-in-out;
    }

    ul.productFilters {
        li.productFilter {
            width: 100%;
            padding: 0;
            text-transform: capitalize;

            &:first-child .productFilterTitleBox {
                border-top: none;
            }

            &:last-child {
                border-bottom: 1px solid var(--main-border-color);
            }

            &.FilterClosed .productFilterTitle::before,
            &.FilterClosed .productFilterTitle::after {
                transform: translateY(-50%) rotate(-180deg);
            }

            &.FilterClosed .productFilterTitle::after {
                top: 6px;
            }

            .productFilterTitle {
                letter-spacing: 0.4px;
                font-size: var(--filter-header-text-font-size);
                line-height: var(--filter-header-text-font-size);
                position: relative;
                margin: 16px 0;
                width: 100%;
                text-transform: capitalize;
                font-family: var(--brand-font) !important;
                font-weight: var(--fw-medium);

                &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0;
                    border-bottom: 8px solid #000;
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    background-color: transparent;
                    left: auto;
                    bottom: auto;
                }

                &::after {
                    content: "";
                    position: absolute;
                    top: 7px;
                    right: 3px;
                    border-bottom: 5px solid #fff;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                }
            }

            .productFilterTitleBox {
                padding: 0;
                cursor: pointer;
                background-color: var(--flan-white);
                z-index: 1;
                border-top: 1px solid var(--main-border-color);

                &.FilterClosed {
                    .productFilterTitle {
                        &::before,
                        &::after {
                            transform: translateY(-50%) rotate(-180deg);
                        }

                        &::after {
                            top: 6px;
                        }
                    }
                }
            }

            .mobAppliedFilters {
                display: none !important;
            }

            .seperator {
                display: none;
            }

            .selectedFiltersGroupInner {
                display: flex;
                flex-wrap: wrap;
                height: auto;
                column-gap: 8px;
            }

            .selectedFilter,
            .filtersOverflow {
                border-radius: 40px;
                padding: 5px 10px;
                background-color: var(--flan-grey);
                margin-bottom: 10px;
                position: relative;
                width: auto;
            }

            .selectedFilter {
                padding-right: 30px;
                cursor: pointer;
            }

            .selectedFilterToggle::before,
            .selectedFilterToggle::after {
                content: "";
                position: absolute;
                width: 11px;
                height: 1px;
                background-color: var(--flan-black);
                top: 50%;
                transform: translateY(-50%);
            }

            .selectedFilterToggle::before {
                transform: translateY(-50%) rotate(45deg);
                right: 10px;
            }

            .selectedFilterToggle::after {
                transform: translateY(-50%) rotate(-45deg);
                right: 10px;
            }

            .productFilterList {
                margin-bottom: 16px;
                max-height: none;
                overflow: unset;
            }

            .FilterShowMore {
                padding: 8px 8px 0 0;
                font-size: 14px;
            }

            .FilterShowMore a {
                font-family: var(--brand-font) !important;
                font-weight: var(--fw-medium);
            }

            .brandSearchSort {
                padding: 0 0 16px;
                position: sticky;
                top: 0;
                z-index: 1;
            }

            #divBrandSearch {
                margin: 0;

                #txtBrandSearch {
                    padding: 5px 30px 5px 35px;
                    border-top: none;
                    border-right: none;
                    border-left: none;
                    height: 45px;
                }

                #spnSearchIcon {
                    display: flex !important;
                    align-items: center;
                    justify-content: center;
                    top: 0;
                    left: 0;
                    height: 45px;

                    .glyphicon {
                        display: block;
                        line-height: 16px;
                        font-size: 16px;
                    }

                    &::before {
                        content: none;
                    }
                }

                #divBrandNotFound {
                    text-transform: none;
                    font-size: var(--filter-text-font-size);
                }
            }

            .FilterName {
                margin: 0;
                font-size: var(--filter-text-font-size);
                padding-left: 28px;
                line-height: 16px;
                flex: 1 1 auto;
                vertical-align: middle;
                text-decoration: none;

                &::before {
                    content: none;
                }
            }

            .FilterValue {
                width: auto;
                float: none;
                line-height: 16px;
                flex: 1 1 auto;
            }

            &.child-categories-in-filters .FilterName {
                padding-left: 0;
            }

            .SelectableFilter,
            .SelectedFilter {
                &::before {
                    top: 50%;
                    transform: translateY(-50%);
                    width: 16px;
                    height: 16px;
                    border: 1px solid var(--flan-black);
                    left: 0;
                }
            }

            .SelectedFilter .FilterName {
                text-shadow: 0 0 1px #000;
                font-weight: unset !important;
            }

            .SelectedFilter::before {
                background-color: var(--flan-black);
            }

            .SelectedFilter::after {
                height: 12px;
                width: 5px;
                border-bottom: 2px solid var(--flan-white);
                border-right: 2px solid var(--flan-white);
                left: 6px;
                top: 50%;
                transform: translateY(-62%) rotate(45deg);
                position: absolute;
            }

            &.sort-by-in-filters .FilterName {
                padding-left: 10px;
            }
        }

        li:not(.productFilter) {
            font-size: var(--filter-text-font-size);
            padding: 16px 0;
            width: 100%;
            text-transform: none;
        }
    }

    .productFilterList .FilterListItem {
        min-height: 0;

        .FilterAnchor {
            display: flex;
            align-items: center;
            padding: 8px 0;

            label {
                margin: 0;
                padding-left: 10px;
                font-weight: unset;
                cursor: pointer;
            }

            .SelectableFilter,
            .SelectedFilter {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            &.greyOut .SelectableFilter {
                opacity: 0.4;
            }

            .MobSortSelectionCheckbox span::before {
                content: none;
            }
        }

        .FilterAnchor:hover {
            .FilterName {
                font-family: var(--brand-font) !important;
                font-weight: var(--fw-semibold);
            }

            .SelectedFilter .FilterName {
                text-shadow: 0 0 var(--flan-black);
                font-family: var(--brand-font) !important;
                font-weight: var(--fw-semibold);
            }
        }
    }

    .productFilterList .FilterListItem .FilterListItem__show {
        display: flex;
        align-items: center;
    }

    #showFilterButtonDiv,
    .mobile-sort-and-filter-wrapper,
    .close-mobile-filters-sort {
        display: none;
    }

    .mobSelectedFiltersContainer {
        display: none !important;
    }

    .mobile-sort-container {
        position: relative;

        .mobAppliedFilters {
            display: none !important;
        }

        .MobSortSelector {
            margin: 0;

            .productFilterTitleBox.FilterClosed + .productFilterList {
                display: none;
            }
        }

        .mobFiltInnerWrap,
        .mobTitlArro,
        .mobSortDesc {
            height: 100%;
        }

        .productFilterTitle {
            margin: 0;
            text-transform: capitalize;
            border: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: var(--filter-header-text-font-size);
            height: 100%;
            letter-spacing: 0;
            font-family: var(--brand-font) !important;
            font-weight: var(--fw-medium);
            font-size: var(--filter-header-text-font-size);
        }

        .productFilterList {
            position: absolute;
            z-index: 960;
            background-color: var(--flan-white);
            border: 1px solid var(--main-border-color);
            left: 0;
            top: 45px;
            right: 0;
            min-width: 250px;
            width: auto;
        }

        .FilterListItem {
            padding: 4px 16px;

            &:first-child {
                margin-top: 4px;
            }

            &:last-child {
                margin-bottom: 4px;
            }
        }
    }

    .Responsive .MobSortSelector .productFilterTitleBox .productFilterTitle::before {
        content: "";
        height: 44px;
        width: 20px;
        background-image: url("/images/core/flan-sort-icon.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 20px 20px;
        display: flex;
        align-items: center;
        margin-right: 20px;
        inset: unset;
        background-color: unset;
        transition: none;
        position: static;
    }
}

.productFilterList input[type="radio"] {
    display: block;
    appearance: none;
    margin: 0;
    width: 16px;
    min-width: 16px;
    max-width: 16px;
    height: 16px;
    border: 1px solid var(--flan-black);
    background: var(--flan-white);
    border-radius: 50%;
    cursor: pointer;

    + .SelectableFilter {
        &::before,
        &::after {
            content: none;
        }
    }

    &:checked + .SelectableFilter label {
        font-family: var(--brand-font) !important;
        font-weight: var(--fw-semibold);
    }

    &::before {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: var(--flan-black);
        margin: 3px;
        display: none;
    }

    &:checked::before {
        display: block;
    }
}

@media (max-width: 1021px) {
    .BodyWrap {
        &.menu-search-hidden {
            .mobile-sort-and-filter-wrapper {
                top: 0;
                box-shadow: 0 8px 12px 2px rgb(0 0 0 / 0.05);
            }
        }
    }

    #TopPaginationWrapper {
        display: none;
    }

    .flanProdList {
        #FilterContainer {
            width: 100% !important;
            position: fixed;
            top: 0 !important;
            left: 100%;
            max-height: 100% !important;
            height: 100% !important;
            transition: all 250ms ease-in;
            visibility: hidden;

            &.filters-open {
                visibility: visible;
                transform: translateX(-100%);
            }

            #innerfiltercontainer {
                max-height: calc(100% - 147px);
                padding: 0 11px 0 16px;
            }

            ul.productFilters {
                li.productFilter {
                    margin: 0;
                }
            }
        }

        #FiltersHeader {
            top: 0 !important;
            padding: 20px 16px;
            left: 0;
            right: 0;
            border-bottom: 1px solid var(--main-border-color);
        }

        .refineByLeftHeader .FiltersTitle {
            padding: 0 !important;
            text-align: center !important;
            flex: 1 1 auto;
        }

        #mobFilterControls {
            height: auto;
            padding: 16px;
            background-color: var(--flan-white);
            box-shadow: 0 4px 12px 4px rgba(0, 0, 0, 0.05);
            column-gap: 16px;
            display: flex !important;
            flex-direction: row-reverse;
            align-items: center;
            position: absolute;
        }

        #mobFilterControls li {
            padding: 16px;
            height: 44px;

            &.mobClearFilters {
                background-color: var(--flan-white);
                border: 1px solid var(--flan-black);

                span {
                    color: var(--flan-black);
                }
            }

            &.mobApplyFilter {
                background-color: var(--flan-black);

                span {
                    color: var(--flan-white);
                }
            }
        }

        #mobFilterControls li .textIconWrap {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                line-height: 16px;
                font-size: 16px;
                letter-spacing: 0;
                font-family: var(--brand-font) !important;
                font-weight: var(--fw-medium) !important;
            }
        }

        .productFilterTitleBox .glyphicon {
            display: none;
        }

        .mobSortDesc {
            width: 100%;
        }

        .mobile-sort-and-filter-wrapper {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            font-size: 14px;
            position: sticky;
            top: 110px;
            z-index: 1;
            background-color: var(--flan-white);
            transition: all 0.3s ease-in-out;
            width: 100%;
            margin: 0 0 16px;
            padding: 16px 20px;
            column-gap: 16px;
        }

        .mobile-sort-container {
            width: 50%;
        }

        .mobile-filter-container {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .mobile-filter-button,
        .MobSortSelector .productFilterTitleBox {
            font-family: var(--brand-font) !important;
            font-weight: var(--fw-medium);
            background-color: var(--flan-grey);
            color: var(--flan-black);
            width: 100%;
            height: 44px;
            padding: 0;
            letter-spacing: 0;
            font-size: 16px;
            line-height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .mobile-filter-button {
            &::before {
                content: "";
                height: 44px;
                width: 20px;
                background-image: url("/images/core/flan-filter-icon.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: 20px 20px;
                display: flex;
                align-items: center;
                margin-right: 20px;
                inset: unset;
                background-color: unset;
                transition: none;
                position: static;
            }
        }

        .close-mobile-filters {
            flex: 0 0 auto;
            display: flex;
            position: relative;
            align-items: center;
            width: 25px;
            justify-content: center;
            cursor: pointer;

            &::before,
            &::after {
                position: absolute;
                right: 10px;
                content: "";
                height: 32px;
                width: 3px;
                background-color: var(--flan-black);
            }

            &::before {
                transform: rotate(45deg);
            }

            &::after {
                transform: rotate(-45deg);
            }
        }

        .sort-by-in-filters {
            display: none;
        }
    }

    .productFilterList {
        display: block;
    }

    #filterByMob {
        position: relative;
        transform: none;
        text-transform: none;
    }

    .mobappfltrsNo {
        margin-left: 10px;
    }
}

@media (min-width: 1022px) {
    .flanProdList #FiltersHeader {
        transition: top 0.3s ease-in-out;
    }

    .flanProdList .BodyWrap.menu-search-hidden #FiltersHeader {
        top: 130px !important;
    }
}

#mobFilterControls .mobappfltrsNo {
    display: none;
}

#TopPaginationWrapper {
    padding-top: 10px;
    padding-bottom: 20px;
}

.PrevNextButtons {
    margin: 6px 0;
}

.PrevNextButtons.PageNumber .NextLink,
.PrevNextButtons.PageNumber .PrevLink {
    background: none;
    text-indent: 0;
    border: none;
    padding: 0 10px;
}

.PrevNextButtons.PageNumber .NextLink.DisabledLink,
.PrevNextButtons.PageNumber .PrevLink.DisabledLink {
    color: #ccc;
    pointer-events: none;
}

.PrevNextButtons.PageNumber .NextLink:hover,
.PrevNextButtons.PageNumber .PrevLink:hover {
    text-decoration: none;
}

.pagination .PageNumber a:hover {
    border-color: #000;
}

.PrevNextButtons.PageNumber .PrevLink,
.PrevNextButtons.PageNumber .PrevLink:hover {
    border-right: 1px solid #000;
}

.PrevNextButtons.PageNumber .NextLink,
.PrevNextButtons.PageNumber .NextLink:hover {
    border-left: 1px solid #000;
}

.PrevNextButtons.PageNumber .NextLink,
.PrevNextButtons.PageNumber .PrevLink,
.PrevNextButtons.PageNumber .CurrentPageNumber,
.PrevNextButtons.PageNumber .PageSeperator,
.PrevNextButtons.PageNumber .MaxPageNumber {
    text-transform: uppercase;
    line-height: 16px;
    height: auto;
    letter-spacing: 0.1em;
    vertical-align: middle;
}

.PrevNextButtons.PageNumber .CurrentPageNumber {
    padding: 0 5px 0 10px;
}

.PrevNextButtons.PageNumber .MaxPageNumber {
    padding: 0 10px 0 5px;
}

.PrevNextButtons.PageNumber a::before {
    content: "";
    display: block;
    position: absolute;
    height: 2px;
    bottom: -3px;
    left: 10px;
    right: 100%;
    background-color: #000;
    transition: right 300ms ease-out 0s;
}

.PrevNextButtons.PageNumber a:hover {
    border: none;
}

.PrevNextButtons.PageNumber a:hover::before,
.PrevNextButtons.PageNumber a:focus::before {
    right: 10px;
}

.PrevNextButtons.PageNumber .DisabledLink:focus::before {
    right: 100%;
}
