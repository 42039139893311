.FlanProdDet {
    .swiper-container {
        .swiper-slide .pinch-zoom-container {
            height: 100% !important; /* Important needed to override the inline styling that SwiperJS Applies */
        }
    }

    .pdp-image-carousel-view {
        .swiper-container {
            .swiper-button-prev {
                left: 0;

                &::before {
                    transform: rotate(180deg) translate(50%, 50%);
                }
            }

            .swiper-button-next {
                right: 0;
                background-position: -127px -220px;
            }

            .swiper-button-prev,
            .swiper-button-next {
                bottom: 50px;
                top: auto;
                height: 50px;
                width: 50px;
                background-image: none;
                background-color: rgba(205, 203, 195, 0.1);

                &:hover {
                    border-color: #fff;
                    transition: background-color 0.25s ease 0s;
                    background-color: rgba(205, 203, 195, 0.5);
                }

                &::before {
                    content: "";
                    position: absolute;
                    display: block;
                    height: 30px;
                    width: 30px;
                    left: 50%;
                    top: 50%;
                    pointer-events: none;
                    background-image: url("/Images/core/flannels-20-sprite-sheet-v9.svg");
                    background-size: 400px 400px;
                    background-position: -81px -196px;
                    transform: translate(-50%, -50%);
                }
            }

            .swiper-slide {
                border: 0;
                height: 100%;
            }

            .swiper-slide.easyzoom,
            .swiper-slide a {
                cursor: initial;
            }

            .swiper-pagination {
                position: static;
                height: 50px;
                line-height: 40px;
                padding: 5px 0;
                bottom: 0;
                transform: none;
            }

            .swiper-button-prev::after,
            .swiper-rtl .swiper-button-next::after,
            .swiper-button-next::after,
            .swiper-rtl .swiper-button-prev::after {
                content: none;
            }
        }
    }

    .pdp-image-grid-view {
        .swiper-pagination {
            position: static;
            line-height: 40px;
            bottom: 0;
            transform: none;

            .swiper-pagination-bullet {
                width: 40px;
                height: 5px;
                border-radius: 0;
                background: var(--flan-black);
                opacity: 0.1;

                &.swiper-pagination-bullet-active {
                    background: var(--flan-black);
                    opacity: 1;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .FlanProdDet {
        .pdp-image-carousel-view {
            .swiper-button-prev,
            .swiper-button-next {
                display: none;
            }
        }
    }
}
