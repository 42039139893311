.brand-room-row {
    margin: 0;
}

.brand-room-row > div {
    padding: 0;
}

.brand-room-row .image-left-wrapper {
    padding: 0;
}

.brand-room .nopadding {
    padding-left: 0;
    padding-right: 0;
}

.brand-room .container {
    width: 100%;
    max-width: var(--max-container-width) !important;
    padding: 0;
}

.brand-room .image-left-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;
    left: 0;
    padding: 0 !important;
}

.brand-room .image-left-wrapper img {
    width: 100%;
    height: 100%;
}

.brand-room .image-right-wrapper img {
    min-width: 100%;
}

.brand-room .image-right-wrapper {
    width: 100%;
    height: 100%;
    max-height: 282px;
    overflow: hidden;
    padding-right: 100px;
}

.brand-room .brand-name {
    display: table;
    max-height: 240px;
    background-color: rgba(235, 235, 235, 0.85);
    padding: 30px;
    margin: 0;
    text-align: left;
    @media (min-width: 1022px) {
        margin-top: 10px;
    }
}

.brand-room .brand-name h1 {
    font-size: 3.35rem;
}

.brand-room .brand-name p {
    font-size: 16px;
    line-height: 30px;
}

.brand-room .brand-read-more {
    display: none;
}

@media screen and (max-width: 1200px) {
    .brand-room .brand-name h1 {
        font-size: 2.3rem;
    }

    .brand-room .brand-name p {
        font-size: 14px;
        line-height: 20px;
    }
}

@media screen and (max-width: 1022px) {
    .brand-room-row {
        display: block !important;
    }

    .brand-room .image-left-wrapper {
        position: relative;
        max-height: 400px;
        padding-left: 50px;
    }

    .brand-room .brand-name {
        min-height: 230px;
        max-height: 230px;
        overflow: hidden;
        padding: 15px;
    }

    .brand-room .hp_brands {
        max-width: 1920px;
    }

    .brand-room .brand-name h1 {
        font-size: 2.3rem;
    }

    .brand-room .brand-name p {
        height: 40px;
        overflow: hidden;
        font-size: 16px;
    }

    .brand-room .brand-name .brand-read-more {
        cursor: pointer;
    }

    .brand-room .brand-read-more {
        display: block;
    }
}

.brand-room .beauty-bg {
    background-image: url("/images/marketing/brandroom/dg_beauty_bg2.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}

.brand-room-2 .container {
    width: 100%;
    padding: 0;
}

.brand-room-2 .image-hero {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 0 100px;
}

.brand-room-2 .alt-brand-room-hero {
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.brand-room-2 .image-hero img {
    width: 100%;
}

.brand-room-2 .brand-name {
    width: 100%;
    display: table;
    text-align: left;
    padding: 30px;
    margin-top: -8.5%;
    max-height: 300px;
    background-color: rgba(244, 244, 244, 0.85);
}

.brand-room-2 .brand-name h1 {
    font-size: 3.35em;
    line-height: 60px;
}

.brand-room-2 .brand-name h2 {
    font-size: 2em !important;
    letter-spacing: 0.05em;
}

.brand-room-2 .brand-title {
    position: absolute;
    width: 100%;
    top: 40%;
}

.brand-room-2 .brand-name .beauty-title {
    font-size: 8.3em;
}

.brand-room-2 .hp_brands {
    position: relative;
    max-width: 1920px;
}

.brand-room-2 .beauty-bg {
    background-image: url("/images/marketing/brandroom/dg_beauty_bg2.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}

.brand-room-2 .loving-swiper .swiper-button-nextloving {
    width: 50px;
    right: 1.5%;
}

.brand-room-2 .brand-title h1 {
    letter-spacing: 0.05em;
    font-size: 60px;
}

.brand-room-2 .brand-title h5 {
    font-size: 22px;
    letter-spacing: 3px;
}

.brand-room-2 .brand-title h1,
.brand-room-2 .brand-title h5 {
    color: #fff;
}

.brand-room-2 .brand-name p {
    font-size: 16px;
    line-height: 30px;
}

.brand-room-2 .brand-read-more {
    display: none;
}

@media screen and (max-width: 1200px) {
    .brand-room-2 .brand-name {
        margin-top: -11%;
    }
}

@media screen and (max-width: 992px) {
    .brand-room-2 .image-hero {
        padding: 0;
    }

    .brand-room-2 .brand-name {
        min-height: 230px;
        max-height: 230px;
        overflow: hidden;
        padding: 15px;
    }

    .brand-room-2 .brand-name h1 {
        font-size: 2.3rem;
    }

    .brand-room-2 .brand-name p {
        height: 60px;
        overflow: hidden;
    }

    .brand-room-2 .brand-name .brand-read-more {
        cursor: pointer;
    }

    .brand-room-2 .brand-read-more {
        display: block;
    }

    .brand-room-2 .brand-room-row {
        display: block !important;
    }
}

@media screen and (max-width: 767px) {
    .brand-room-2 .brand-title h1 {
        font-size: 4.5em !important;
    }

    .brand-room-2 .brand-title h5 {
        font-size: 18px !important;
    }
}

@media screen and (max-width: 400px) {
    .brand-room-2 .brand-name h1 {
        font-size: 4.5em;
    }

    .brand-room-2 .brand-name h2 {
        font-size: 1.5em;
    }
}

.brand-room-2 .hp_brands,
.brand-room-2 .hp_discover {
    background-color: #000;
}

.brand-room-2 .hp_brands .outerContain {
    max-width: 1600px;
    margin: 0 auto;
    width: calc(100% - 20px);
}

.brand-room-2 .hp_brands .hp_brands_Title {
    text-align: center;
    padding: 50px 0 20px;
}

.brand-room-2 .hp_brands .swiper-container-brands {
    padding: 15px;
    margin: 0 auto 30px;
}

.brand-room-2 .hp_brands .swiper-slide a .hp_brands_ImageWrap {
    display: block;
    min-height: 100px;
    position: relative;
}

.brand-room-2 .hp_brands .swiper-slide a .hp_brands_ImageWrap .hp_brands_slideText {
    display: block;
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    font-weight: var(--fw-semibold);
    position: absolute;
    top: 50%;
    background-color: #fff;
    padding: 2px 0;
    color: #000;
    transform: translateY(-50%);
}

.brand-room-2 .hp_brands .swiper-slide.sliderAllBrands a .hp_brands_ImageWrap {
    border: 2px solid #e0ff01;
}

.brand-room-2 .hp_brands .outerContain .swiper-button-nextBrand .arrow,
.brand-room-2 .hp_brands .outerContain .swiper-button-prevBrand .arrow {
    fill: #fff;
    stroke: var(--flan-neon);
    stroke-width: 1;
    fill-rule: evenodd;
}

.brand-room-2 .hp_brands .outerContain .swiper-button-nextBrand,
.brand-room-2 .hp_brands .outerContain .swiper-button-prevBrand {
    background-image: none;
    height: 20px;
    width: auto;
    margin-top: -10px;
}

.brand-room-2 .hp_brands .swiper-button-prev.swiper-button-prevBrand {
    left: 2%;
}

.brand-room-2 .hp_brands .swiper-button-nextBrand {
    right: 2%;
}

.brand-room-2 .hp_brands .swiper-button-prev {
    background: none !important;
    right: auto;
}

.brand-room-2 .hp_brands .swiper-button-next {
    background: none !important;
    left: auto;
}

.brand-room-2 .hp_brands .swiper-wrapper.disabled {
    transform: translate3d(0, 0, 0) !important;
}

.brand-room-2 .hp_brands .swiper-pagination.disabled {
    display: none;
}

.brand-room-2 .hp_brands .swiper-slide {
    max-height: 150px;
}

@media (min-width: 1600px) {
    .brand-room-2 .hp_brands .swiper-button-nextBrand,
    .brand-room-2 .hp_brands .outerContain .swiper-button-prevBrand {
        display: block;
    }
}

@media (min-width: 1022px) {
    .brand-room-2 .hp_brands .swiper-slide a {
        padding-bottom: 100%;
    }

    .brand-room-2 .hp_brands .swiper-slide a .hp_brands_ImageWrap {
        position: absolute;
        width: 150px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .brand-room-2 .hp_brands .swiper-slide a .hp_brands_ImageWrap,
    .brand-room-2 .hp_brands .swiper-slide a .hp_brands_ImageWrap .hp_brands_slideText {
        transition: all 0.25s linear 0s;
    }

    .brand-room-2 .hp_brands .swiper-slide a .hp_brands_ImageWrap .hp_brands_slideText {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .brand-room-2 .hp_brands .swiper-slide {
        max-height: 150px;
    }

    .brand-room-2 .hp_brands .swiper-slide a .hp_brands_ImageWrap {
        border-radius: 100%;
        overflow: hidden;
        transition: all 0.25s linear 0s;
        width: 150px;
        max-height: 150px;
    }

    .brand-room-2 .hp_brands .swiper-slide a .hp_brands_ImageWrap .hp_brands_slideText {
        position: absolute;
        top: 50%;
        background-color: #fff;
        padding: 5px 0;
        color: #000;
        transform: translateY(-50%);
        font-size: 16px;
    }

    .brand-room-2 .hp_brands .swiper-container-brands {
        width: 100%;
    }

    .brand-room-2.eightSlides .hp_brands .swiper-container-brands {
        width: calc(100% - 135px);
    }
}

@media (max-width: 1021px) {
    .brand-room-2 .hp_brands::before {
        display: block;
    }
}

@media (max-width: 999px) {
    .brand-room-2 .hp_brands .swiper-slide {
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 992px) {
    .brand-room-2 .hp_brands .swiper-wrapper {
        justify-content: start !important;
    }
}

@media (max-width: 767px) {
    .brand-room-2 .hp_brands .swiper-slide {
        max-height: 150px;
    }

    .brand-room-2 .hp_brands .swiper-slide a .hp_brands_ImageWrap {
        border-radius: 100%;
        overflow: hidden;
        transition: all 0.25s linear 0s;
        width: 150px !important;
    }

    .brand-room-2 .hp_brands .swiper-slide a .hp_brands_ImageWrap .hp_brands_slideText {
        width: inherit;
    }
}

@media screen and (max-width: 500px) {
    .brand-room-2 .hp_brands .swiper-slide a .hp_brands_ImageWrap,
    .brand-room .hp_brands .swiper-slide a .hp_brands_ImageWrap {
        width: 125px !important;
    }
}

.brand-room-2 .hp_brands .swiper-wrapper {
    display: flex;
    justify-content: center;
    padding: 10px;
}

@media (min-width: 1400px) {
    .brand-room-2 .hp_brands .swiper-button-next {
        left: auto;
    }
}

.brand-room .hp_brands,
.brand-room .hp_discover {
    background-color: #000;
}

.brand-room .hp_brands {
    position: relative;
    max-width: 1920px;
}

.brand-room .hp_brands .outerContain {
    max-width: 1600px;
    margin: 0 auto;
    width: calc(100% - 20px);
}

.brand-room .hp_brands .hp_brands_Title {
    text-align: center;
    padding: 50px 0 20px;
}

.brand-room .hp_brands .swiper-container-brands {
    padding: 15px;
    margin: 0 auto 30px;
}

.brand-room .hp_brands .swiper-slide a .hp_brands_ImageWrap {
    display: block;
    min-height: 100px;
    position: relative;
}

.brand-room .hp_brands .swiper-slide a .hp_brands_ImageWrap .hp_brands_slideText {
    display: block;
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    font-weight: var(--fw-semibold);
    position: absolute;
    top: 50%;
    background-color: #fff;
    padding: 2px 0;
    color: #000;
    transform: translateY(-50%);
}

.brand-room .hp_brands .swiper-slide.sliderAllBrands a .hp_brands_ImageWrap {
    border: 2px solid #e0ff01;
}

.brand-room .hp_brands .outerContain .swiper-button-nextBrand .arrow,
.brand-room .hp_brands .outerContain .swiper-button-prevBrand .arrow {
    fill: #fff;
    stroke: var(--flan-neon);
    stroke-width: 1;
    fill-rule: evenodd;
}

.brand-room .hp_brands .outerContain .swiper-button-nextBrand,
.brand-room .hp_brands .outerContain .swiper-button-prevBrand {
    background-image: none;
    height: 20px;
    width: auto;
    margin-top: -10px;
}

.brand-room .hp_brands .swiper-button-prev.swiper-button-prevBrand {
    left: 2%;
}

.brand-room .hp_brands .swiper-button-nextBrand {
    right: 2%;
}

.brand-room .hp_brands .swiper-button-prev {
    background: none !important;
    right: auto;
}

.brand-room .hp_brands .swiper-button-next {
    background: none !important;
    left: auto;
}

.brand-room .hp_brands .swiper-wrapper.disabled {
    transform: translate3d(0, 0, 0) !important;
}

.brand-room .hp_brands .swiper-pagination.disabled {
    display: none;
}

.brand-room .hp_brands .swiper-slide {
    max-height: 150px;
}

@media (min-width: 1600px) {
    .brand-room .hp_brands .swiper-button-nextBrand,
    .brand-room .hp_brands .outerContain .swiper-button-prevBrand {
        display: block;
    }
}

@media (min-width: 1022px) {
    .brand-room .hp_brands .swiper-slide a {
        padding-bottom: 100%;
    }

    .brand-room .hp_brands .swiper-slide a .hp_brands_ImageWrap {
        position: absolute;
        width: 150px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .brand-room .hp_brands .swiper-slide a .hp_brands_ImageWrap,
    .brand-room .hp_brands .swiper-slide a .hp_brands_ImageWrap .hp_brands_slideText {
        transition: all 0.25s linear 0s;
    }

    .brand-room .hp_brands .swiper-slide a .hp_brands_ImageWrap .hp_brands_slideText {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .brand-room .hp_brands .swiper-slide {
        max-height: 150px;
    }

    .brand-room .hp_brands .swiper-slide a .hp_brands_ImageWrap {
        border-radius: 100%;
        overflow: hidden;
        transition: all 0.25s linear 0s;
        width: 150px;
        max-height: 150px;
    }

    .brand-room .hp_brands .swiper-slide a .hp_brands_ImageWrap .hp_brands_slideText {
        position: absolute;
        top: 50%;
        background-color: #fff;
        padding: 5px 0;
        color: #000;
        transform: translateY(-50%);
        font-size: 16px;
    }

    .brand-room .hp_brands .swiper-container-brands {
        width: 100%;
    }

    .brand-room.eightSlides .hp_brands .swiper-container-brands {
        width: calc(100% - 135px);
    }
}

@media (max-width: 1021px) {
    .brand-room .hp_brands::before {
        display: block;
    }
}

@media (max-width: 999px) {
    .brand-room .hp_brands .swiper-slide {
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 992px) {
    .brand-room .hp_brands .swiper-wrapper {
        justify-content: start !important;
    }
}

@media (max-width: 767px) {
    .brand-room .hp_brands .swiper-slide {
        max-height: 150px;
    }

    .brand-room .hp_brands .swiper-slide a .hp_brands_ImageWrap {
        border-radius: 100%;
        overflow: hidden;
        transition: all 0.25s linear 0s;
        width: 150px !important;
    }

    .brand-room .hp_brands .swiper-slide a .hp_brands_ImageWrap .hp_brands_slideText {
        width: inherit;
    }
}

@media screen and (max-width: 500px) {
    .brand-room .hp_brands .swiper-slide a .hp_brands_ImageWrap {
        width: 125px !important;
    }
}

.brand-room .hp_brands .swiper-wrapper {
    display: flex;
    justify-content: center;
    padding: 10px;
}

@media (min-width: 1400px) {
    .brand-room .hp_brands .swiper-button-next {
        left: auto;
    }
}

.brand-room-2 .loving-swiper .InfoTitle,
.productPrice {
    text-align: left;
    color: #000;
    font-size: 1.5em;
    font-weight: var(--fw-semibold);
    text-transform: uppercase;
    letter-spacing: 0.03em;
    transition: all 0.3s ease-out;
    padding: 10px 10px 0;
}

.brand-room-2 .loving-swiper .InfoSubtitle {
    padding: 6px 10px;
    font-size: 1.2em;
    letter-spacing: 0.03em;
}

.brand-room-2 .loving-swiper .hp_content .InfoSubtitle {
    text-transform: none;
    text-decoration: none;
    width: 100%;
    margin: 0 auto 10px;
    text-align: center;
    font-size: 1.4em;
    padding: 6px 0;
}

.brand-room-2 .loving-swiper .beautySwiper .InfoTitle,
.beautySwiper .InfoSubtitle,
.beautySwiper .productPrice {
    color: #fff;
    border-bottom: none;
}

.brand-room-2 .loving-swiper .beautySwiper .InfoSubtitle {
    text-decoration: underline;
}

.brand-room-2 .loving-swiper .beautySwiper .InfoSubtitle:hover {
    text-decoration: none;
}

.brand-room-2 .loving-swiper .productInfo {
    text-align: left;
    padding: 15px;
    background-color: #fff;
}

.brand-room-2 .loving-swiper .swiper-container-styleNews .productInfo {
    background-color: transparent;
}

.brand-room-2 .loving-swiper {
    padding: 18px 0 30px;
    overflow: hidden;
    background-image: url("/images/marketing/flan-bty-lp-texture6-d.jpg");
    background-repeat: no-repeat;
    background-size: 100% 50%;
}

.brand-room-2 .loving-swiper .swiper-slide,
.swiper-container-meTime .swiper-slide {
    border: 2px solid #ccc;
}

.brand-room-2 .quickBuy {
    background-color: #000;
    padding: 14px 10px;
    color: #fff;
    text-align: center;
    font-size: 1.2em;
    letter-spacing: 0.03em;
    margin-top: 20px;
}

.brand-room-2 .loving-swiper .swiper-container-homepageLower .SlideLogo {
    width: 80%;
    height: 100%;
    border-radius: 100%;
    display: inline-block;
    padding: 2px;
    background: #e00;
}

.brand-room-2 .loving-swiper .swiper-container-loving .swiper-pagination {
    text-align: center;
    width: 100%;
    left: 0;
    position: static;
    margin-top: 25px;
}

.brand-room-2 .loving-swiper .swiper-container-loving .swiper-pagination .swiper-pagination-bullet {
    height: 30px;
    width: 30px;
    line-height: 30px;
    display: inline-block;
    background: transparent;
    opacity: 1;
    border: 0;
}

.brand-room-2 .loving-swiper .swiper-container-loving .swiper-pagination .swiper-pagination-bullet::before {
    content: "";
    background: #ccc;
    opacity: 1;
    border: 1px solid #ccc;
    transition: all 0.3s ease-out;
    width: 25px;
    height: 4px;
    display: inline-block;
    vertical-align: middle;
}

.brand-room-2 .loving-swiper .swiper-container-loving .swiper-pagination-bullet-active::before,
.swiper-container-loving .swiper-pagination-bullet:hover::before {
    background: #000 !important;
    border: 1px solid #000 !important;
}

.brand-room-2 .loving-swiper .swiper-container-homepageLower .swiper-slide {
    width: 28.5%;
}

.brand-room-2 .loving-swiper .swiper-container-loving .swiper-slide {
    width: 50%;
}

.brand-room-2 .loving-swiper .swiper-container-homepageLower {
    padding: 0 10px;
}

.brand-room-2 .loving-swiper .swiper-container-loving .swiper-slide,
.loving-swiper .swiper-container-homepageLower .swiper-slide {
    width: 28.5%;
}

.brand-room-2 .loving-swiper .swiper-container-loving .swiper-slide .SlideLogo img,
.loving-swiper .swiper-container-homepageLower .swiper-slide .SlideLogo img {
    background-color: #fff;
    padding: 2px;
}

.brand-room-2 .loving-swiper .swiper-container-homepageLower .swiper-slide .SlideLogo img {
    border-radius: 100%;
}

.brand-room-2 .lovingSlider .loving-swiper .swiper-container-loving span.slideName,
.loving-swiper .swiper-container-homepageLower span.slideName {
    display: block;
    margin: 5px auto 0;
    text-align: center;
    font-size: 1.1em;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.brand-room-2 .loving-swiper .loving-swiper .swiper-slide > a {
    text-align: center;
}

.brand-room-2 .loving-swiper .swiper-container-loving {
    max-width: 1400px;
    padding: 25px 0 0;
    width: calc(90% - 50px);
}

.brand-room-2 .loving-swiper .swiper-button-prevloving,
.swiper-button-nextloving {
    background-image: none;
}

.brand-room-2 .loving-swiper .swiper-button-prevloving {
    left: 1.5%;
}

.brand-room-2 .loving-swiper .seeMore {
    padding: 0 10px 0 0;
}

.brand-room-2 .loving-swiper .seeMore span {
    border-bottom: 2px solid #000;
    padding: 1px;
    margin: 10px 0;
    font-size: 1.2em;
    letter-spacing: 0.03em;
    display: inline-block;
}

.brand-room-2 .loving-swiper .seeMore span:hover {
    font-weight: var(--fw-semibold);
}

.brand-room-2 .loving-swiper .sideLogo > div {
    line-height: 1em;
    font-size: 3em;
    letter-spacing: 0.03em;
    text-align: left;
    margin: 50px 2% 1% 12.5%;
    display: inline-block;
    padding: 0 25px;
    color: #fff;
    border-bottom: 2px solid #fff;
}

.brand-room-2 .loving-swiper .sideLogo .BlackTitle {
    color: #000;
    border-bottom: 2px solid #000;
    border-right: none;
}

.brand-room-2 .loving-swiper .stayConnected .sideLogo .BlackTitle {
    max-width: 500px;
}

@media (max-width: 400px) {
    .brand-room-2 .loving-swiper .sideLogo > div {
        font-size: 1.75em;
    }
}

.brand-room-2 #featuredBrands {
    background-image: url("/images/marketing/flan-bty-lp-texture6-m.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    margin: 60px 20px 40px;
}

.brand-room-2 .hp_contentBlocks div a,
.brand-room-2 .imgContainer,
.brand-room-2 .imgSection a {
    position: relative;
    display: block;
    text-align: center;
}

.brand-room-2 .hp_content.hp_contentBlocksA {
    padding: 10px 20px;
}

.brand-room-2 .hp_contentBlocks div a .hp_overlay,
.brand-room-2 div#Loving .swiper-slide a .overlayHome,
.brand-room-2 .imageArea .overlayHome,
.brand-room-2 .storeSwiper .overlayHome,
.brand-room-2 .findStore .overlayHome,
.brand-room-2 .wellnessSection .overlayHome,
.brand-room-2 .stayConnected .overlayHome {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #3e3e3e;
    opacity: 0;
    transition: opacity 0.25s linear 0s;
}

.brand-room-2 .hp_contentBlocks div a:hover .hp_overlay {
    opacity: 0.2;
}

.brand-room-2 div#Loving .swiper-slide a:hover .overlayHome,
.brand-room-2 .imageArea a:hover .overlayHome,
.brand-room-2 .storeSwiper a:hover .overlayHome,
.brand-room-2 .findStore a:hover .overlayHome,
.brand-room-2 .stayConnected a:hover .overlayHome,
.brand-room-2 .wellnessSection a:hover .overlayHome {
    opacity: 0.1;
}

.brand-room-2 .hp_button {
    position: relative;
    display: inline-block;
    margin: 10px 10px 20px 0;
}

.brand-room-2 .hp_content .hp_button span {
    background-color: #000;
    color: #fff;
}

.brand-room-2 .hp_content .hp_button::before {
    border: 1px solid #000;
}

.brand-room-2 .hp_content .hp_button:hover::before {
    border: 2px solid #000;
}

.brand-room-2 .imageContainer {
    position: relative;
    border: 4px solid #fff;
}

.brand-room-2 .hp_content .InfoTitle {
    text-align: left;
    padding: 10px;
    letter-spacing: 0.03em;
}

.brand-room-2 .hp_content .InfoSubtitle {
    text-transform: none;
    text-decoration: none;
    width: 100%;
    margin: 0 auto 10px;
    text-align: left;
    font-size: 1.4em;
    padding: 6px 0;
}

.brand-room-2 .brandP-Section .InfoSubtitle {
    padding: 6px 10px;
    font-size: 17px;
    letter-spacing: 0.03em;
}

.brand-room-2 .brandP-Section .InfoTitle {
    text-align: left;
    color: #000;
    font-size: 22px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    transition: all 0.3s ease-out;
    padding: 15px 10px 5px 50px;
}

.brand-room-2 .swiper-container-brandP .swiper-pagination .swiper-pagination-bullet {
    height: 30px;
    width: 30px;
    line-height: 30px;
    display: inline-block;
    background: transparent;
    opacity: 1;
    border: 0;
}

.brand-room-2 .swiper-container-brandP .swiper-pagination .swiper-pagination-bullet::before {
    content: "";
    background: #ccc;
    opacity: 1;
    border: 1px solid #ccc;
    transition: all 0.3s ease-out;
    width: 25px;
    height: 4px;
    display: inline-block;
    vertical-align: middle;
}

.brand-room-2 .swiper-container-brandP .swiper-pagination-bullet-active::before,
.swiper-container-brandP .swiper-pagination-bullet:hover::before {
    background: #000 !important;
    border: 1px solid #000 !important;
}

.brand-room-2 .shop-look .RightSideArea {
    padding: 10px 0 20px;
    color: #fff;
}

.brand-room-2 .shop-look .textSection {
    margin-left: 5%;
}

.brand-room-2 .shop-look .textContain {
    text-align: left;
    margin: 0 auto;
    font-size: 16px;
    padding: 0 2% 1% 0;
}

.brand-room-2 .shop-look .subHeader {
    font-size: 1.3em;
    letter-spacing: 0.03em;
    padding: 10px 0;
}

.brand-room-2 .shop-look .innerSubHeader {
    font-size: 1.3em;
    letter-spacing: 0.03em;
    padding: 10px 0;
}

.brand-room-2 .shop-look {
    background: linear-gradient(to bottom, #000 0%, #000 100%) no-repeat;
    background-size: calc(100%) calc(50%);
    background-position: top;
}

.brand-room-2 .shop-look .stayConnected {
    background-image: url("/images/marketing/flan-bty-lp-texture4-m.jpg");
    background-repeat: no-repeat;
    background-size: 100% 65%;
}

.brand-room-2 .shop-look .copyText {
    font-size: 1.2em;
    letter-spacing: 0.03em;
    line-height: 1.7em;
}

.brand-room-2 .shop-look .ctaContainer {
    text-align: left;
    margin: 50px 2% 1% 0;
}

.brand-room-2 .shop-look .sideLogo > div {
    line-height: 1em;
    font-size: 3em;
    letter-spacing: 0.03em;
    text-align: left;
    display: inline-block;
    padding: 0 25px;
    color: #fff;
    border-bottom: 2px solid #fff;
    margin: 50px 0 1% 0%;
}

.brand-room-2 .shop-look .sideLogo .BlackTitle {
    color: #000;
    border-bottom: 2px solid #000;
    border-right: none;
}

.brand-room-2 .shop-look .RightSideArea > div {
    margin-left: 0;
}

.brand-room-2 .shop-look .ctaContainer a:hover .hp_button span,
.videoShopNow a.hp_button:hover span {
    background-color: #fff;
    color: #000;
}

.brand-room-2 .shop-look .seeMoreBtn {
    position: absolute;
    bottom: 20px;
}

@media (max-width: 767px) {
    .brand-room-2 .shop-look .RightSideArea {
        padding: 10px 0;
        float: none;
        position: relative;
        width: calc(90% - 60px);
        margin: 0 auto;
    }

    .brand-room-2 .shop-look .ctaContainer {
        margin: 20px 2% 1% 0;
    }

    .brand-room-2 .shop-look .hidden-xs {
        display: none !important;
    }

    .brand-room-2 .shop-look .visible-xs {
        display: block !important;
    }

    .brand-room-2 .shop-look .col-sm-4 {
        width: 100%;
    }

    .brand-room-2 .shop-look .col-sm-8 {
        width: 100%;
    }

    .brand-room-2 .shop-look .editsSwiper {
        width: 100%;
    }

    .brand-room-2 .shop-look .editsSwiper .swiper-container-edits {
        padding-left: calc(5% + 10px);
    }
}

@media (max-width: 400px) {
    .brand-room-2 .shop-look .ctaContainer {
        margin: 20px 2% 0 0;
    }

    .brand-room-2 .shop-look {
        background-size: 100% 70%;
    }

    .brand-room-2 .shop-look .RightSideArea {
        padding: 10px;
        width: 100%;
    }

    .brand-room-2 .shop-look .sideLogo > div {
        font-size: 2em;
        padding: 0 15px;
    }

    .brand-room-2 .shop-look .textSection {
        margin: auto;
        padding: 0 10px;
    }
}

.brand-room-2 .shop-look .upperVideoHub {
    position: relative;
    width: 100%;
    margin: 0 auto;
}

.brand-room-2 .shop-look .video-container {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
}

.brand-room-2 .shop-look .theVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    z-index: 1;
}

.brand-room-2 .shop-look .videoCover {
    position: relative;
    z-index: 2;
    cursor: pointer;
}

.brand-room-2 .shop-look .videoCoverImage img {
    width: 100%;
}

.brand-room-2 .shop-look .playButton {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
}

.brand-room-2 .shop-look .playButton img {
    width: 100%;
    height: auto;
    display: block;
    opacity: 0.4;
}

.brand-room-2 .shop-look .playButton img:hover {
    cursor: pointer;
}

.brand-room-2 .shop-look .videoShopNow {
    z-index: 4;
    position: relative;
    text-align: center;
    padding: 3% 0 2%;
    display: block;
}

.brand-room-2 .shop-look .videoComplete {
    width: 100%;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.brand-room-2 .shop-look .hp_button span,
.findStore .ctaContainer .hp_button span {
    position: relative;
    display: block;
    background-color: #fff;
    color: #000;
    text-align: center;
    min-width: 130px;
    padding: 15px 25px !important;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: var(--fw-semibold);
    letter-spacing: 0.2em;
    transition: all 0.3s ease-out;
}

.brand-room-2 .shop-look .hp_button::before,
.findStore .ctaContainer .hp_button::before {
    content: "";
    position: absolute;
    top: 8px;
    left: 8px;
    width: 100%;
    height: 100%;
    border: 1px solid #fff;
    transition: all 0.25s linear 0s;
}

.brand-room-2 .shop-look .hp_button:hover::before {
    border: 2px solid var(--flan-neon);
}

.brand-room-2 .shop-look .videoShopNow .hp_button span,
.ctaContainer .hp_button span {
    background-color: #000;
    color: #fff;
}

.brand-room-2 .shop-look .videoShopNow .hp_button::before,
.ctaContainer .hp_button::before {
    border: 1px solid #000;
}

.brand-room-2 .shop-look .videoShopNow .hp_button:hover::before,
.ctaContainer .hp_button:hover::before {
    border: 2px solid var(--flan-neon);
}
