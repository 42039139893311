.flanProdList {
    .member-pricing {
        margin-top: -7px;
        margin-bottom: 3px;

        .member-price-text {
            font-size: 13px;
        }
    }
}

.FlanProdDet {
    .member-price {
        justify-content: start;
        text-align: left;
        font-size: 20px;

        + .product-detail__price {
            padding-top: 3px;
        }
    }
}
