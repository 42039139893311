#divColourImages {
    float: none;
    padding: 10px 0 0;
    display: block;
}

.colourImages li {
    width: auto;
}

.colourImages li.greyOut a {
    border: 1px solid #e9e9e9;
    padding: 3px;
}

.colourImages li.greyOut span {
    background: url("/images/core/thisline.png") no-repeat scroll center center rgba(235, 235, 235, 0.4);
}

.sizeVariantHighlight::before,
.variantHighlight::before {
    content: none;
}

.colourImages li a:hover {
    border: 1px solid #999;
}

.colourImages li.variantHighlight a,
.sizeButtons li.sizeVariantHighlight a,
#productDetails .sizeButtons li.sizeVariantHighlight.greyOut a,
#productDetails .colourImages li.greyOut.variantHighlight a {
    border: 1px solid #000;
}
