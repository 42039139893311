/* These are also used on the StoreFinder page */

.whiteBoxBrand .greyBgBrand.boxContentBrand,
.whiteBoxBrand {
    background: none repeat scroll 0 0 #fff !important;
}

.whiteBoxBrand .boxContentBrand {
    border: none !important;
}
