.ToplinksGroup .search #cmdSearch span,
.langlogin a.login span.ico,
.QuickLookIcon,
#divBagTotalLink span#bagQuantityContainer,
#divBagTotalLink span#bagQuantityContainer #bagQuantity::before,
.socialSitesWrapper li a,
.footerAppLine span a,
#ModSearchClose,
.MenuCloseActive,
.emailWrapper .SocialWrapperHeader::after,
.lillBasket #clsBasketMob::before,
.pdpWishListIco,
.WishListContain .sAddToWishListWrapper a:hover > .pdpWishListIco,
.FlanProdDet a#aAddToWishList .pdpWishListLabel.addWishList::before,
.mainImageButtons .zoomButton .glyphicon::before,
.PageNumber a.NextLink,
.PageNumber a.PrevLink,
#divShareButton .ShareIcons,
.mainImageButtonPrev span::before,
.mainImageButtonNext span::before,
.pd-accordion .acc-title h3::after,
.brandSearchSort #spnSearchIcon::before,
.flanProdList .productFilterList .SelectedFilter .FilterName::before,
.flanProdList .refineByLeftHeader h2 .refineIco,
.flanProdList .sortbyfilter .sortOptionsHeader::after,
.MobMenIcon.loginIco,
.flanProdList .SelectedFiltersContainer li.selectedFilter a::after,
.flanProdList .Responsive .mobDdClose .clsFilterIcon,
.FlanProdDet .productImageCarousel .swiper-button-prev,
.FlanProdDet .productImageCarousel .swiper-button-next,
.lillBasket a.removeClass,
.flanProdList .refineByLeftHeader .closeFilters,
.flanProdList .SelectedFiltersWrapper.mobileSelectedFilters ul li.selectedFilter span.selectedFilterLabel::after,
.am-menu .am-level a.MobMenChevron::after,
.mp-menu .mp-level a.MobMenChevron::after,
.mp-container .mp-menu .mp-level ul > li a.mp-back::before,
.mp-container .mp-menu .mp-level ul li#mob-wishlist > a::after,
.mp-container .mp-menu .mp-level ul li#mob-account > a::after,
.mp-container .mp-menu .mp-level ul li#mob-stores > a::after,
.product-usage-attributes .beautyAccordionWrap h4.panel-title a::after,
#hotspotModal .swiper-button-next,
#hotspotModal .swiper-button-prev,
.FlanProdDet .ShopTheLook .swiper-button-prevLook,
.FlanProdDet .ShopTheLook .swiper-button-nextLook,
.gwpPromotion .gwpPromotionInner .gwpIcon::before,
.free-samples-promo-container .promo-icon::before,
#GwpPromotionDetailModal .promoTitle .promoTitleLogo,
.hotspotwishlist .WishIcon {
    background-image: url("/images/core/flannels-20-sprite-sheet-v9.svg");
    background-repeat: no-repeat;
}

#hotspotModal .close {
    background: url("/images/core/flannels-20-sprite-sheet-v9.svg") -227px -350px/700px 700px no-repeat transparent !important;
    border: none;
}

.dvSearch .TextBoxClear::before,
.FooterSubGroup .FooterHeader .glyphicon-plus::before,
.FooterSubGroup .FooterHeader .glyphicon-minus::before,
.accordionMenuContentWrap .glyphicon.glyphicon-chevron-right::before,
.PreviousEnable,
.PreviousDisable,
.NextEnable,
.NextDisable,
.sortbyfilter .sortOptionsHeader::before,
.refineByLeftHeader h2 .refineIco,
.productFilterTitleBox .glyphicon::before,
.SelectedFilter::before,
#mobControlBar #filterByMob .mobFilterAppIcon::before,
.SelectedFiltersContainer.mobileSelectedFilters ul li.selectedFilter span.selectedFilterLabel::before,
.flanProdList #mobControlBar #filterByMob .mobFilterAppIcon::before,
.back-to-top,
.ToplinksGroup #mobSearchContainer #mobileSearchTriggerBtn > span {
    background-image: url("/images/core/flannels-new-sheet-v4.svg");
    background-repeat: no-repeat;
}

.ImgButWrap a,
.ImgButWrap a:active,
.ImgButWrap a:visited,
.ImgButWrap a:link,
.ImgButWrap input,
.Basket #BasketDiv .UpdateQuant,
button,
input[type="button"],
input[type="submit"],
.primary2 a.dnnPrimaryAction,
.primary2 input,
#aCheckout,
#aViewBag,
.CareersBrand .vacancy-search-submit a,
.CareersBrand .vacancy-more-info-btn a {
    background-image: none;
    border: 0;
    border-radius: 0;
    filter: none;
    text-shadow: 0 0 0;
    text-transform: capitalize;
    font-weight: var(--fw-medium);
    letter-spacing: 0.1em;
}
