:root {
    --flan-black: #000;
    --flan-grey: #f5f5f5;
    --flan-neon: #eaff00;
    --flan-pink: #e31d93;
    --flan-red: #b30000;
    --flan-white: #fff;
    --flan-dark-grey: #555;
    --flan-light-black: #222;
    --flan-inactive-pagination: #e8e8e8;
    --main-border-color: #c1c1c1;
    --secondary-border-colour: #c5c5c5;
    --video-button-color: #6c6c6c;
    --background-color-main: #fff;
    --background-color-secondary: #f1f1f1;

    --max-container-width: 1660px;

    /* Font */
    --brand-font: "Montserrat", arial, helvetica, sans-serif;
    /* Temp Font Variables for landing pages */
    --bodyFontRegular: "Montserrat", arial, helvetica, sans-serif !important;
    --body: "Montserrat", arial, helvetica, sans-serif !important;
    /* END Temp Font Variables for landing pages */
    --body-font-size: 14px;
    --subtitle-font-size: 16px;
    --filter-header-text-font-size: 16px;
    --filter-text-font-size: 14px;
    --padding-unit: 4px;
    --spritesheet: url("/images/core/flannels-20-sprite-sheet-v9.svg");

    --fw-normal: 400;
    --fw-medium: 500;
    --fw-semibold: 600;
    --fw-bold: 700;

    /* Spacing unit i.e calc(--spacing-unit * 4) */
    --spacing-unit: 4px;

    /* Elevated Cart Variables */
    --cart-font-family: var(--brand-font);
    --cart-font-family-bold: var(--brand-font);
    --cart-font-family-italic: var(--brand-font);
    --cart-sticky-element-default-top-value: 142px;
    --cart-details-margin-top-value: 16px;
    --cart-heading-font-style: normal;
    --cart-heading-font-family: var(--brand-font);
    --cart-button-background-color: var(--flan-black);
    --cart-button-font-color: var(--flan-white);
    --cart-button-hover-color: var(--flan-white);
    --cart-button-hover-text-color: var(--flan-black);
    --cart-button-visited-color: var(--flan-white);
    --cart-border-radius: 0;
    --cart-button-font-style: normal;
    --cart-button-font-family: var(--brand-font);
    --cart-button-font-weight: var(--fw-medium);
    --cart-button-transactional-background-color: var(--flan-black);
    --cart-button-transactional-hover-background-color: var(--flan-black);
    --cart-button-transactional-font-color: var(--flan-white);
    --cart-button-transactional-hover-font-color: var(--flan-neon);
    --cart-total-font-weight: var(--fw-semibold);
    --cart-error-font-color: var(--flan-red);
    --cart-discount-font-color: var(--flan-red);
    --cart-error-background-color: hsl(0deg 100% 98% / 1);

    /* Wishlist Variables */
    --wishlist-summary-header-font-style: normal;
    --wishlist-header-font-style: normal;
    --wishlist-tick-background-color: var(--flan-neon);
    --wishlist-summary-header-text-transform: uppercase;
    --wishlist-button-font-style: normal;
    --wishlist-button-background-color: var(--flan-black);
    --wishlist-button-background-color-hover: var(--flan-black);
    --wishlist-button-hover-color: var(--flan-white);
    --wishlist-scroll-background-color: var(--flan-black);
    --wishlist-button-letter-spacing: 0.1em;
    --wishlist-button-text-transform: capitalize;

    /* Product Line Item used in Cart/Wishlist */
    --product-line-item-price-font-weight: normal;
    --product-line-item-line-error: var(--flan-red);
    --product-line-item-line-error-color: var(--flan-red);
    --product-line-item-font-family-bold: var(--brand-font);
    --product-line-item-discount-text-color: var(--flan-red);
    --product-line-item-border-radius: 0;

    /* Global variables */
    --site-button-primary-background: var(--flan-black);
    --site-button-primary-color: var(--flan-white);
    --site-button-secondary-background: var(--flan-white);
    --site-button-secondary-color: var(--flan-black);
    --site-button-border-radius: 0;
    --site-primary: var(--flan-black);

    --swiper-preloader-color: var(--flan-neon);

    --site-membership-badge-background-colour: var(--flan-neon);
    --site-membership-badge-text-colour: var(--flan-black);

    --printess-pdp-tick-outer: var(--flan-neon);
    --printess-pdp-tick-inner: var(--flan-black);
}
